// Will be uncommented when decrypt is implemented
export const CONFIG_DECRYPT_KEY_SUFFIX = {
  // req url      : ['unique part of req.body.handlerURL or rew.urlWithParams']
  getAssetDetailsData: [{ urlSubString: '/api/v2/assets', configKey: 'getAssetDetailsData_api_v2_assets' }],

  //summary dashboard
  postCycleCount: [{ urlSubString: '/api/v1/cycles', configKey: 'postCycleCount_api_v1_cycles' }],
  postPayload: [{ urlSubString: '/api/v1/cycles', configKey: 'postPayload_api_v1_cycles' }],
  postFaultCode: [{ urlSubString: '/telematics/faultCodes', configKey: 'postFaultCode_telematics_fault_codes' }],
  postKPI: [
    { urlSubString: '/api/v1/utilisation', configKey: 'postKPI_api_v1_utilisation' },
    { urlSubString: '/api/v1/productivity', configKey: 'postKPI_api_v1_productivity' },
    { urlSubString: '/api/v1/cost', configKey: 'postKPI_api_v1_cost' }
  ],
  postFleetLoadCount: [{ urlSubString: '/api/v1/cycles', configKey: 'postFleetLoadCount_api_v1_cycles' }],
  postSegment: [{ urlSubString: '/api/v1/segments', configKey: 'postSegment_api_v1_segments' }],
  postUtilizationHours: [{ urlSubString: '/api/v1/utilisation', configKey: 'postUtilizationHours_api_v1_utilisation' }],
  postUtilisationMapData: [{ urlSubString: '/api/v1/utilisation', configKey: 'postUtilisationMapData_api_v1_utilisation' }],
  getLoadCountZoneInfoDetails: [{ urlSubString: '/api/v1/site', configKey: 'getLoadCountZoneInfoDetails_api_v1_site' }],
  utilizationZoneInfoDetails: [{ urlSubString: '/api/v1/site', configKey: 'utilizationZoneInfoDetails_api_v1_site' }],
  dailyCcaSummary: [{ urlSubString: '/api/v1/wrapper', configKey: 'dailyCcaSummary_api_v1_wrapper' }],
  getCompactionMetrics: [{ urlSubString: '/api/v1/wrapper', configKey: 'getCompactionMetrics_api_v1_wrapper' }],
  // insights
  postMeasuredVolume: [{ urlSubString: '/api/v1/wrapper', configKey: 'postMeasuredVolume_api_v1_wrapper' }],
  getCoverageMetrics: [{ urlSubString: '/api/v1/wrapper', configKey: 'getCoverageMetrics_api_v1_wrapper' }],
  cutFillTiles: [{ urlSubString: 'cutFillTiles', configKey: 'cutFillTiles_cutFillTiles' }],
  compactionTiles: [{ urlSubString: 'compactionTiles', configKey: 'compactionTiles_compactionTiles' }],
  elevationTiles: [{ urlSubString: 'elevationTiles', configKey: 'elevationTiles_elevationTiles' }],
  getCCDSLocation: [{ urlSubString: '/telematics/locations', configKey: 'getCCDSLocation_telematics_locations' }],
  getElevationProfileData: [{ urlSubString: '/api/v1/wrapper', configKey: 'getElevationProfileData_api_v1_wrapper' }],
  postCycleSegments: [{ urlSubString: '/api/v1/sites/', configKey: 'postCycleSegments_api_v1_sites' }],
  productivityinsights: [{ urlSubString: '/api/v3/tableData', configKey: 'productivityinsights_api_v3_tableData' }],
  search: [{ urlSubString: '', configKey: 'search' }],
  cost: [{ urlSubString: '/cost/sum', configKey: 'cost_cost_sum' }],
  postCostMapData: [{ urlSubString: '/api/v1/cost', configKey: 'postCostMapData_api_v1_cost' }],
  postAssetListData_sm: [{ urlSubString: '/api/v1/wrapper', configKey: 'postAssetListData_sm_api_v1_wrapper' }],
  postOnGradeKPI: [{ urlSubString: '/api/v1/wrapper', configKey: 'postOnGradeKPI_api_v1_wrapper' }],
  postSegmentDonut: [{ urlSubString: '/api/v1', configKey: 'postSegmentDonut_api_v1' }],
  postSegmentCycleDetail: [{ urlSubString: '/api/v1', configKey: 'postSegmentCycleDetail_api_v1' }],
  postVolume: [{ urlSubString: '/api/v1/cycles', configKey: 'postVolume_api_v1_cycles' }],
  getHistogramData_pitms: [{ urlSubString: '/api/v1/histogram/payload', configKey: 'getHistogramData_pitms_api_v1_histogram_payload' }],
  postSegmentMetric: [{ urlSubString: '/api/v1/', configKey: 'postSegmentMetric_api_v1' }]
};

export const CONFIG_DECRYPT_PARAMS = {
  getAssetDetailsData_api_v2_assets: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assets', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  // insights
  postMeasuredVolume_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  getCoverageMetrics_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  cutFillTiles_cutFillTiles: [
    {
      method: 'GET',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      multi: ','
    }
  ],
  compactionTiles_compactionTiles: [
    {
      method: 'GET',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      multi: ','
    }
  ],

  //summary dashboard
  postCycleCount_api_v1_cycles: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postPayload_api_v1_cycles: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postFaultCode_telematics_fault_codes: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assets', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postKPI_api_v1_utilisation: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postKPI_api_v1_productivity: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postFleetLoadCount_api_v1_cycles: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postSegment_api_v1_segments: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' },
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKeys', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postUtilizationHours_api_v1_utilisation: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' },
    {
      method: 'POST',
      type: 'param_replace',
      param: 'txt',
      prefix: 'asset',
      property: 'payload',
      multi: '|'
    }
  ],

  postUtilisationMapData_api_v1_utilisation: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  getLoadCountZoneInfoDetails_api_v1_site: [
    { method: 'GET', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  utilizationZoneInfoDetails_api_v1_site: [
    { method: 'GET', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' , isEncoded:true }
  ],
  dailyCcaSummary_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  getCompactionMetrics_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  //insights
  getCCDSLocation_telematics_locations: [{ method: 'POST', type: 'param_replace', property: 'params', isObj: true }],
  getElevationProfileData_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  postCycleSegments_api_v1_sites: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assetKey',
      prefix: 'asset',
      property: 'payload',
      multi: ','
    }
  ],
  productivityinsights_api_v3_tableData: [
    {
      method: 'POST',
      type: 'param_replace',
      param: 'txt',
      prefix: 'asset',
      property: 'payload',
      multi: '|'
    }
  ],
  elevationTiles_elevationTiles: [
    {
      method: 'GET',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      multi: ','
    }
  ],
  search: [{ method: 'POST', type: 'param_replace', property: 'filters', isObj: true }],
  cost_cost_sum: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postCostMapData_api_v1_cost: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postKPI_api_v1_cost: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postAssetListData_sm_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  postOnGradeKPI_api_v1_wrapper: [
    {
      method: 'POST',
      type: 'param_replace',
      isAssetKey: true,
      param: 'assets',
      prefix: 'asset',
      property: 'payload',
      internalProperties: ['url'],
      multi: ','
    }
  ],
  postSegmentDonut_api_v1: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postSegmentCycleDetail_api_v1: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postVolume_api_v1_cycles: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  getHistogramData_pitms_api_v1_histogram_payload: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ],
  postSegmentMetric_api_v1: [
    { method: 'POST', type: 'param_replace', isAssetKey: true, param: 'assetKey', prefix: 'asset', property: 'payload', multi: ',' }
  ]
};

export const DECRYPT_CONFIG_FOR_OBJ = {
  getCCDSLocation_telematics_locations: {
    isJsonParseReq: true,
    keyNames: [
      { key: 'assetId', prefix: 'asset' },
      { key: 'serialNumber', prefix: 'asset' },
      { key: 'equipmentId', prefix: 'assetId' }
    ]
  },
  search: {
    isJsonParseReq: false,
    keyNames: [{ key: 'values', prefix: 'asset' }]
  }
};
