import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { SEND_BOX_PLACEHOLDER_MESSAGE, WEBCHAT_USER_ID } from '../webchat.constants';
import { StyleOptions, WebChatbot } from '../webchat.models';
import { WebchatService } from '../webchat.service';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-chat-bot[botSecretKey]',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBotComponent implements OnChanges {
  @ViewChild('botWindow') botWindowElement: ElementRef;
  @Input() botSecretKey: string;
  @Input() styleOptions: StyleOptions;
  isChatBotContainerAvailable = false;
  changeCloseIcon = false;

  constructor(private readonly webChatbotService: WebchatService) {}

  chatIconClick() {
    // TODO: toggle visibility for the chat container
    this.isChatBotContainerAvailable = !this.isChatBotContainerAvailable;
  }

  ngOnChanges(): void {
    if (this.botSecretKey) {
      this.subscribeToWebchatbot();
    }
  }

  /**
   * method to load webChatbot script
   */
  subscribeToWebchatbot(): void {
    this.webChatbotService.load().subscribe((webchatbot: WebChatbot) => {
      const directLine = webchatbot.createDirectLine({
        secret: this.botSecretKey,
        webSocket: false
      });

      webchatbot.renderWebChat(
        {
          directLine,
          userID: WEBCHAT_USER_ID,
          styleOptions: this.styleOptions,
          overrideLocalizedStrings: {
            TEXT_INPUT_PLACEHOLDER: SEND_BOX_PLACEHOLDER_MESSAGE
          }
        },
        this.botWindowElement?.nativeElement
      );
    });
  }
}
