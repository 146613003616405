/**
 * cookiesString  constant creat for help popup text translate
 */
export const CookiesString = {
  onetrustPopupTitle: 'ONETRUST_COOKIES_POPUP_TITLE',
  onetrustCookiesNotEnable: 'ONETRUST_COOKIES_DISABLED',
  onetrustCookiesEnable: 'ONETRUST_COOKIES_ENABLED',
  onetrustCookiesWhatNewView: 'ONETRUST_COOKIES_WHAT_NEW_VIEW'
};
export const SHOW_CHANGE_ACCOUNT_POPOVER = 'changeAccount';
export const HeaderBackgroundColor = '#FFDE59';
export const USER_ROLE_READ_ONLY = 'Read-only';

export const DEMO_MODE_STRINGS = {
  demo: 'DEMO',
  inDemoMode: 'IN_DEMO_MODE'
};
