export const SET_TARGET_CONFIG = {
  title: 'Set Target',
  description: 'Set Performance goals against KPIs to track in your dashboard',
  KPI: [
    {
      displayName: 'setTarget.tonValue.displayName',
      units: 'setTarget.tonValue.units',
      icon: '../assets/images/vector.svg',
      value: '',
      mappedValue: 'tons'
    },
    {
      displayName: 'setTarget.cycleValue.displayName',
      units: 'setTarget.cycleValue.units',
      icon: '../assets/images/vector.svg',
      value: '',
      mappedValue: 'cycles'
    }
  ],
  actions: [
    {
      type: 'submit',
      displayName: 'setTarget.actions.displayName'
    }
  ]
};
