import { Injectable } from '@angular/core';
import { CustomWindow } from '@Terra/shared/widgets/interface';

function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): CustomWindow {
    return _window();
  }
}
