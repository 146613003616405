import { Injectable } from '@angular/core';
import { WebChatbot } from './webchat.models';

export interface CustomWindow extends Window {
  WebChat: WebChatbot;
}

function _window(): any {
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): CustomWindow {
    return _window();
  }
}
