<!-- Chat Icon -->
<div class="bot-wrapper">
  <div class="chat-icon" (click)="chatIconClick()">
    <div *ngTemplateOutlet="chatIcon"></div>
  </div>
  <!-- chat container -> header + content -->
  <div class="web-chat-wrapper" [ngClass]="{ 'web-chat-container-visibility': isChatBotContainerAvailable }">
    <div class="webchat-header" #chatHeader>
      <!-- chat Header -> logo + close icon -->
      <span class="chat-icon-header">
        <span>
          <span *ngTemplateOutlet="chatIconHeader"> </span>
          <span class="chat-header-text"> ChatBot </span>
        </span>
        <span class="chat-header-close" (click)="chatIconClick()" (mouseover)="changeCloseIcon = true" (mouseout)="changeCloseIcon = false">
          <span *ngTemplateOutlet="changeCloseIcon ? closeIconOnHover : closeIcon"></span>
        </span>
      </span>
    </div>
    <!-- container used by webchat lib to render chat messages  -->
    <div class="webchat-container" #botWindow></div>
  </div>
</div>

<ng-template #chatIconHeader>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9475 4.98075C13.6024 4.98075 14.1333 5.51163 14.1333 6.16651H13.8468C17.72 6.20901 21.0006 8.72096 22.1882 12.2021C22.9837 12.4879 24.0393 13.3271 23.9989 15.1894C23.9583 17.0575 22.9225 17.818 22.1607 18.0432C20.94 21.5127 17.6339 23.9998 13.7469 23.9998H10.1663C6.26756 23.9998 2.95324 21.4977 1.74151 18.0116C0.991926 17.7516 0.0403538 16.9783 0.00148392 15.1894C-0.037302 13.4043 0.93104 12.5592 1.71196 12.2405C2.88855 8.73946 6.17897 6.20917 10.0664 6.16651H9.82455C9.82455 5.51163 10.3554 4.98075 11.0103 4.98075H12.9475Z"
      fill="white"
    />
    <rect width="17.2781" height="9.5335" rx="4.76675" transform="matrix(-1 0 0 1 20.6396 10.3396)" fill="#0046B0" />
    <ellipse cx="1.7235" cy="1.89721" rx="1.7235" ry="1.89721" transform="matrix(-1 0 0 1 18.1406 13.2327)" fill="white" />
    <ellipse cx="1.7235" cy="1.89721" rx="1.7235" ry="1.89721" transform="matrix(-1 0 0 1 8.96289 13.1856)" fill="white" />
    <rect width="0.689399" height="3.03554" transform="matrix(-1 0 0 1 12.3242 1.94368)" fill="white" />
    <ellipse cx="0.947923" cy="1.04347" rx="0.947923" ry="1.04347" transform="matrix(-1 0 0 1 12.9269 0)" fill="white" />
  </svg>
</ng-template>

<ng-template #closeIcon>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L11 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 11L1 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</ng-template>
<ng-template #closeIconOnHover>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L11 1" stroke="#90CAF9" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 11L1 1" stroke="#90CAF9" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</ng-template>
<ng-template #chatIcon>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 24.2677 47.9956 24.5344 47.9869 24.8H48V48H24.8V47.9869C24.5344 47.9956 24.2677 48 24 48Z"
      fill="#0046B0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.3616 13.2246C26.1802 13.2246 26.8438 13.8882 26.8438 14.7068H21.0987C21.0987 13.8882 21.7624 13.2246 22.581 13.2246H25.3616ZM39.9985 25.9881C39.9452 28.2875 38.6052 29.2445 37.5944 29.542C36.0721 33.8856 31.9361 37.0004 27.0727 37.0004H20.812C15.9331 37.0004 11.7864 33.8659 10.2759 29.5008C9.2839 29.1613 8.05255 28.1904 8.0015 25.9881C7.95052 23.7882 9.20474 22.7305 10.2379 22.3193C11.7159 17.8961 15.8916 14.708 20.812 14.708H27.0727C31.9746 14.708 36.1375 17.8724 37.6301 22.2698C38.6849 22.6415 40.0517 23.6936 39.9985 25.9881Z"
      fill="#F2F2F2"
    />
    <rect width="23.0379" height="11.917" rx="5.95848" transform="matrix(-1 0 0 1 35.519 19.9248)" fill="#0046B0" />
    <ellipse cx="2.29804" cy="2.37154" rx="2.29804" ry="2.37154" transform="matrix(-1 0 0 1 32.1869 23.541)" fill="#F2F2F2" />
    <ellipse cx="2.29804" cy="2.37154" rx="2.29804" ry="2.37154" transform="matrix(-1 0 0 1 19.95 23.4824)" fill="#F2F2F2" />
    <rect width="0.919216" height="3.79446" transform="matrix(-1 0 0 1 24.4312 9.43066)" fill="#F2F2F2" />
    <ellipse cx="1.26392" cy="1.30434" rx="1.26392" ry="1.30434" transform="matrix(-1 0 0 1 25.2355 7)" fill="#F2F2F2" />
  </svg>
</ng-template>
