/* eslint-disable max-lines */
/**This config includes key which is made by combining -
 * req.url and unique value/part from req.body.handlerUrl or req.urlWithParams.
 * The value represent the way response is coming from api and which value of response
 * needed to be encrypted*/

export const CONFIG_ENCRYPT_PARAMS = {
  getSitesWithKPIS_api_v2_jobsites: [['sites', [], { records: [{ property: 'name', prefix: 'site' }] }]],
  getSitesWithKPIS_api_v1_jobsites: [['sites', [], { records: [{ property: 'name', prefix: 'site' }] }]],
  postAssetList_api_v2_assets: [
    [
      'assets',
      [],
      {
        records: [
          { property: 'customerAssetId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  parties_entitlements_v1_parties: [['parties', [], { records: [{ property: 'partyName', prefix: '', isHidden: 'true' }] }]],
  getSites_api_v1_assets_recommendations_config: [['config', [], { records: [{ property: 'serialNumber', prefix: 'asset' }] }]],
  getSites_api_v1_sites_name: [['sites', [], { records: [{ property: 'name', prefix: 'site' }] }]],
  assetNotification_api_v1_notifications_count_site: [
    [
      'notifications',
      'asset',
      [],
      {
        records: [
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  //insights
  siteassets_api_v1_organization_jobsite: [
    [
      'sites',
      [],
      'assets',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  postSegment_api_v1_segments: [
    [
      'segments',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'loaderSerialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  zones_api_v1_organization_jobsite: [
    [
      'sites',
      [],
      'zones',
      [],
      {
        records: [
          { property: 'boundaryName', prefix: 'boundaryName', customPrefixProperty: 'boundaryName', propertyToAddAsPrefix: 'boundaryZone' }
        ]
      }
    ]
  ],
  zoneInfo_api_v1_sites: [
    [
      'zones',
      [],
      'kpis',
      'assets',
      [],
      {
        records: [{ property: 'serialNumber', prefix: 'asset' }]
      }
    ],
    [
      'zones',
      [],
      'kpis',
      'cycleCount',
      'zones',
      [],
      {
        records: [{ property: 'name', prefix: 'boundaryName', customPrefixProperty: 'name', propertyToAddAsPrefix: 'type' }]
      }
    ],
    [
      'zones',
      [],
      'kpis',
      'cycleTime',
      'zones',
      [],
      {
        records: [{ property: 'name', prefix: 'boundaryName', customPrefixProperty: 'name', propertyToAddAsPrefix: 'type' }]
      }
    ],
    [
      'zones',
      [],
      'kpis',
      'payload',
      'zones',
      [],
      {
        records: [{ property: 'name', prefix: 'boundaryName', customPrefixProperty: 'name', propertyToAddAsPrefix: 'type' }]
      }
    ]
  ],
  productivityinsights_api_v3_tableData: [
    [
      'productivityInsights',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'name', prefix: 'asset', checkAssetKey: true, checkInProperty: 'id' },
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'loadLatitude', prefix: '' },
          { property: 'loadLongitude', prefix: '' },
          { property: 'dumpLatitude', prefix: '' },
          { property: 'dumpLongitude', prefix: '' },
          { property: 'loadedBy', prefix: 'asset' },
          { property: 'customList1', prefix: 'assetId' },
          { property: 'customList2', prefix: 'assetId' },
          { property: 'customList3', prefix: 'assetId' },
          { property: 'customList4', prefix: 'assetId' },
          { property: 'loadZone', prefix: 'source', propertyValueToNotMask: ['Unknown'] },
          { property: 'loadSiteDescription', prefix: 'assetId' },
          { property: 'dumpZone', prefix: 'destination', propertyValueToNotMask: ['Unknown'] },
          { property: 'truckId', prefix: 'assetId' },
          { property: 'materialId', prefix: 'assetId' }
        ]
      }
    ],
    [
      'productivityInsights',
      [],
      'data',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'name', prefix: 'asset', checkAssetKey: true, checkInProperty: 'id' },
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'loadLatitude', prefix: '' },
          { property: 'loadLongitude', prefix: '' },
          { property: 'dumpLatitude', prefix: '' },
          { property: 'dumpLongitude', prefix: '' },
          { property: 'loadedBy', prefix: 'asset' },
          { property: 'customList1', prefix: 'assetId' },
          { property: 'customList2', prefix: 'assetId' },
          { property: 'customList3', prefix: 'assetId' },
          { property: 'customList4', prefix: 'assetId' },
          { property: 'loadZone', prefix: 'source', propertyValueToNotMask: ['Unknown'] },
          { property: 'loadSiteDescription', prefix: 'assetId' },
          { property: 'dumpZone', prefix: 'destination', propertyValueToNotMask: ['Unknown'] },
          { property: 'truckId', prefix: 'assetId' },
          { property: 'materialId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  postUtilizationHours_api_v1_utilisation: [
    [
      'utilisations',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'latitudeStart', prefix: '' },
          { property: 'latitudeEnd', prefix: '' },
          { property: 'longitudeStart', prefix: '' },
          { property: 'longitudeEnd', prefix: '' },
          {
            property: 'name',
            propertyValueToNotMask: ['Unknown'],
            checkParamProperty: 'groupBy=',
            checkParamValue: ['zone', 'serialNumber'],
            paramBasedPrefix: { zone: 'zone', serialNumber: 'asset' }
          },
          { property: 'zoneName', prefix: 'zone' }
        ]
      }
    ],
    [
      'utilisations',
      [],
      'data',
      [],
      {
        records: [
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'latitudeEnd', prefix: '' },
          { property: 'latitudeStart', prefix: '' },
          { property: 'longitudeEnd', prefix: '' },
          { property: 'longitudeStart', prefix: '' },
          { property: 'zoneName', prefix: 'zone' }
        ]
      }
    ]
  ],
  productivityinsights_api_v1_assetsummary: [
    [
      'assets',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  productivityinsights_api_v2_assetsummary: [
    [
      'assets',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  postCycles_pitms_api_v2_cycles: [
    [
      'cycles',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  productivityinsights_api_v1_filter_equipmentid: [
    [
      [],
      {
        records: [
          { property: 'id', prefix: 'asset' },
          { property: 'name', prefix: 'assetId' }
        ]
      }
    ]
  ],
  productivityinsights_api_v1_filter_serialnumber: [
    [
      [],
      {
        records: [
          { property: 'id', prefix: 'asset' },
          { property: 'name', prefix: 'asset' }
        ]
      }
    ]
  ],
  productivityinsights_api_v1_filter_loadedby: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'asset' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_loadzone: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'source' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_dumpzone: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'destination' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_zonename: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'zone' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_loadsitedescription: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'assetId' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_materialid: [
    [
      [],
      {
        records: [{ property: 'name', prefix: 'assetId' }]
      }
    ]
  ],
  productivityinsights_api_v1_filter_truckid: [
    [
      [],
      {
        records: [{ property: 'id', prefix: 'assetId' }]
      }
    ]
  ],
  //summary dashboard
  getSiteCoreData_pitms_api_v1_organization_jobsite: [
    [
      'sites',
      [],
      'assets',
      [],
      {
        records: [
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ],
    [
      'sites',
      [],
      'zones',
      [],
      {
        records: [
          { property: 'boundaryName', prefix: 'boundaryName', customPrefixProperty: 'boundaryName', propertyToAddAsPrefix: 'boundaryZone' }
        ]
      }
    ]
  ],
  postDesignFiles_api_v1_organization_jobsite: [
    ['files', [], { records: [{ property: 'name', prefix: 'fileName', ignoreExtension: true }] }]
  ],
  postKPI_api_v1_productivity_site: [['assets', [], { records: [{ property: 'serialNumber', prefix: 'asset' }] }]],
  postKPI_api_v1_utilisation_site: [['assets', [], { records: [{ property: 'serialNumber', prefix: 'asset' }] }]],
  postKPI_api_v1_cost_site: [['assets', [], { records: [{ property: 'serialNumber', prefix: 'asset' }] }]],
  postFleetLoadCount_api_v1_cycles: [
    [
      'fleet',
      'rows',
      [],
      {
        records: [
          {
            property: 'primaryLabel',
            prefix: 'assetId',
            propertyValueToNotMask: ['Total', 'Unknown'],
            prefixToBeAddedFromReqProperty: 'subView',
            valueToBeInReq: 'zone'
          },
          { property: 'secondaryLabel', prefix: 'asset', propertyValueToNotMask: ['Total', 'Unknown'] }
        ]
      }
    ],
    [
      'fleet',
      'rows',
      [],
      'cells',
      [],
      {
        records: [
          {
            property: 'primaryLabel',
            prefix: 'assetId',
            propertyValueToNotMask: ['Total', 'Unknown'],
            prefixToBeAddedFromReqProperty: 'subView',
            valueToBeInReq: 'zone'
          },
          { property: 'secondaryLabel', prefix: 'asset', propertyValueToNotMask: ['Total', 'Unknown'] }
        ]
      }
    ]
  ],
  search: [
    [
      'assets',
      [],
      'metadata',
      {
        records: [{ property: 'serialNumber', prefix: 'asset' }]
      }
    ]
  ],
  postSiteImageFiles_pitms_api_v1_organization_jobsite: [
    ['files', [], { records: [{ property: 'name', prefix: 'fileName', ignoreExtension: true }] }]
  ],
  postUtilisationMapData_api_v1_utilisation: [
    [
      'utilisations',
      [],
      {
        records: [
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  cost_api_v1: [
    [
      'cost',
      [],
      {
        records: [
          { property: 'assetId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  postCostMapData_api_v1_cost: [
    [
      'cost',
      [],
      {
        records: [
          { property: 'equipmentId', prefix: 'assetId' },
          { property: 'serialNumber', prefix: 'asset' }
        ]
      }
    ]
  ],
  getLoadCountZoneInfoDetails_api_v1_site: [
    [
      'zone',
      {
        records: [{ property: 'zoneName', prefix: 'zoneName', customPrefixProperty: 'zoneName', propertyToAddAsPrefix: 'zoneType' }]
      }
    ]
  ],
  getCostZoneInfoDetails_api_v1_cost: [
    [
      'zone',
      {
        records: [{ property: 'zoneName', prefix: 'zoneName', customPrefixProperty: 'zoneName', propertyToAddAsPrefix: 'zoneType' }]
      }
    ]
  ],
  utilizationZoneInfoDetails_api_v1_site: [
    [
      'zone',
      {
        records: [{ property: 'zoneName', prefix: 'zoneName', customPrefixProperty: 'zoneName', propertyToAddAsPrefix: 'zoneType' }]
      }
    ]
  ],
  getCustSummary_helios_userManagement_v1_parties_CUST_summary: [
    ['parties', [], { records: [{ property: 'partyName', prefix: '', isHidden: 'true' }] }]
  ],
  customerParties_userManagement_v1_dealers: [['parties', [], { records: [{ property: 'partyName', prefix: '', isHidden: 'true' }] }]],
  getCCDSLocation_telematics_locations: [[{ records: [{ prefix: 'asset', encryptKey: true }] }]],
  getSiteImageMetaData_pitms_wrapper_sm: [
    [
      'tileMap',
      {
        records: [{ property: 'title', prefix: 'fileName', ignoreExtension: true }]
      }
    ]
  ],
  getSiteImageTileURL_pitms_wrapper_sm: [[{ records: [{ property: 'fileName', prefix: 'fieleName', ignoreExtension: true }] }]],
  sitesmaster_api_v1_sites: [['sites', [], { records: [{ property: 'name', prefix: 'site' }] }]],
  postCycleSegments_api_v1_sites: [
    [
      'cycles',
      [],
      {
        records: [
          { property: 'serialNumber', prefix: 'asset' },
          { property: 'equipmentId', prefix: 'assetId' }
        ]
      }
    ]
  ],
  postAssetListData_sm_api_v1_wrapper: [
    [
      'assets',
      [],
      {
        records: [{ property: 'serialNumber', prefix: 'asset' }]
      }
    ]
  ],
  postMeasuredVolume_api_v1_wrapper: [
    [
      'summary',
      'surveyFiles',
      [],
      {
        records: [{ property: 'surveyFileName', prefix: 'fileName', ignoreExtension: true }]
      }
    ]
  ],
  dailyCcaSummary_api_v1_wrapper: [
    [
      'dailyCcaSummary',
      [],
      'assetSummary',
      [],
      {
        records: [{ property: 'asset', prefix: 'asset', ignoreMake: true }]
      }
    ]
  ]
};
