import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DefaultDataServiceConfig, EntityCollectionReducerMethodsFactory, EntityDataModule, PersistenceResultHandler } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  AppEffects,
  appReducer,
  AppService,
  EnvironmentEffects,
  initialState as environmentInitialState,
  environmentReducer
} from '@Terra/pitmanagement/app-store';
import { AppLibComponent } from '@Terra/pitmanagement/shared/app-lib';
// app insights monitor service and error handler
import { DatePipe } from '@angular/common';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalService
} from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule } from '@ngneat/transloco';
import { translocoLoader } from '@Terra/shared/shared-translate-loader';
import { AVAILABLE_LANGUAGES } from '@Terra/shared/widgets/config';
import { WindowRef } from '@Terra/shared/widgets/one-trust';
import {
  ClientApplicationConfig,
  MsalGuardConfig,
  MsalInterceptorConfig,
  MSalRedirectComponent,
  PkceAuthService
} from '@Terra/shared/widgets/pkce-auth';
import { SpinnerModule } from '@Terra/shared/widgets/spinner';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { GTM_DATALAYER_ID, USER_MANAGEMENT_CONFIG } from './app.config';
import { appRoutes } from './app.routes';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';
import { CustomEntityCollectionReducerMethodsFactory } from './ngrx-data/CustomEntityCollectionReducerMethods';
import { CustomPersistenceResultHandler } from './ngrx-data/CustomPersistenceResultHandler';
import { defaultDataServiceConfig, entityConfig } from './ngrx-data/entity-metadata';
import { CustomRouterStateSerializer } from './router.config';
import { SET_TARGET_CONFIG } from './setTarget.config';

@NgModule({
  imports: [
    TranslocoModule,
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledNonBlocking',
      useHash: true,
      preloadingStrategy: CustomPreloadingStrategyService
    }),
    StoreModule.forRoot(
      { app: appReducer },
      {
        metaReducers: [],
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false
        }
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    StoreModule.forFeature('environment', environmentReducer, {
      initialState: environmentInitialState
    }),
    EffectsModule.forFeature([EnvironmentEffects]),
    EntityDataModule.forRoot(entityConfig),
    AppLibComponent,
    SpinnerModule,
    MSalRedirectComponent
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    WindowRef,
    { provide: 'googleTagManagerId', useValue: GTM_DATALAYER_ID },
    AppService,
    PkceAuthService,
    { provide: 'UserManagementConfig', useValue: USER_MANAGEMENT_CONFIG },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        listenToLangChange: true,
        reRenderOnLangChange: true,
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: 'en-US',
        fallbackLang: 'en-US',
        prodMode: true,
        missingHandler: {
          useFallbackTranslation: true
        }
      } as TranslocoConfig
    },
    translocoLoader,
    {
      provide: MSAL_INSTANCE,
      useValue: new PublicClientApplication(ClientApplicationConfig)
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: MsalGuardConfig
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: MsalInterceptorConfig
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: 'setTargetConfig', useValue: SET_TARGET_CONFIG },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: PersistenceResultHandler, useClass: CustomPersistenceResultHandler },
    { provide: EntityCollectionReducerMethodsFactory, useClass: CustomEntityCollectionReducerMethodsFactory }
  ]
})
export class AppModule {}
