import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CcAccordionModule } from '@cat-digital-workspace/shared-ui-core/accordion';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { CcModalModule } from '@cat-digital-workspace/shared-ui-core/modal';
import { TranslocoModule } from '@ngneat/transloco';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { SanitizeHtmlPipe } from './app-footer/footer-content-pipe';
@NgModule({
  imports: [CommonModule, CcAccordionModule, CatButtonModule, TranslocoModule, CcModalModule],
  declarations: [AppFooterComponent, SanitizeHtmlPipe],
  exports: [AppFooterComponent]
})
export class FooterModule {}
