import { GoogleTagManagerService, GoogleUtilService } from '@Terra/shared/google-analytics';
import { UserInfo } from '@Terra/shared/widgets/interface';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationError, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState, Measurements, Properties, sha256 } from './insight-analytics.config';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  env: string;
  previousUrl: string;
  currentUrl: string;
  pageHaultDuration: number;
  navigationStartTime: number;
  navigationResolvedTime: number;
  routerSubscription: Subscription;
  userInfosubscription = new Subscription();
  canLog = false;
  appInsights: ApplicationInsights;
  b2cLoginUserInfo: UserInfo;
  constructor(
    private readonly router: Router,
    private readonly store: Store<any>,
    private readonly gtmService: GoogleTagManagerService,
    public googleUtilService: GoogleUtilService
  ) {}

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private AddGlobalProperties(_properties?: Properties): Properties {
    if (!_properties) {
      _properties = {};
    }

    //add your custom properties such as app version

    return _properties;
  }

  // eslint-disable-next-line complexity
  public listenPageViews(enableAppInsights = false) {
    this.env =
      window.location.hostname.indexOf('-') !== -1
        ? window.location.hostname.split('-')[0]
        : window.location.hostname.indexOf('local') !== -1
        ? 'local'
        : 'prod';

    // added to support slots

    if (window.location.hostname.indexOf('-') !== -1 && window.location.hostname.split('-')[0] === 'beta') {
      this.env = 'prod';
    }

    if (this.env !== 'local' && this.env !== 'dev') {
      if (this.routerSubscription) {
        this.routerSubscription.unsubscribe();
        this.userInfosubscription.unsubscribe();
      }
      this.subscribeUserInfo();
      this.subscribeRouter();

      if (!enableAppInsights) {
        return;
      }
      this.subscribeToInstrumentationKey();
    }
  }

  // Subscribe to Instrumentation Key from Google Util Service;
  subscribeToInstrumentationKey() {
    // Ref: https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
    this.googleUtilService.instrumentationKey$.subscribe(result => {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: result,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: ['*.contentsquare.net', '*.googleapis.com', '*.whatfix.com', 'api*.cat.com'],
          distributedTracingMode: DistributedTracingModes.AI_AND_W3C
        }
      });
      this.canLog = true;
      this.appInsights.loadAppInsights();
    });
  }

  /**
   * method to set the canLog variable to false (to stop trackpage view) and unload app insights and to remove user context cookies informatio
   */
  stopPerformanceCookies(): void {
    this.canLog = false;
    if (this.appInsights) {
      // post all telemtry from buffer before unloading
      this.appInsights.flush(false);
      //clear the user context
      this.appInsights.clearAuthenticatedUserContext();
      //unload the appInsights
      this.appInsights.unload(false);
      this.appInsights = null;
    }
  }

  subscribeRouter() {
    this.routerSubscription = this.router.events.subscribe(event => {
      this.currentUrl = this.router.url;
      switch (true) {
        case event instanceof NavigationStart: {
          this.navigationStartTime = new Date().getTime();
          break;
        }

        case event instanceof ResolveEnd: {
          this.pushB2CInfoToDataLayer();

          if (!this.canLog) {
            return;
          }

          this.navigationResolvedTime = new Date().getTime();
          this.previousUrl = this.currentUrl;
          this.currentUrl = (event as ResolveEnd).url;
          const activatedComponent = this.getActivatedComponent((event as ResolveEnd).state.root);
          if (activatedComponent) {
            this.pageHaultDuration = this.navigationResolvedTime - this.navigationStartTime;
            this.logPageView(
              `${activatedComponent.name} ${this.getRouteTemplate((event as ResolveEnd).state.root)}`,
              (event as ResolveEnd).urlAfterRedirects,
              { PreviousURL: this.previousUrl, CurrentURL: this.currentUrl },
              { 'NavigationStart(ms)': this.navigationStartTime, 'NavigationResolvedTime(ms)': this.navigationResolvedTime },
              this.pageHaultDuration
            );
          }
          break;
        }

        case event instanceof NavigationError: {
          // Present error to user
          this.logPageView((event as NavigationError).error.message || 'Invalid route URL', this.previousUrl);
          break;
        }
      }
    });
  }
  subscribeUserInfo(): void {
    this.userInfosubscription = this.store
      .select((state: AppState) => state.app.userInfo)
      .subscribe((userInfo: UserInfo) => {
        if (userInfo && userInfo !== this.b2cLoginUserInfo) {
          this.b2cLoginUserInfo = userInfo;
          this.pushB2CInfoToDataLayer();
        }
      });
  }
  private logPageView(name: string, url?: string, _properties?: Properties, _measurements?: Measurements, duration?: number) {
    if (this.canLog) {
      this.appInsights.trackPageView({
        name,
        uri: url,
        properties: { measurement: _measurements, duration, ...this.AddGlobalProperties(_properties) }
      });
    }
  }

  public logEvent(name: string, _properties?: Properties, _measurements?: Measurements) {
    if (this.canLog) {
      this.appInsights.trackEvent({ name }, { ...this.AddGlobalProperties(_properties), measurement: _measurements });
    }
  }

  public logError(exception: Error, severityLevel?: number) {
    if (this.canLog) {
      this.appInsights.trackException({ exception, severityLevel });
    }
  }

  public logTrace(message: string, _properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, _properties);
  }

  private async pushB2CInfoToDataLayer(): Promise<void> {
    if (this.b2cLoginUserInfo) {
      this.gtmService.pushOnDataLayer(
        Object.entries({
          event: 'b2cloaded',
          companyID: String(this.b2cLoginUserInfo.ucid).toLowerCase(), // Customer Company ID - UCID
          HEM: await sha256(String(this.b2cLoginUserInfo.email_address).toLowerCase()),
          loginID: await sha256(String(this.b2cLoginUserInfo.catloginid).toLowerCase()), // The user's CWSID
          catRecId: String(this.b2cLoginUserInfo.catrecid).toLowerCase(), // Cat RecID
          affClass: String(this.b2cLoginUserInfo.catafltnclass).toLowerCase(), // Affiliation Class
          affCode: String(this.b2cLoginUserInfo.catafltncode).toLowerCase(), // Affiliation Code
          B2CdealerCode: String(this.b2cLoginUserInfo.catafltnorgcode).toLowerCase(), // Affiliation Org Code
          topLvlOrgCode: String(this.b2cLoginUserInfo.cattoplevelorgcode).toLowerCase(), // Top Level Org Code
          B2CObjectID: String(this.b2cLoginUserInfo.aud).toLowerCase(),
          B2CUserType: String(this.b2cLoginUserInfo.catafltnclass).toLowerCase()
        }).reduce(
          (obj, [key, value]) => ({
            ...obj,
            [key]: value === 'undefined' || value === '' ? null : value
          }),
          {}
        )
      );
    }
  }
}
