import { GoogleTagManagerService } from '@Terra/shared/google-analytics';
import { CP_COOKIE } from '@Terra/shared/widgets/config';
import { CustomWindow } from '@Terra/shared/widgets/interface';
import { oneTrustHelper, WindowRef } from '@Terra/shared/widgets/one-trust';
import { PkceAuthService } from '@Terra/shared/widgets/pkce-auth';
import { utilsHelper } from '@Terra/shared/widgets/utils';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APPLICATION_ID, configStrings } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  appSubscription: Subscription = new Subscription();

  routerPath = configStrings.navigatePath;
  applicationId = APPLICATION_ID;
  isMarketingPage = this.router.url === '/';
  isSSOAvailable = utilsHelper.getSSOAvailable();
  window: CustomWindow;
  isCpCookieAvailable = utilsHelper.getLatestCPCookie();

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    public pkceAuthService: PkceAuthService,
    private readonly winRef: WindowRef
  ) {
    this.window = this.winRef.nativeWindow;
  }

  ngOnInit(): void {
    this.handleSSOLogin();
  }

  async handleSSOLogin() {
    if (!this.isSSOAvailable) {
      this.pkceAuthService.setIsLoggedInValue(false);
      this.navigateToMarketing();
      return;
    }

    await this.pkceAuthService.initializeAuthService();
    this.pkceAuthService.handlePkceSubjects();
    // we have to wait until the interaction is none.
    // use the msalInprogress subject instead of creating new subject
    this.pkceAuthService.msalInProgressNone$.subscribe(progressOver => {
      if (!progressOver) return;

      if (this.pkceAuthService.getActiveAccount() && this.isCpCookieAvailable) {
        this.pkceAuthService.setIsLoggedInValue(true); //this will render app-lib
      } else if (this.isCpCookieAvailable) {
        // to handle self logout
        this.navigateToMarketing();
      } else {
        utilsHelper.setCookie(CP_COOKIE, 1);
        this.pkceAuthService.loginRedirect();
      }
    });
  }

  navigateToMarketing() {
    this.pkceAuthService?.setIsLoggedInValue(false);
    this.isMarketingPage = true;
    this.gtmService.addGtmToDom();
    oneTrustHelper.load(this.window);
    this.router.navigateByUrl('/');
  }

  ngOnDestroy(): void {
    this.appSubscription.unsubscribe();
  }
}
