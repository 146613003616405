import { UsermanagementConfig } from '@Terra/shared/usermanagement-v2';
import { MessageBarHorizontalPosition, MessageBarVerticalPosition } from '@cat-digital-workspace/shared-ui-core/message';
import { BotStyleConstants } from './appLib.constants';

export const SECURE_FORCE_URL = 'https://cat-crmi.secure.force.com/CatProductivity';

export enum BC_CHANNELS {
  app = 'cp_app_root_channel'
}

export const SEMI_MODAL: any = {
  type: 'semi-modal',
  hasBackdrop: true,
  isAutoHeightModalContent: true,
  disableBackdropClick: true,
  disableMargin: true
};

export const LOGOUT_MODAL_WIDTH = '366px';
export const PREFERENCE_MODAL_WIDTH = '433px';
export const CHANGE_ACCOUNT_MODAL_WIDTH = '433px';

export const CHATBOT_STYLE_OPTION = {
  // Basic styling
  backgroundColor: BotStyleConstants.COLOR_GREY_LIGHT, // webchat container
  subtle: BotStyleConstants.COLOR_GREY_DARK, // webchat subtitle
  fontSizeSmall: '75%', // subtitle font size
  paddingRegular: 10, // padding between image and text
  primaryFont: 'noto-sans',
  // bot style
  bubbleBackground: BotStyleConstants.COLOR_WHITE,
  bubbleBorderColor: BotStyleConstants.COLOR_WHITE,
  bubbleBorderRadius: 16,
  bubbleTextColor: BotStyleConstants.COLOR_BLACK,
  // user style
  bubbleFromUserBackground: BotStyleConstants.COLOR_NAVY_BLUE,
  bubbleFromUserBorderRadius: 16,
  bubbleFromUserTextColor: BotStyleConstants.COLOR_BLACK,
  // send box style
  sendBoxHeight: 48,
  sendBoxBackground: BotStyleConstants.COLOR_WHITE, // send box bg color
  sendBoxPlaceholderColor: BotStyleConstants.COLOR_GREY, // send box text color
  sendBoxTextColor: BotStyleConstants.COLOR_BLACK, // send box text color
  sendBoxButtonColor: BotStyleConstants.COLOR_OFF_WHITE, // send box button color
  sendBoxButtonColorOnActive: BotStyleConstants.COLOR_ALERT_BLUE, // send box bg color on active/click
  sendBoxButtonShadeColorOnActive: BotStyleConstants.COLOR_WHITE, // send box bg color on active/click
  sendBoxButtonShadeColorOnHover: BotStyleConstants.COLOR_WHITE,
  hideUploadButton: true,
  timestampFormat: 'absolute', // relative
  // suggested action style
  suggestedActionBackground: BotStyleConstants.COLOR_WHITE,
  suggestedActionBorder: 2,
  suggestedActionBorderRadius: 5
};

export enum BC_MSG_IDs {
  synch_store_req = 'synch store request',
  synch_store_res = 'synch store response'
}

export enum BC_ERR_CODES {
  synch_store_fail = 'synch store failed'
}

export const configStrings = {
  sites_v3: '/api/v3/sites',
  users_v3: '/api/v3/users',
  applications: '/api/applications/',
  applications_v2: '/api/v2/applications/',
  qaVocConsent: 'https://qa-voconsent.cat.com',
  navigatePath: '/dashboard',
  cookieString: 'cookie-consent-status',
  queryString: '=; path=/; domain=.cat.com; expires='
};

export const DEFAULT_ROUTE_PATH = '/dashboard/activesite/1/20';

export const DEMO_MODE_RESTRICTED_URLS = [
  'targets',
  'reportManagement',
  'remoteListManagement',
  'auditLogs',
  'missionManagement',
  'fileManagement',
  'userManagement'
];

export const MENU_CONFIGURATION_BODY = {
  togglerIcon: '../assets/hamburgerIcon.svg',
  brand: {
    src: '../assets/CAT-Logo.svg',
    link: '',
    isTitle: true,
    title: 'app.header.sitemanagement'
  },
  leftMenu: {
    navs: []
  },
  rightMenu: {
    navs: []
  }
};

export const MENU_ACTIONS = {
  PREFERENCES: 'userPreference',
  HELP: 'help',
  LOGOUT: 'logOut',
  UPGRADE: 'upgrade',
  ENABLE_COOKIES: 'enableCookies'
};

export const PARTY_STORAGE_KEY = 'partyData';

export const USER_MANAGEMENT_CONFIG: UsermanagementConfig = {
  ui: {
    elements: {
      title: {
        styles: {
          color: '#26292e'
        },
        label: 'usermanagement.users.admintitle'
      },
      search: {},
      add: {
        label: 'usermanagement.users.addbutton'
      },
      edit: {},
      delete: {},
      form: {},
      cwsID: {
        showInGrid: true
      },
      name: {
        label: 'usermanagement.users.table.username',
        showInGrid: true
      },
      email: {
        label: 'usermanagement.users.addedit.email',
        showInGrid: true
      },
      phoneNo: {
        label: 'usermanagement.users.addedit.phone',
        showInGrid: true
      },
      group: {
        showInGrid: true,
        label: 'usermanagement.users.table.account'
      },
      role: {
        showInGrid: true,
        label: 'usermanagement.users.table.userrole'
      },
      action: {
        showInGrid: true,
        label: 'usermanagement.users.table.action'
      }
    },
    validations: {
      higherRoleEdit: false
    }
  },
  api: {
    middleware: true
  },
  isAppLevelConfig: false,
  rootGroupInfo: {
    description: 'Root',
    groups: [],
    id: 3,
    name: 'Root',
    parentID: 0,
    ucid: ''
  },
  PAGE_DETAILS: {
    PageSize: 20,
    PageNumber: 1
  }
};

export const VOC_HELIOS_LANGUAGE_CODE = {
  'jp-jn': 'ja-jp',
  zh_CN: 'zh-ch',
  'zh-Hans': 'zh-ch'
};

export const GTM_DATALAYER_ID = 'GTM-NL835FR';

export const LOCAL_STORAGE_KEYS = {
  LOGOUT: 'logOut',
  DEVICEIDS: 'deviceIDs'
};

export const DEFAULT_LANGUAGE = 'en-US';

export const URL_POSITION = '-';
export const GROUP_ROOT = 3;
export const ROOT_LABEL = 'Root';

export const PAGE_PATH = {
  MARKETING: '/marketing',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/userManagement'
};

export const ROOT_RESTRICTED_PAGES = [
  {
    path: 'dashboard',
    title: 'productivity.title'
  },
  {
    path: 'allAssets',
    title: 'Assets'
  },
  {
    path: 'targets',
    title: 'setTargetTITLE'
  },
  {
    path: 'reportManagement',
    title: 'Report Management'
  },
  {
    path: 'remoteListManagement',
    title: 'REMOTE LIST MANAGEMENT'
  },
  {
    path: 'auditLogs',
    title: 'Audit Logs'
  },
  {
    path: 'missionManagement',
    title: 'Missions'
  },
  {
    path: 'fileManagement',
    title: 'Files'
  }
];

export const APP_ID = 'pitManagement';
export const CP_APPLICATION_ID = 3;
export const SCS_APPLICATION_ID = 4;
export const horizontalPosition: MessageBarHorizontalPosition = 'center';
export const verticalPosition: MessageBarVerticalPosition = 'top';
export const TOASTER_CONFIG = {
  TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  TOASTER_LIFE_TIME: 5000,
  HOST_TYPE: {
    OVERLAY: 'overlay',
    INLINE: 'inline'
  },
  H_POSITION: horizontalPosition,
  V_POSITION: verticalPosition,
  PREFERENCE_TEXT: {
    SAVED: 'PREFERENCE_SUCCESS_MSG',
    FAILED: 'PREFERENCE_SAVE_FAIL_MSG'
  },
  ERROR_KEY: 'app.errorMessage.process_ERR',
  FALLBACK: 'There is an error processing your request. Please contact customer support'
};

export const BROWSER_COMPATIBILITY_CONFIG = [
  {
    name: 'MS-Edge-Chromium',
    device: 'desktop',
    version: '95',
    range: 2
  },
  {
    name: 'Chrome',
    device: 'desktop',
    version: '95',
    range: 2
  },
  {
    name: 'Safari',
    device: 'desktop',
    version: '15',
    range: 1
  },
  {
    name: 'Firefox',
    device: 'desktop',
    version: '93',
    range: 3
  },
  {
    name: 'Safari',
    device: 'tablet',
    version: '15',
    range: 1
  },
  {
    name: 'Safari',
    device: 'mobile',
    version: '15',
    range: 1
  },
  {
    name: 'Chrome',
    device: 'mobile',
    version: '94',
    range: 2
  },
  {
    name: 'Chrome',
    device: 'tablet',
    version: '94',
    range: 2
  }
];

export const CONTACT_SUPPORT_URL = {
  local: SECURE_FORCE_URL,
  dev: SECURE_FORCE_URL,
  int: SECURE_FORCE_URL,
  qa: SECURE_FORCE_URL,
  stg: SECURE_FORCE_URL,
  perf: SECURE_FORCE_URL,
  prodBeta: SECURE_FORCE_URL,
  prod: SECURE_FORCE_URL
};

export const NAVIGATION_ITEM_TYPE = {
  ApplicationMenuList: 'ApplicationMenuList',
  ProfileMenuList: 'ProfileMenuList'
};

export const SIDE_NAV_CONFIG = {
  collapseWidth: 72,
  expandWdith: 232
};

export const FILE_MENU = 'FileManagementV2';
export const ROOT_ACCOUNT = 'Root';

export const NOTIFICATION_STORAGE_CONSTANTS = {
  key: 'APP_NOTIFICATION_STATUS',
  value: 'VISITED'
};

export const OPTANON_COOKIES = ['OptanonConsent', 'OptanonAlertBoxClosed'];

export const FEATURE_MENULIST = ['MissionManagement', 'FileManagementV2', 'Safety'];

export const USER_NAV_ACTIONS = {
  signoutText: 'app.SIGNOUT_TXT',
  signoutBtn: 'SIGNOUT_BUTTON',
  cancelBtn: 'COMMONLABELS_CANCEL'
};

export const UCID_TRANSLATION_TEXT = {
  dataFetchedError: 'dataFetchedError',
  retry: 'retry',
  noRecord: 'noRecord',
  searchAccount: 'SEARCH_ACCOUNT',
  account: 'ACCOUNT',
  showMore: 'CHANGE_ACCOUNT_SHOW_MORE',
  changeAccount: 'CHANGE_ACCOUNT',
  selectAccount: 'selectAccount',
  other: 'OTHER',
  primary: 'PRIMARY',
  noPrimaryRecord: 'NO_PRIMARY_ACCOUNT',
  customer: 'CUSTOMER'
};

export const DEFAULT_FOR_NON_SUPPORTED_LANGUAGE = 'en-us';

export const USER_TYPES = {
  DEALER: 'DLR',
  CUSTOMER: 'CUST',
  CAT: 'CAT'
};

export const USER_ROLE_READ_ONLY = 'Read-only';

export const LEGAL_POPUP_WIDTH = '750px';
