import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MessageBar } from '@cat-digital-workspace/shared-ui-core/message';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  API_STATUS,
  HideAppSpinner,
  LogOut,
  ProgressBarAlertFailureState,
  ProgressBarAlertProgressState,
  ProgressBarAlertSuccessState,
  SharedLibState,
  ShowAppSpinner
} from '@Terra/shared/shared-lib';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';

import { errorMessage, TOASTER_CONFIG } from './error.messages.constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly instanceServiceArray: any = [];
  private xApplicationId: any;
  private readonly progressBarArray: any = [];

  constructor(
    private readonly _injector: Injector,
    private readonly store: Store<SharedLibState>,
    private readonly messageBar: MessageBar,
    private readonly translocoService: TranslocoService
  ) {
    this.store
      .select((state: any) => state.app.selectedParty)
      .pipe(filter(res => !!res))
      .subscribe(response => {
        this.xApplicationId = response.applicationID;
      });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const canShowSpinner = _req => _req.params.has('canShowSpinner');
    const canShow = canShowSpinner(req);
    const hasDontRemoveSpinner = _req => _req.params.has('dontRemoveSpinner');
    const hasCanProgressBarLoader = _req => _req.params.has('canShowProgressBarLoader');
    const getRequestParamProgressBarSpinner = () => req.params.get('canShowProgressBarLoader');

    if (canShow) {
      if (!hasDontRemoveSpinner(req)) {
        req.params['map'].delete('canShowSpinner');
      }
      this.instanceServiceArray.push(req);
      this.store.dispatch(new ShowAppSpinner());
    }
    if (hasCanProgressBarLoader(req)) {
      //status and mode ===> default is false:Success(hide), other than that got an status
      this.inProgressStateForAlert('InProgress', getRequestParamProgressBarSpinner());
      this.progressBarArray.push(req);
      if (this.xApplicationId > '2') {
        req.params['map'].delete('canShowProgressBarLoader');
      }
    }
    return next.handle(req).pipe(
      tap({
        next: event => {
          if (event instanceof HttpResponse) {
            this.eventUrlEffect(req.urlWithParams, 'Success');
            return event.status;
          }
          return null;
        },
        error: err => {
          if (err.status === API_STATUS.INTERNAL_SERVER_ERROR) {
            let msg = this.translocoService.translate(errorMessage.commonErrMsg);
            msg = msg === errorMessage.commonErrMsg ? errorMessage.internalServerError : msg;
            this.messageBar.open(msg, TOASTER_CONFIG.TYPE.ERROR, [], {
              hostType: TOASTER_CONFIG.HOST_TYPE.OVERLAY,
              duration: TOASTER_CONFIG.TOASTER_LIFE_TIME,
              horizontalPosition: TOASTER_CONFIG.H_POSITION,
              verticalPosition: TOASTER_CONFIG.V_POSITION
            });
          }
          if (err.status === 401) {
            this.store.dispatch(new LogOut(true));
          }
          //error occoured if url is empty
          if (err.status === 0 && hasCanProgressBarLoader(req)) {
            this.xApplicationId === '1'
              ? this.inProgressStateForAlert('Failure', getRequestParamProgressBarSpinner())
              : this.inProgressStateForAlert('Success', getRequestParamProgressBarSpinner());
          }
          this.eventUrlEffect(req.urlWithParams, 'Failure');
        }
      }),
      finalize(() => {
        if (canShow) {
          this.instanceServiceArray.pop();
          if (this.instanceServiceArray.length === 0) {
            this.store.dispatch(new HideAppSpinner());
          }
        }
        // will capture the api after done even if it is success/failure
        if (hasCanProgressBarLoader(req) && this.progressBarArray.length === 0) {
          this.inProgressStateForAlert('Success', getRequestParamProgressBarSpinner());
        }
      }),
      catchError(err => {
        /** Handle Node unauthorised error */
        let errorResp = err.error;
        try {
          errorResp = typeof errorResp === 'string' ? (this.isJsonString(errorResp) ? JSON.parse(errorResp) : errorResp) : errorResp;
        } catch (e) {
          console.log('Error in API', e);
        }
        if (err.status === 403 && errorResp && errorResp.error === 'NODE_UNAUTHORISED') {
          const msg = this.translocoService.translate(errorResp.message);
          this.messageBar.open(msg, TOASTER_CONFIG.TYPE.ERROR, [], {
            hostType: TOASTER_CONFIG.HOST_TYPE.OVERLAY,
            duration: TOASTER_CONFIG.TOASTER_LIFE_TIME,
            horizontalPosition: TOASTER_CONFIG.H_POSITION,
            verticalPosition: TOASTER_CONFIG.V_POSITION
          });
          return EMPTY; // Complete the stream
        }
        return throwError(() => err); // Pass on other errors
      })
    );
  }
  /*
     eventUrlEffect => will execute the function in success and failure  with the url and status
*/
  eventUrlEffect(url, status) {
    if (!url) {
      return;
    }

    if (url.indexOf('canShowProgressBarLoader') === -1) {
      return;
    }

    const requestParams = {
      url,
      status,
      canShowProgressBar: this.getParams(url)
    };

    this.progressBarArray.pop();
    this.xApplicationId === '1'
      ? this.inProgressStateForAlert(requestParams.status, JSON.parse(requestParams.canShowProgressBar.canShowProgressBarLoader))
      : this.inProgressStateForAlert('Success', JSON.parse(requestParams.canShowProgressBar.canShowProgressBarLoader));
  }
  /*
     inProgressStateForAlert => Dispatch the actions based on the status(Inprogress,Success,Error) and ApiMode(hide/show)
*/
  inProgressStateForAlert(apiStatus, apiMode) {
    if (!apiMode) {
      apiStatus = 'default';
    }

    const dispatchProgressStatus = {
      InProgress: () =>
        this.store.dispatch(new ProgressBarAlertProgressState(this.xApplicationId === '1' ? 'messageProgress' : 'simpleProgress')),
      Success: () => this.store.dispatch(new ProgressBarAlertSuccessState()),
      Failure: () => this.store.dispatch(new ProgressBarAlertFailureState()),
      default: () => this.store.dispatch(new ProgressBarAlertSuccessState())
    };
    return (dispatchProgressStatus[apiStatus] || dispatchProgressStatus['default'])();
  }
  /*
     getParams => It will parse the params from url  return an object with the params
*/
  getParams(url): any {
    const params = {};
    const parser = document.createElement('a');
    parser.href = decodeURIComponent(url);
    const query = parser.search.substring(1);
    const splitters = query.split('&');
    for (const i of splitters) {
      const pair = i.split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return { ...params, urlwithParams: url };
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
