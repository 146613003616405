import { CustomWindow, OneTrust } from '@Terra/shared/widgets/interface';
import { Observable, Subscriber } from 'rxjs';
import {
  COOKIES_TAG,
  DEFAULT_DATA_DOMAIN,
  MIN_COUNT_CHECK,
  MIN_LOADING_THRESHOLD,
  ONE_TRUST_LANG_CODES,
  ONE_TRUST_SCRIPT_DOMAIN,
  ONE_TRUST_SCRIPT_ENDPOINT,
  SCRIPT_LOAD_ERROR,
  SCRIPT_TAG_CONSTANTS
} from '../utils/one-trust.constants';
import { OneTrustScript } from '../utils/one-trust.models';

/**
 *
 * @param oneTrustApi OneTrust Window Object
 * @returns updated oneTrust Api with additional properties
 */
export const updateOneTrustApis = (oneTrustApi: OneTrust) => {
  if (oneTrustApi) {
    oneTrustApi.getLangCode = ONE_TRUST_LANG_CODES;
    oneTrustApi.updateLanguage = (lang: string) => {
      if (oneTrustApi.currentLanguage !== lang) {
        oneTrustApi.currentLanguage = oneTrustApi.getLangCode(lang);
        oneTrustApi.changeLanguage(oneTrustApi.currentLanguage);
      }
    };
  }
  return oneTrustApi;
};

/**
 * method to check if script is loaded and emit the data
 * @param observer Subscriber
 */
export const setScriptLoadAndError = (observer: Subscriber<OneTrust | string>, window: CustomWindow) => {
  let count = 0;
  const otTimer = setInterval(() => {
    if (count > MIN_COUNT_CHECK) {
      sendObserver<string>(observer, SCRIPT_LOAD_ERROR, true, otTimer);
      return;
    }

    const oneTrustApi = window.OneTrust;
    if (oneTrustApi && otTimer) {
      const updatedOneTrustApi = updateOneTrustApis(oneTrustApi);
      sendObserver<OneTrust>(observer, updatedOneTrustApi, false, otTimer);
    }
    count += 1;
  }, MIN_LOADING_THRESHOLD);
};

export const getOneTrustBannerSDK = (): HTMLScriptElement => {
  const oneTrustNodes: NodeListOf<HTMLScriptElement> = document?.querySelectorAll('script[src*="cdn.cookielaw.org"');
  const sdkNode: NodeListOf<HTMLScriptElement> = document?.querySelectorAll('script[src$="Sdk.js"');
  return sdkNode[0] ? sdkNode[0] : oneTrustNodes[1];
};

/**
 * method to create a script tag
 * @param src script tag src
 * @param dataDomain data-domain attribute
 * @returns
 */
export const loadScript = (src: string, dataDomain: string): OneTrustScript => {
  const script = document.createElement('script') as OneTrustScript;
  const head = document.getElementsByTagName('head')[0];
  script.src = src;
  script.type = SCRIPT_TAG_CONSTANTS.TYPE;
  script.setAttribute('data-domain-script', dataDomain);
  head.appendChild(script);
  return script;
};

/**
 * method to check if the script element is already loaded into the dom
 * @param scriptSource src for script
 * @returns HTMLScriptElement | null
 */
export const isScriptLoaded = (scriptSource: string): HTMLScriptElement | null => document.querySelector(`script[src="${scriptSource}"]`);

/**
 * Identifying app environment is local or not
 * @param env environment name
 * @returns boolean
 */
export const identifyAppEnv = (env?: string): boolean => {
  const hostName = env || window.location.hostname;
  return hostName.indexOf('local') >= 0;
};

/**
 * method to send the observer and complete
 * @param observer Subscriber
 * @param value string or OneTrust
 * @param isError boolean
 */
export const sendObserver = <T>(observer: Subscriber<T>, value: T, isError: boolean, id: any) => {
  clearInterval(id);
  const isPerformanceCookie = document.cookie.split(encodeURIComponent(`${COOKIES_TAG.PERFORMANCE}:1`)).length > 1;
  const isFunctionalCookie = document.cookie.split(encodeURIComponent(`${COOKIES_TAG.FUNCTIONAL}:1`)).length > 1;
  const isTargetingCookie = document.cookie.split(encodeURIComponent(`${COOKIES_TAG.TARGETING}:1`)).length > 1;
  isError ? observer.error(value) : observer.next({ ...value, isPerformanceCookie, isFunctionalCookie, isTargetingCookie });
  observer.complete();
};

/**
 *method to load oneTrust script
 * @returns Observable
 */
export const load = (window: CustomWindow): Observable<any> =>
  new Observable(observer => {
    setScriptLoadAndError(observer, window);
  });

export const initialiseOneTrust = () => {
  const appEnv = identifyAppEnv();
  const source = ONE_TRUST_SCRIPT_DOMAIN + ONE_TRUST_SCRIPT_ENDPOINT;
  const oneTrustScript = isScriptLoaded(source);
  if (appEnv && !oneTrustScript) {
    loadScript(source, DEFAULT_DATA_DOMAIN);
  }
};
