import { UserInfo } from "@Terra/shared/widgets/interface";

export interface Properties {
  [key: string]: string;
}

export interface Measurements {
  [key: string]: number;
}

export const sha256 = async message => {
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(message));

  // convert bytes to hex string
  return Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
};

export interface AppState {
  app: {
    userInfo: UserInfo;
  };
}
