import { ENCRYPT_DECRYPT_SECRET } from './demo-mode-secrets.config';

export const encrypt = (plaintext: string, keyword: string = ENCRYPT_DECRYPT_SECRET): string => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ-0123456789';
  const input = plaintext.toUpperCase();
  const key = keyword.toUpperCase();

  let result = '';
  let keyIndex = 0;

  input.split('').forEach((char: string) => {
    if (alphabet.includes(char)) {
      const charIndex = alphabet.indexOf(char);
      const keyCharIndex = alphabet.indexOf(key[keyIndex % key.length]);
      const encryptedCharIndex = (charIndex + keyCharIndex) % alphabet.length;
      result += alphabet[encryptedCharIndex];
      keyIndex++;
    } else {
      result += char;
    }
  });

  return result;
};

export const decrypt = (plaintext: string, keyword: string = ENCRYPT_DECRYPT_SECRET): string => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ-0123456789';
  const input = plaintext.toUpperCase();
  const key = keyword.toUpperCase();

  let result = '';
  let keyIndex = 0;

  input.split('').forEach((char: string) => {
    if (alphabet.includes(char)) {
      const charIndex = alphabet.indexOf(char);
      const keyCharIndex = alphabet.indexOf(key[keyIndex % key.length]);
      const decryptedCharIndex = (charIndex - keyCharIndex + alphabet.length) % alphabet.length;

      result += alphabet[decryptedCharIndex];
      keyIndex++;
    } else {
      result += char;
    }
  });

  return result;
};
