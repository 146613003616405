// Move to keyvault later
const DEMO_MODE_SECRET = {
  local: 'd8717ef7-a5de-4753-a44b-de67251dccac',
  dev: 'd8717ef7-a5de-4753-a44b-de67251dccac',
  int: 'f92435d5-7280-4c27-b710-68fb19a62951',
  qa: '28a226c5-18a3-48fd-b43a-b2f1063dd00c',
  perf: 'a85e88a1-8cd7-4642-81fd-8d1d8b48183f',
  stage: '07cce537-c45b-44cf-80f3-53d2b3d48f67',
  prod: '7fa02a05-46c7-4310-9088-1183f030eb13'
};

export const env =
  Object.keys(DEMO_MODE_SECRET).find((envKey: string) => new URL(window.location.href).host.indexOf(envKey) !== -1) || 'prod';

export const ENCRYPT_DECRYPT_SECRET = DEMO_MODE_SECRET[env];
