@if (isTranslationsAvailable && isPreferenceAvailable && isConsentAccepted) {
<div class="root-container" id="root-container">
  @if ((!isMarketingPage() && !isPreferenceMandatory()) || (isCPCookieAvailable() && hasAccess)) {
  <div class="pm-parent-container">
    <div class="wrapper">
      <cc-top-nav
        [navdata]="topNavConfig"
        [setTheme]="true"
        [setSideNavState]="isSideNavOpen"
        [logoTemplate]="logoTemplate"
        (isSideNavCollapsed)="isSideNavCollapsed($event)"
        class="cc-atom-top-Nav"
        [disableTopNavToggle]="false"
        [pagetitle]="mobilePageTitle"
      >
        @if(mobileDevice && showDemoMode && demoModeStatus){
        <ng-container *ngTemplateOutlet="mobileDemoModeTemplate"></ng-container>
        }
        <!-- This is a Temp div, This will be removed once the App header revamp is complete -->
        <div class="temp-view">
          <app-user-nav
            [showDemoMode]="showDemoMode"
            [showUpgrade]="showUpgrade"
            [enableUpgrade]="enableUpgrade"
            [ucidName]="ucidName"
            [userRole]="userRole"
            [userInitials]="userInitials"
            [userName]="userName"
            (userNavEvents)="userPerfActions($event)"
            (accountEvents)="ucidAccountEvents($event)"
            (demoModeEvent)="demoModeToggle($event)"
            [isFunctionalCookieDisabled]="!isFunctionalCookieEnabled"
            [changeAccountTemplateRef]="changeAccount"
          >
          </app-user-nav>
        </div>
      </cc-top-nav>
      <div class="side-nav-body">
        <cc-side-nav
          [theme]="true"
          [expanded]="isSideNavOpen"
          [isAutoCollapse]="autoCollapse"
          [collapsible]="true"
          [dataSource]="sideNavConfig"
          [selectedPathName]="selectedPath"
          [expandWidth]="expandWidth"
          [collapseWidth]="collapseWidth"
          (navLinkClicked)="onNavLinkClicked($event)"
          (navLinkExpanded)="onNavLinkExpanded($event)"
          (bubbleSideNavToggle)="onBubbleSideNavToggle($event)"
          [overlayView]="true"
          [collpaseOnOutsideClick]="true"
          [hideSideNav]="hideSideNav"
          [sidenavOnLoad]="false"
          [childIcons]="true"
          [childIconsonCollapse]="true"
          [tabletAutoCollapse]="false"
          [mobileBackArrowContent]="true"
          [mobileCloseButton]="true"
        >
          <app-user-nav
            [isMobileView]="true"
            [showDemoMode]="showDemoMode"
            [showUpgrade]="showUpgrade"
            [enableUpgrade]="enableUpgrade"
            [userRole]="userRole"
            [userInitials]="userInitials"
            [userName]="userName"
            [isMobileViewUserInfo]="true"
            class="right-nav-mobile-header"
            (accountEvents)="ucidAccountEvents($event)"
            (userNavEvents)="userPerfActions($event)"
            (demoModeEvent)="demoModeToggle($event)"
            [ngClass]="{ 'right-nav-mobile-header-hide': hideMobileUserInfo }"
            [changeAccountTemplateRef]="changeAccount"
          >
          </app-user-nav>
          <svg class="mobile-back-arrow" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32 16C32 24.8368 24.8361 32 16.0004 32C7.16385 32 0 24.8368 0 16C0 7.16318 7.16385 0 16.0004 0C24.8361 0 32 7.16318 32 16Z"
              fill="#F2F2F2"
            />
            <path
              d="M22 16.0003H10"
              stroke="black"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.3636 20L10 16L14.3636 12"
              stroke="black"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="mobile-close-button"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            cc-tooltip="{{ 'app.close' | transloco }}"
            tooltipPosition="left"
          >
            <path d="M1 15L15 1M1 1L15 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <sidenav-content class="sidenav-content">
            <div class="mobile-header-top-border">
              <span class="mobile-header-sep-border"></span>
            </div>
            <!-- Header for Mobile -->
            <app-user-nav
              [showDemoMode]="showDemoMode"
              [showUpgrade]="showUpgrade"
              [enableUpgrade]="enableUpgrade"
              [ucidName]="ucidName"
              [userRole]="userRole"
              [userInitials]="userInitials"
              [userName]="userName"
              (accountEvents)="ucidAccountEvents($event)"
              (userNavEvents)="userPerfActions($event)"
              (demoModeEvent)="demoModeToggle($event)"
              [isMobileView]="true"
              [isMobileViewUserInfo]="false"
              [changeAccountTemplateRef]="changeAccount"
            >
            </app-user-nav>
          </sidenav-content>
          <footer>
            <div class="app-footer" [ngClass]="{ 'footer-height': getCookieState() && isMarketingPage() }">
              <app-footer
                [contactSupportUrl]="contactSupportUrl"
                [showCookies]="true"
                [showLegal]="showLegal"
                [showContact]="!isMarketingPage()"
                (clickCookies)="onClickCookies()"
                (clickLegal)="onClickLegal()"
                [isSideMenuFooter]="true"
                [isOneTrust]="true"
              >
              </app-footer>
            </div>
          </footer>
        </cc-side-nav>
        <div
          id="app-lib-content-container"
          class="content-container"
          [ngClass]="{ 'remove-side-nav': hideSideNav }"
          [class.fixed-header]="mobileDevice && !isPreferenceMandatory()"
        >
          <div class="router-container" [ngClass]="{ 'footer-adj': isMarketingPage() || isPreferenceMandatory() }">
            @if (!isRootAccountPage && showBody) {
            <router-outlet (activate)="onActivate(outlet)" (deactivate)="onDeactivate()" #outlet="outlet"></router-outlet>
            }
            <app-notifications
              [notificationData]="notificationData"
              (onclose)="onNotificationClose($event)"
              (ontryAgain)="onTryAgain($event)"
            ></app-notifications>
            <!-- Message to display for Root account -->
            @if (isRootAccountPage) {
            <div class="root-page-container">
              <div class="root-page-message">
                <div class="root-page-title">{{ rootPageTitle | transloco }}</div>
                <figure class="text-center">
                  <img src="../assets/images/root_nodata.svg" alt="No Data to View" />
                </figure>
                <div class="img-caption text-center">{{ 'app.rootPage.noData' | transloco }}</div>
                <div class="action-info text-center">{{ 'app.rootPage.actionInfo' | transloco }}</div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</div>
}
<ng-template #legalPopupFooterTemplate> </ng-template>
<app-spinner></app-spinner>
<!-- styleoptions comes from static config -->
<app-chat-bot
  class="remove-chatbot chatbot"
  [ngClass]="{ 'chatbot-availability': enableChatBot && directlineSecret }"
  [botSecretKey]="directlineSecret"
  [styleOptions]="styleOptions"
>
</app-chat-bot>

<!-- Logout Confirmation dialog -->
<ng-template #logoutTemplate let-data let-ref="modalRef">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ userNavActions.signoutText | transloco }}</div>
      <div cc-tooltip="{{ 'app.close' | transloco }}" (click)="onConfirmNo()" class="cc-modal-close">
        <img class="cc-close-semi" src="../assets/images/closeSemi.svg" alt="close modal" />
      </div>
    </div>

    <div class="cc-modal-content">
      <p class="cc-modal-signout-text">
        {{ 'app.SIGNOUT_MSG.Initail_text' | transloco }} <b>{{ 'app.SIGNOUT_MSG.SIGN_IN' | transloco }}</b>
        {{ 'app.SIGNOUT_MSG.PAGE' | transloco }}
      </p>
    </div>
    <div class="cc-modal-footer">
      <button [setTheme]="true" cc-btn-secondary cc-btn-large (click)="onConfirmNo()">{{ userNavActions.cancelBtn | transloco }}</button>
      <button [setTheme]="true" cc-btn-primary cc-btn-large (click)="onConfirmYes()">{{ userNavActions.signoutBtn | transloco }}</button>
    </div>
  </div>
</ng-template>
<!-- Preference Container -->
<ng-template #userPreferences let-data let-ref="modalRef">
  <app-user-preferences-container
    [appId]="applicationID"
    [ucidSelectionDropDown]="defaultUcidDropDown"
    [allowToClose]="allowPreferenceClose"
    [defaultPreferenceCP]="preferences.defaultPreferenceCP"
    (preferenceClose)="closeUserPreference(ref)"
    (showChangeAccountModal)="showChangeAccountDialog($event)"
  >
  </app-user-preferences-container>
</ng-template>

@if (showUpgradeModal) {
<div>
  @defer() {
  <app-upgrade-role [ucidData]="selectedParty" (closeModal)="upgradeUserRoleModalClose()"> </app-upgrade-role>
  }
</div>
}

<ng-template #logoTemplate>
  <div class="visionlink-productivity-logo">
    <svg width="246" height="17" viewBox="0 0 246 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M138.656 4.44389H140.942C141.302 4.44389 142.778 4.64189 142.778 6.62189C142.778 8.65589 141.158 8.83589 140.474 8.83589H138.656V4.44389ZM138.656 15.9999V10.2759H140.834C142.166 10.2759 144.47 9.66389 144.47 6.71189C144.47 3.65189 142.598 3.00389 140.852 3.00389H137V15.9999H138.656Z"
        fill="white"
      />
      <path
        d="M148.406 4.44389H150.908C152.186 4.44389 152.654 5.48789 152.654 6.42389C152.654 7.55789 152.078 8.58389 150.89 8.58389H148.406V4.44389ZM150.35 10.0239C150.908 10.0239 152.186 10.0959 152.312 11.6799C152.456 13.4079 152.348 15.0999 152.816 15.9999H154.544C154.292 15.1899 154.112 13.1559 154.094 12.5979C154.004 9.98789 153.464 9.48389 151.97 9.24989V9.21389C153.5 8.94389 154.346 7.77389 154.346 6.24389C154.346 4.62389 153.536 3.00389 151.232 3.00389H146.75V15.9999H148.406V10.0239H150.35Z"
        fill="white"
      />
      <path
        d="M160.82 16.2699C163.574 16.2699 164.906 14.8479 164.906 11.2479V6.98189C164.906 4.38989 163.574 2.73389 160.82 2.73389C158.066 2.73389 156.734 4.38989 156.734 6.98189V11.2479C156.734 14.8479 158.066 16.2699 160.82 16.2699ZM160.82 14.8839C159.254 14.8839 158.426 14.1639 158.426 11.3199V6.92789C158.426 4.20989 160.298 4.11989 160.82 4.11989C161.342 4.11989 163.214 4.20989 163.214 6.92789V11.3199C163.214 14.1639 162.386 14.8839 160.82 14.8839Z"
        fill="white"
      />
      <path
        d="M169.484 4.44389H171.662C173.39 4.44389 174.2 5.41589 174.2 8.04389V10.6719C174.2 13.6959 173.354 14.5599 171.05 14.5599H169.484V4.44389ZM167.828 15.9999H170.816C173.084 15.9999 174.398 15.4419 175.1 14.3259C175.64 13.4619 175.892 11.8599 175.892 9.08789C175.892 8.67389 175.838 6.08189 175.316 4.98389C174.668 3.59789 173.48 3.00389 171.824 3.00389H167.828V15.9999Z"
        fill="white"
      />
      <path
        d="M178.742 11.2479C178.742 14.8479 180.074 16.2699 182.828 16.2699C185.582 16.2699 186.914 14.8479 186.914 11.2479V3.00389H185.258V11.3199C185.258 14.1639 184.394 14.8839 182.828 14.8839C181.262 14.8839 180.398 14.1639 180.398 11.3199V3.00389H178.742V11.2479Z"
        fill="white"
      />
      <path
        d="M197.234 6.58589C197.396 4.06589 196.406 2.73389 193.832 2.73389C191.276 2.73389 189.656 3.77789 189.656 7.44989V11.1219C189.656 16.1619 192.14 16.2699 193.76 16.2699C194.606 16.2699 197.504 16.2699 197.432 12.1659H195.722C195.704 14.7039 194.174 14.8839 193.67 14.8839C192.392 14.8839 191.348 14.5059 191.348 11.3199V7.84589C191.348 5.61389 191.672 5.28989 191.726 5.14589C191.78 4.96589 192.32 4.13789 193.688 4.13789C194.03 4.13789 194.804 4.28189 195.146 4.69589C195.488 5.10989 195.542 5.84789 195.524 6.58589H197.234Z"
        fill="white"
      />
      <path d="M198.596 3.00389V4.44389H202.016V15.9999H203.672V4.44389H207.092V3.00389H198.596Z" fill="white" />
      <path d="M210.674 3.00389H209.018V15.9999H210.674V3.00389Z" fill="white" />
      <path
        d="M222.127 3.00389H220.435L217.393 14.4339H217.357L214.387 3.00389H212.587L216.349 15.9999H218.329L222.127 3.00389Z"
        fill="white"
      />
      <path d="M225.685 3.00389H224.029V15.9999H225.685V3.00389Z" fill="white" />
      <path d="M227.617 3.00389V4.44389H231.037V15.9999H232.693V4.44389H236.113V3.00389H227.617Z" fill="white" />
      <path
        d="M240.901 9.28589L238.363 3.00389H236.509L240.001 11.0499V15.9999H241.657V11.0499L245.203 3.00389H243.529L240.901 9.28589Z"
        fill="white"
      />
      <path
        d="M94.5952 15.9998H91.6846V2.90884H94.1101L103.327 11.1513V2.90884H106.238V15.9998H103.812L94.5952 7.75733V15.9998Z"
        fill="white"
      />
      <path d="M65.0039 16H62.0933V2.90909H65.0039L73.7358 10.6667V0H77.1315V12.6061H84.8932V16H74.2209L65.0039 7.75758V16Z" fill="white" />
      <path d="M3.57929 0H0V0.25L8.43689 16H10.9935L18.9191 0H15.3398L9.71521 11L3.57929 0Z" fill="white" />
      <rect x="18.9189" y="2.90884" width="2.91063" height="13.0909" fill="white" />
      <rect x="39.7788" y="2.90884" width="2.91063" height="13.0909" fill="white" />
      <rect x="86.3486" y="2.90884" width="2.91063" height="13.0909" fill="white" />
      <path
        d="M37.3532 6.54521H34.9277C34.9277 5.74188 34.2765 5.09066 33.4731 5.09066H28.0142C27.2778 5.09066 26.6809 5.68762 26.6809 6.424C26.6809 7.16037 27.2778 7.75733 28.0142 7.75733H35.3532C36.4578 7.75733 37.3532 8.65276 37.3532 9.75733V13.9998C37.3532 15.1043 36.4578 15.9998 35.3532 15.9998H26.2554C25.1508 15.9998 24.2554 15.1043 24.2554 13.9998V12.121H26.6809C26.6809 12.9243 27.3321 13.5755 28.1354 13.5755H33.4731C34.2765 13.5755 34.9277 12.9243 34.9277 12.121C34.9277 11.3176 34.2765 10.6664 33.4731 10.6664H26.2554C25.1508 10.6664 24.2554 9.77099 24.2554 8.66642V4.90884C24.2554 3.80427 25.1508 2.90884 26.2554 2.90884H35.3532C36.4578 2.90884 37.3532 3.80428 37.3532 4.90885V6.54521Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.6294 2.90884C45.5248 2.90884 44.6294 3.80427 44.6294 4.90884V13.9998C44.6294 15.1043 45.5248 15.9998 46.6294 15.9998H58.1527C59.2573 15.9998 60.1527 15.1043 60.1527 13.9998V4.90884C60.1527 3.80427 59.2573 2.90884 58.1527 2.90884H46.6294ZM57.2421 4.84868H47.54V13.5759H57.2421V4.84868Z"
        fill="white"
      />
      <path
        d="M111.574 15.9998H108.664V2.90884H111.574V7.75733L117.395 2.90884H122.732L114.485 8.96945L123.217 15.9998H118.366L111.574 10.1816V15.9998Z"
        fill="white"
      />
      <path d="M73.7358 16V10.6667V0H77.1316V12.6061H84.8933V16H73.7358Z" fill="#FFCD11" />
      <path
        d="M127.987 0.406682C128.701 0.406682 129.311 0.659707 129.816 1.16724C130.317 1.6718 130.568 2.28055 130.568 2.99497C130.568 3.71385 130.317 4.32707 129.816 4.83311C129.313 5.34065 128.704 5.59367 127.987 5.59367C127.27 5.59367 126.658 5.34065 126.158 4.83311C125.915 4.59349 125.723 4.307 125.594 3.99103C125.465 3.67506 125.402 3.33619 125.408 2.99497C125.408 2.28204 125.66 1.6718 126.161 1.16724C126.397 0.922211 126.681 0.728275 126.995 0.597448C127.309 0.466622 127.647 0.401692 127.987 0.406682ZM131 2.98902C131 2.16148 130.707 1.45599 130.121 0.874032C129.534 0.292077 128.826 0.00035566 127.994 0.00035566C127.601 -0.00582023 127.21 0.0684936 126.847 0.218735C126.483 0.368975 126.154 0.591972 125.88 0.874032C125.596 1.14765 125.372 1.47652 125.221 1.84031C125.069 2.2041 124.994 2.59506 125 2.98902C125 3.82251 125.29 4.53246 125.871 5.11739C126.456 5.70679 127.162 6 127.994 6C128.826 6 129.534 5.70679 130.121 5.11739C130.707 4.52948 131 3.81953 131 2.98902ZM128.79 2.34455C128.79 2.59608 128.676 2.76725 128.448 2.85655C128.268 2.9129 128.079 2.93757 127.89 2.92948H127.362V1.74324H127.862C128.512 1.74324 128.79 1.86827 128.79 2.34455ZM126.78 4.62772H127.362V3.33581H127.822C128.131 3.33581 128.347 3.37004 128.47 3.44148C128.679 3.56353 128.781 3.81358 128.781 4.19162V4.45209L128.795 4.55776C128.796 4.57032 128.799 4.58276 128.802 4.59497L128.811 4.62623H129.351L129.33 4.58604C129.296 4.32855 129.293 3.93116 129.293 3.93116C129.291 3.72792 129.215 3.53234 129.081 3.38014C128.946 3.22794 128.761 3.12927 128.56 3.10213C129.143 3.00539 129.351 2.74194 129.351 2.29097C129.351 1.87571 129.182 1.59738 128.839 1.4545C128.647 1.37413 128.347 1.33543 127.938 1.33543H126.78V4.62772Z"
        fill="white"
      />
    </svg></div
></ng-template>
<ng-template #changeAccount>
  @if (showChangeAccountPopover) {
  <div class="change-account-Popover" domClickDetectDirective (domClicked)="onChangeAccountPopoverClose()">
    <ng-container [ngTemplateOutlet]="changeAccountTemplate"></ng-container>
  </div>
  }
</ng-template>

<ng-template #changeAccountPreferences>
  <div class="cc-modal">
    <div class="cc-modal-content-changeAccount">
      <ng-container [ngTemplateOutlet]="changeAccountTemplate"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #changeAccountTemplate>
  @defer(){
  <app-change-account
    [ucidList]="ucidListDropDown"
    [defaultUcid]="defaultUcidDropDown"
    [isPopover]="showChangeAccountPopover"
    [isDataLoaderActive]="isDataLoaderActive"
    [isDataLoadError]="isPartyDataLoadError"
    [isImplicitDataLoaderActive]="isImplicitDataLoaderActive"
    [isImplicitDataLoadError]="isImplicitDataLoadError"
    [showMore]="showMore"
    [implicitShowMore]="implicitShowMore"
    [isCATParty]="isCATParty"
    (accountEvents)="ucidAccountEvents($event)"
    [isMobileView]="mobileDevice"
    [ucidTranslationText]="ucidTranslationText"
    [userType]="userType"
    [pickedByImplicitSearch]="pickedByImplicitSearch"
    [implicitParties]="implicitPartiesDropdown"
    [implicitPartiesCount]="implicitPartiesCount"
  ></app-change-account>
  }
</ng-template>

<ng-template #mobileDemoModeTemplate>
  <div class="mobile-demo-mode-banner">
    <img class="user-img-demo-mode-white mobile-demo-mode" alt="mobile-demo-mode" src="../assets/transparent_image.png" />
    <span class="mobile-demo-mode-banner-label">{{ demoModeStrings?.inDemoMode | transloco }}</span>
  </div>
</ng-template>
