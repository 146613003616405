/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UCID_STORAGE_KEY, UI_API_CALL } from '@Terra/shared/widgets/config';
import { localStorageHelper } from '@Terra/shared/widgets/utils';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { configStrings } from './interceptor.config';
import { checkIfUrlIncludesCacheEndpoints } from './interceptor.helper';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  xApplicationId: string;
  UCID: string;
  ucidData: string;
  urlListAPIGee = ['https://api', 'https://pfm', 'Ocp-Apim-Subscription-Key', '/ccds'];

  constructor(private readonly store: Store<any>) {
    this.store
      .select((state: any) => state.app.selectedParty)
      .pipe(filter(res => !!res))
      .subscribe(response => {
        this.xApplicationId = JSON.stringify(response.applicationID);
        if (response) {
          this.ucidData = response['ucid'];
        }
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('localhost') > -1) {
      // change base url
      request = request.clone({ url: 'https://int-vlproductivity.cat.com' + request.url.split('https://localhost.cat.com:4200')[1] });
    } else if (request.url[0] === '/') {
      request = request.clone({ url: 'https://int-vlproductivity.cat.com' + request.url });
    }
    // request = request.clone({ headers: request.headers.set('origin', 'https://int-vlproductivity.cat.com') });

    if (!this.isApiGeeURL(request.urlWithParams) && !request.headers.has('x-amz-server-side-encryption')) {
      let ucid = this.ucidData ? this.ucidData : localStorageHelper.getItem(UCID_STORAGE_KEY);
      let trackingId = uuidv4();

      if (request.headers.has(configStrings.xOrganizationId)) {
        ucid = request.headers.get(configStrings.xOrganizationId);
      }

      if (request.headers.has(configStrings.xCatAPITrackingId)) {
        trackingId = request.headers.get(configStrings.xCatAPITrackingId);
      } else if (request.body?.customheaders?.includes(configStrings.xCatAPITrackingId)) {
        trackingId = JSON.parse(request.body.customheaders)[configStrings.xCatAPITrackingId];
      }
      const customHeaders = {
        'x-application-id': this.xApplicationId ? this.xApplicationId : configStrings.xApplicationId,
        ...(ucid && { [configStrings.xOrganizationId]: ucid }),
        [configStrings.xCatAPITrackingId]: trackingId
      };

      if (request.headers.has(configStrings.contentType)) {
        customHeaders[configStrings.contentType] = configStrings.applicationJson;
      }
      if (request.headers.has(configStrings.xpackageName)) {
        customHeaders[configStrings.xpackageName] = request.headers.get(configStrings.xpackageName);
      }
      if (request.headers.has(configStrings.acceptLanguage)) {
        customHeaders[configStrings.acceptLanguage] = request.headers.get(configStrings.acceptLanguage);
      }
      if (request.headers.has('CustomAccept')) {
        customHeaders['CustomAccept'] = request.headers.get('CustomAccept');
      }

      // Added for Bug #39569
      if (request.headers.has('segmentCode')) {
        customHeaders['segmentCode'] = request.headers.get('segmentCode');
      }
      if (request.headers.has('segmentKey')) {
        customHeaders['segmentKey'] = request.headers.get('segmentKey');
      }

      request = request.clone({ headers: request.headers.set('customheaders', JSON.stringify(customHeaders)) });

      if (request.headers.has(configStrings.contentType)) {
        request = request.clone({
          headers: request.headers.set(configStrings.contentType, request.headers.get(configStrings.contentType))
        });
      } else {
        request = request.clone({ headers: request.headers.set(configStrings.contentType, configStrings.applicationJson) });
      }

      if (request.headers.has(configStrings.xHarvesterApplicationUcid)) {
        request = request.clone({
          headers: request.headers.set(
            configStrings.xHarvesterApplicationUcid,
            request.headers.get(configStrings.xHarvesterApplicationUcid)
          )
        });
      }
      //For Harverste apigee update
      if (request.headers.has(configStrings.xMixedfleetintUcid)) {
        request = request.clone({
          headers: request.headers.set(configStrings.xMixedfleetintUcid, request.headers.get(configStrings.xMixedfleetintUcid))
        });
      }

      if (request.headers.has('Accept')) {
        request = request.clone({ headers: request.headers.set('Accept', request.headers.get('Accept')) });
      } else {
        request = request.clone({ headers: request.headers.set('Accept', configStrings.applicationJson) });
      }
      if (checkIfUrlIncludesCacheEndpoints(request.url)) {
        request = request.clone({
          headers: request.headers.set('Cache-Control', 'public').set('Expires', `${new Date(Date.now() + 28800 * 1000).toUTCString()}`)
        });
      } else {
        request = request.clone({
          headers: request.headers
            .set('Cache-Control', 'no-cache')
            // .set('Pragma', 'no-cache')
            .set('Expires', `${new Date(Date.now() + 28800 * 1000).toUTCString()}`)
          // .set('If-Modified-Since', '0') //commented as static JSON files are not loading with this header
        });
      }
      if (request.headers.has('hostURL')) {
        request = request.clone({ url: request.headers.get('hostURL') + request.url });
      }
    } else if (request.url.includes(UI_API_CALL)) {
      request = request.clone({ headers: request.headers.set(configStrings.xOrganizationId, this.ucidData) });
      request = request.clone({ headers: request.headers.set(configStrings.xApplicationIdConfig, this.xApplicationId) });
      const reqURL = request.url.replace('?' + UI_API_CALL, '');
      request = request.clone({ url: reqURL });
    }
    if (request.headers.has('canAddDelay')) {
      const delayValue = Number(request.headers.get('canAddDelay'));
      request.headers['normalizedNames'].delete('canAddDelay');
      return timer(delayValue).pipe(switchMap(() => next.handle(request)));
    } else {
      return next.handle(request);
    }
  }

  isApiGeeURL(requestUrl: string) {
    for (const url of this.urlListAPIGee) {
      if (requestUrl.indexOf(url) >= 0) {
        return true;
      }
    }
    return false;
  }
}
