import { MessageBarHorizontalPosition, MessageBarVerticalPosition } from '@cat-digital-workspace/shared-ui-core/message';

export const errorMessage = {
  commonErrMsg: 'app.errorMessage.process_ERR',
  unAuthorized: 'You do not have the required privileges to access this application',
  internalServerError: 'There is an error processing your request.Please contact customer support'
};

export const horizontalPosition: MessageBarHorizontalPosition = 'center';
export const verticalPosition: MessageBarVerticalPosition = 'top';
export const TOASTER_CONFIG = {
  TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  TOASTER_LIFE_TIME: 5000,
  HOST_TYPE: {
    OVERLAY: 'overlay',
    INLINE: 'inline'
  },
  H_POSITION: horizontalPosition,
  V_POSITION: verticalPosition
};
