<ng-container>
  @switch ((pkceAuthService?.isloggedIn$ | async)) { @case (true) {
  <app-lib [applicationID]="applicationId" [applicationRoutingPath]="routerPath" (stopRender)="navigateToMarketing()"></app-lib>
  } @case (false) { @if (isMarketingPage) {
  <router-outlet name="marketing"></router-outlet>
  } } @default {
  <app-widget-three-dot-spinner></app-widget-three-dot-spinner>
  } }

  <app-msal-redirect></app-msal-redirect>
</ng-container>
