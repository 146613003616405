import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { WebchatService } from './webchat.service';
import { WindowRef } from './windowref';

@NgModule({
  imports: [CommonModule],
  declarations: [ChatBotComponent],
  exports: [ChatBotComponent],
  providers: [WebchatService, WindowRef]
})
export class SharedChatbotModule {}
