/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ErrorHandler,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { CcMessageModule, MessageBar } from '@cat-digital-workspace/shared-ui-core/message';
import { CcModal, CcModalModule } from '@cat-digital-workspace/shared-ui-core/modal';
import { CcSideNavEventSource, SideNavModule } from '@cat-digital-workspace/shared-ui-core/side-nav';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  AFFILIATION_CODES,
  AppService,
  AppState,
  AppUtilService,
  ClearSearchParties,
  CONDITIONAL_MENU_OPTIONS,
  ConditionalMenuOptConfig,
  DATE_TIME_CONSTANTS,
  defaultLanguage,
  DEMO_MODE_USERS,
  EnvironmentState,
  FindImplicitPartyDetails,
  getEnableUpgradeLinkSelector,
  getFeatureList,
  getHeliosPreferencesData,
  getImplicitPartiesCount,
  getImplicitPartiesFailed,
  getImplicitPartiesList,
  GetPartiesAndPreferences,
  GetPartiesDetails,
  getPartiesFailed,
  getPartiesList,
  getPartiesSearchData,
  GetPendingUpgrade,
  getProductFamilyAvailableSelector,
  getProductFamilyConfigSelector,
  getSelectedParty,
  GetSessionAndHeliosParty,
  getTimezonesListSelector,
  getUserInfo,
  HeliosPreferencesLoaded,
  HOSTNAME_TO_ENV_CONFIG,
  IdentifyEnvironment,
  ImplicitPartiesLoaded,
  LoadFeatureListAction,
  LoadImplicitParties,
  LoadImplicitPartiesCount,
  LoadMenuConfig,
  LoadPrimaryPartyInfo,
  LoadProductFamilyConfigAction,
  LoadSelectedParty,
  LoadTimeZoneListAction,
  LoadTimeZoneListSuccessAction,
  Notification,
  NOTIFICATION_TYPE,
  NotificationResponse,
  PartiesDetails,
  PartiesFailed,
  PartiesLoaded,
  PARTY,
  Party,
  PARTY_SEARCH_DEFAULT_LIMIT,
  PartyDropdown,
  prefferedLanguages,
  ProductFamilyConfigLoadSuccessAction,
  ResetAppStateAction,
  ResetFeatureListAction,
  SearchPartiesLoaded,
  TimeZoneList,
  UCIDTranslation,
  UpdateBrowserCompatibility,
  USER_ROLES,
  USER_ROLES_NAME,
  UserInfoLoaded
} from '@Terra/pitmanagement/app-store';
import { ChangeAccountComponent } from '@Terra/pitmanagement/shared/change-account';
import { DomClickDetectDirective } from '@Terra/pitmanagement/shared/directive';
import {
  CPUtilService,
  CPUtilsModule,
  DEMO_MODE_KEY,
  GlobalUtilService,
  isDemoModeON,
  isDemoModeONForInterceptor,
  removeDemoMode,
  setDemoModeStatus,
  setOrRemoveReloadStatus,
  setReloadStatusForDemoMode
} from '@Terra/pitmanagement/shared/utils';

import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { CcListModule } from '@cat-digital-workspace/shared-ui-core/cc-list';
import { CcDropdownModule } from '@cat-digital-workspace/shared-ui-core/dropdown';
import { CcInputFieldModule } from '@cat-digital-workspace/shared-ui-core/input-field';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { CcTopNavModule } from '@cat-digital-workspace/shared-ui-core/top-nav';
import { AppUserNavModule, DEMO_MODE_STRINGS } from '@Terra/pitmanagement/shared/app-user-nav';
import { PitmanagementHttpInterceptorModule } from '@Terra/pitmanagement/shared/http-interceptor';
import { UpgradeRoleComponent } from '@Terra/pitmanagement/shared/upgrade-role';
import { SharedChatbotModule } from '@Terra/shared/chatbot';
import { FooterModule } from '@Terra/shared/footer';
import { GlobalFileExportPollingModule, GlobalFileExportPollingService } from '@Terra/shared/global-file-export-polling';
import { GOOGLE_TAG_ACTIONS, GoogleTagManagerService, GoogleUtilService } from '@Terra/shared/google-analytics';
import { HttpInterceptorModule } from '@Terra/shared/http-interceptor';
import { MonitoringErrorHandler, MonitoringService } from '@Terra/shared/insight-analytics';
import {
  BC_RESERVED_ERR_CODEs,
  BC_RESERVED_MSG_IDs,
  BCError,
  BCMessage,
  BCP_LANGUAGE_CODES,
  BroadcastChannelService,
  CIBroadcastChannel,
  CP_USER_PREFERENCE_HELIOS_VALUE,
  HideAppSpinner,
  SharedAccessGuard,
  SharedLibModule,
  SharedLibService,
  SharedLibState
} from '@Terra/shared/shared-lib';
import { ResetFlags, UserPreferencesModule, UserPreferencesState } from '@Terra/shared/userpreferences';
import { AppNotification, AppNotificationsModule, NotificationType } from '@Terra/shared/widgets/app-notifications';
import { APP_ENV, CONSENT_STATUS, MAP_UCID, UCID_STORAGE_KEY, UCID_EVENTS as ucidEvents } from '@Terra/shared/widgets/config';
import {
  ConsentEvent,
  CustomWindow,
  Feature,
  FeatureList,
  HeliosPreference,
  HeliosUserPreference,
  OneTrust,
  Preference,
  ProductFamilyConfig,
  SelectedParty,
  UserInfo
} from '@Terra/shared/widgets/interface';
import { GApiLoaderService } from '@Terra/shared/widgets/map-leaflet';
import { COOKIES_TAG, oneTrustHelper, WindowRef } from '@Terra/shared/widgets/one-trust';
import { MSAL_INTERACTION_STATUS, PkceAuthService } from '@Terra/shared/widgets/pkce-auth';
import { SpinnerModule } from '@Terra/shared/widgets/spinner';
import {
  BrowserInfo,
  dateTimeFormatterHelper,
  localStorageHelper,
  momentHelper,
  momentTimeFormatterHelper,
  setOmsMenuOptInSessionStorage,
  showOmsMenuOptionAndTab,
  utilsHelper
} from '@Terra/shared/widgets/utils';
import { DurationInputArg2, unitOfTime } from 'moment-timezone';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import {
  BC_CHANNELS,
  BC_ERR_CODES,
  BC_MSG_IDs,
  BROWSER_COMPATIBILITY_CONFIG,
  CHANGE_ACCOUNT_MODAL_WIDTH,
  CHATBOT_STYLE_OPTION,
  CONTACT_SUPPORT_URL,
  DEFAULT_FOR_NON_SUPPORTED_LANGUAGE,
  DEFAULT_LANGUAGE,
  DEFAULT_ROUTE_PATH,
  DEMO_MODE_RESTRICTED_URLS,
  FEATURE_MENULIST,
  FILE_MENU,
  LEGAL_POPUP_WIDTH,
  LOCAL_STORAGE_KEYS,
  LOGOUT_MODAL_WIDTH,
  MENU_ACTIONS,
  NOTIFICATION_STORAGE_CONSTANTS,
  OPTANON_COOKIES,
  PAGE_PATH,
  PREFERENCE_MODAL_WIDTH,
  ROOT_RESTRICTED_PAGES,
  SEMI_MODAL,
  SIDE_NAV_CONFIG,
  TOASTER_CONFIG,
  UCID_TRANSLATION_TEXT,
  URL_POSITION,
  USER_NAV_ACTIONS,
  USER_ROLE_READ_ONLY,
  USER_TYPES
} from './appLib.config';
import {
  allAssetBaseUrl,
  allAssetEntryRoute,
  CHAT_BOT,
  DEMO_MODE_FEATURE_FLAG,
  MARKETING_CONSTANTS,
  remoteListDetailsUrl,
  remoteListUrl
} from './appLib.constants';
import { StyleOptions } from './appLib.models';

@Component({
  selector: 'app-lib',
  templateUrl: './app-lib.component.html',
  styleUrls: ['./app-lib.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    CcTopNavModule,
    AppUserNavModule,
    SideNavModule,
    NgClass,
    CcTooltipModule,
    FooterModule,
    RouterOutlet,
    AppNotificationsModule,
    SharedLibModule,
    SharedChatbotModule,
    CatButtonModule,
    UserPreferencesModule,
    UpgradeRoleComponent,
    DomClickDetectDirective,
    NgTemplateOutlet,
    ChangeAccountComponent,
    HttpClientModule,
    GlobalFileExportPollingModule,
    HammerModule,
    HttpInterceptorModule,
    SpinnerModule,
    CPUtilsModule,
    CcModalModule,
    CcMessageModule,
    CcDropdownModule,
    CcListModule,
    ReactiveFormsModule,
    ScrollingModule,
    CcInputFieldModule,
    FormsModule,
    PitmanagementHttpInterceptorModule
  ],
  providers: [
    WindowRef,
    AppService,
    PkceAuthService,
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: ErrorHandler, useClass: MonitoringErrorHandler }
  ]
})
export class AppLibComponent implements OnInit, OnDestroy {
  window: CustomWindow;
  subscriptions = new Subscription();
  @Input() applicationID!: number;
  @Input() applicationRoutingPath!: string;
  @Output() stopRender: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('userPreferences') userPreferences: any;
  @ViewChild('logoutTemplate') logoutTemplate: any;
  @ViewChild('changeAccountPreferences') changeAccountPreferences: any;
  @ViewChild('legalPopupFooterTemplate', { read: ViewContainerRef }) legalPopupFooterRef!: ViewContainerRef;
  storageObservable$: Observable<Event>;

  outletSubscription = new Subscription();
  translocoSubscription = new Subscription();
  collapseWidth = SIDE_NAV_CONFIG.collapseWidth;
  expandWidth = SIDE_NAV_CONFIG.expandWdith;
  broadcastChannel: CIBroadcastChannel;
  isProductFamilyMappingAvailable = false;
  productFamilyConfig: ProductFamilyConfig;
  timezonesList: Array<TimeZoneList> = [];
  isPageViewsListened: boolean;
  browserCompatibilityFlag = false;
  mobileDevice = false;
  isPreferenceAvailable = false;
  preferences: Preference;
  heliosPreference: HeliosUserPreference;
  allowPreferenceClose = false;
  skipModalClose = false;
  showUserPreference = false;
  resetUpdatePreference = false;
  showBody = false;
  preferenceModal;
  changeAccountModal;
  isTranslationsAvailable = false;
  isConsentAccepted = false;
  currentLang = 'en-us';
  previousLanguage = '';
  isLangChange = false;
  nonEssentialCookies = false;
  legalPopupRef: CcModal;

  // Properties to handle the account selection dropdown in header
  ucidListDropDown: PartyDropdown[] = [];
  defaultUcidDropDown: PartyDropdown[];
  storedUcidListDropDown: PartyDropdown[] = [];
  ucidTranslationText: UCIDTranslation = UCID_TRANSLATION_TEXT;
  selectedParty: SelectedParty;
  parties: Party[] = [];
  implicitParties: Party[] = [];
  implicitPartiesDropdown: PartyDropdown[] = [];
  implicitSearchParties: PartyDropdown[] = [];
  storedImplicitParties: Party[] = [];
  implicitPartiesCount: number;
  pickedByImplicitSearch = false;
  TotalImplicitPartiesCount: number;
  searchResponse: PartyDropdown[] = [];
  partiesNextCursor: string;
  ImplicitPartiesNextCursor: string;
  partiesSearchNextCursor: string;
  ImplicitPartiesSearchNextCursor: string;
  isDataLoaderActive = false;
  isPartyDataLoadError = false;
  isImplicitDataLoaderActive = false;
  isImplicitDataLoadError = false;
  isUcidValid = false;
  findingParty = false;
  hasAccess = false;
  showLegal = false;
  featureMenuListToRemove: string[] = FEATURE_MENULIST;
  isRootAccountPage = false;
  rootPageTitle: string;
  ucidChangedInOtherPage = false;
  isCATParty = false;

  userInfo: UserInfo;
  userInitials = '';
  userName = '';
  userRole = '';
  userType = '';

  userNavActions = USER_NAV_ACTIONS;
  enableUpgrade: boolean;
  showUpgrade = false;
  selectedPath: string;
  topNavConfig = [];
  isSideNavOpen = false;
  sideNavConfig = []; //add interface
  hideSideNav = false;
  defaultPreferenceCP = false;
  ucidName: string;
  navigateFlag: boolean;
  hideMobileUserInfo = false;
  mobilePageTitle: string;

  notificationData: AppNotification[] = [];
  notification: Notification;
  failedNotifications: AppNotification[] = [];
  helpUrl: string;
  contactSupportUrl = '';
  isOneTrustManaged = true;
  oneTrustData: OneTrust;
  isFunctionalCookieEnabled: boolean;
  isWhatFixLoaded: boolean;
  enableChatBot = false;
  directlineSecret: string;

  styleOptions: StyleOptions = CHATBOT_STYLE_OPTION;
  isInitialLoad = true;
  showChangeAccountPopover = false;
  showMore: string;
  implicitShowMore: string;
  isFooterLegalLoaded = false;
  isMsalLogoutTriggered = false;
  showUpgradeModal = false;
  showDemoMode: boolean;
  autoCollapse: boolean;
  isPrefAvailable = new Subject<Preference>();
  demoModeStatus = isDemoModeONForInterceptor();
  demoModeStrings = DEMO_MODE_STRINGS;
  isCPCookieAvailable = () => utilsHelper.getCPCookie();
  getCookieState = () => !localStorageHelper.getItem(MARKETING_CONSTANTS.COOKIE_SETTINGS);
  getBrowserLanguage = () => navigator.language;
  isPreferenceMandatory = () => this.showUserPreference && !this.allowPreferenceClose;
  isMarketingPage = () => this.router.url === '/';
  isSuperAdminUnderRoot = () =>
    USER_ROLES.SUPER_ADMIN === this.selectedParty?.userRoleID && this.selectedParty?.partyName === PARTY.ROOT.partyName;
  featureListResponse: FeatureList;
  _breakPointSubscription: Subscription;

  constructor(
    private readonly sharedAccessGuard: SharedAccessGuard, // this line is needed for the shared access guard to work
    private readonly sharedLibStore: Store<SharedLibState>,
    private readonly appStore: Store<AppState>,
    private readonly preferenceStore: Store<UserPreferencesState>,
    private readonly router: Router,
    private readonly envStore: Store<EnvironmentState>,
    private readonly translocoService: TranslocoService,
    private readonly monitorservice: MonitoringService,
    private readonly gtmService: GoogleTagManagerService,
    public breakpointObserver: BreakpointObserver,
    public appService: AppService,
    private readonly cpUtilService: CPUtilService,
    private readonly globalUtilService: GlobalUtilService,
    private readonly sharedLibService: SharedLibService,
    private readonly fileExportPollingService: GlobalFileExportPollingService,
    private readonly bcService: BroadcastChannelService,
    public modal: CcModal,
    public messageBar: MessageBar,
    private readonly appUtilService: AppUtilService,
    public googleUtilService: GoogleUtilService,
    private readonly deviceService: DeviceDetectorService,
    private readonly winRef: WindowRef,
    public pkceAuthService: PkceAuthService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.getUserInfoFromToken();
    this.getAppLoadInfo();
    this.window = this.winRef.nativeWindow;
    this.gtmService.addGtmToDom();
    this.isMobileDevice();
    this.monitorservice.listenPageViews(false);
    oneTrustHelper.initialiseOneTrust();
    this.subscribeToOneTrust();
    this.loadStyle('non-critical');
  }

  getAppLoadInfo(): void {
    this.googleUtilService.googleAPIKey.pipe(first()).subscribe(apiKey => {
      GApiLoaderService.init(apiKey, DEFAULT_LANGUAGE);
    });
    this.googleUtilService.getAppLoadInfo();
  }

  getUserInfoFromToken(): void {
    this.pkceAuthService.getUserInfoFromToken();
  }

  // To Load non-critical.scss styles
  loadStyle(styleName: string) {
    const body = document.getElementsByTagName('body')[0];
    const style = document.createElement('link');
    style.id = 'lazy-load';
    style.rel = 'preload';
    style.as = 'style';
    style.href = `${styleName}.css`;
    style.onload = function () {
      style.rel = 'stylesheet';
      this['rel'] = 'stylesheet';
    };
    body.appendChild(style);
  }

  ngOnInit() {
    this.storageObservable$ = fromEvent(window, 'storage');
    this.redirectToDefaultRouteInDemoMode();
    this.checkIfPkceToListenLocalStorage();
    this.setApplicationLanguage();
    this.identifyEnv();
    this.subcribeAppStore();
    this.subscribeToUserInfo();
    this.subscribeConsentStatus();

    this.subscribeToSearchParties();
    this.subscribeToPartiesData();
    this.subscribeToPartiesFailed();
    this.subscribeToImplicitPartiesFailed();
    this.subscribeToImplicitPartiesCount();
    this.subscribeToUpgradeLink();
    this.subscribeToUpdatedPreference();
    this.subscribeToPreferenceSaveFailure();
    this.subscribeToLogOut();
    this.subscribeToFailedExports();
    this.subscribeHelpUrl();
    this.onNavigationEnd();
    this.addStorageListner();
    this.checkBrowserCompatibility();
    this.subscribeIsPrefAvail();
    this._breakPointSubscription = this.breakpointObserver
      .observe([Breakpoints.TabletLandscape, Breakpoints.TabletPortrait, Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((result: BreakpointState) => {
        this.autoCollapse = result.matches;
        this.isSideNavOpen = false;
      });
  }

  checkIfPkceToListenLocalStorage(): void {
    this.subscribeLocalStorage();
  }

  /**
   * Redirects to Jobsite dashboard page when Demo Mode is ON
   * If we are in INSIGHTS page, we should just reload the page
   */
  redirectToDefaultRouteInDemoMode(): void {
    const url = this.window.location?.hash?.split('/')[1];
    setDemoModeStatus(this.window.localStorage.getItem(DEMO_MODE_KEY));
    if (isDemoModeON() && DEMO_MODE_RESTRICTED_URLS.includes(url)) {
      this.router.navigate([DEFAULT_ROUTE_PATH]);
    }

    setOrRemoveReloadStatus();
  }

  /**
   * @description Method to detect browser's language and load it
   */
  setApplicationLanguage(): void {
    const languageCode = this.appUtilService.getBrowserLanguage();
    this.translocoService.setActiveLang(languageCode);
    const translocoStub = this.translocoService.selectTranslation(languageCode).subscribe(() => {
      if (!this.isCPCookieAvailable()) {
        this.isTranslationsAvailable = true;
      }
    });
    this.translocoSubscription.add(translocoStub);
  }

  /**
   * @description Method to identify host name and this. to store
   */
  identifyEnv(): void {
    const envMapping = {
      'https://demo-vlp.cat.com': 'https://int-vlproductivity.cat.com'
    };
    const hostName = new URL(this.window.location.href).host;
    let env = Object.keys(HOSTNAME_TO_ENV_CONFIG).find(host => hostName.indexOf(host) !== -1) || HOSTNAME_TO_ENV_CONFIG.prod;
    env = HOSTNAME_TO_ENV_CONFIG[env];
    this.contactSupportUrl = CONTACT_SUPPORT_URL[env];
    this.envStore.dispatch(
      new IdentifyEnvironment(
        env,
        envMapping[this.window.location.origin] ? envMapping[this.window.location.origin] : this.window.location.origin
      )
    );
  }

  /**
   * subscribe local storage change events
   */
  subscribeLocalStorage(): void {
    this.subscriptions.add(
      this.storageObservable$.subscribe(() => {
        const itemKey = MSAL_INTERACTION_STATUS;
        if (!localStorage.getItem(UCID_STORAGE_KEY)) {
          if (sessionStorage.getItem(itemKey)) {
            sessionStorage.removeItem(itemKey);
          }
          !this.isMsalLogoutTriggered && this.pkceAuthService.logoutRedirect();
          this.isMsalLogoutTriggered = true;
        }
      })
    );
  }

  subcribeAppStore(): void {
    if (this.isCPCookieAvailable()) {
      this.isTranslationsAvailable = false;
      this.pkceAuthService.msaluserInfo$.pipe(filter((data: UserInfo) => !!data)).subscribe({
        next: userInfo => {
          if (userInfo) {
            this.appStore.dispatch(new UserInfoLoaded(userInfo));
            this.userInfo = userInfo;
            this.userType = this.appUtilService.getUserType(userInfo.catafltncode);
            this.getPartiesAndPreferences();
          }
        },
        error: () => {
          this.getPartiesAndPreferences();
        }
      });
    } else {
      this.isPreferenceAvailable = this.isConsentAccepted = true;

      this.stopRender.emit(true);
    }
    this.subscriptions.add(
      this.appStore.select(getProductFamilyAvailableSelector).subscribe(isAvailable => {
        this.isProductFamilyMappingAvailable = isAvailable;
      })
    );
    this.subscriptions.add(
      this.appStore.select(getProductFamilyConfigSelector).subscribe(config => {
        this.productFamilyConfig = config as ProductFamilyConfig;
      })
    );
    this.subscriptions.add(
      this.appStore.select(getTimezonesListSelector).subscribe(config => {
        this.timezonesList = config;
      })
    );
  }

  subscribeConsentStatus(): void {
    this.subscriptions.add(
      this.appUtilService.legalUtil.consentStatus$.pipe(filter(response => !!response)).subscribe(consentInfo => {
        this.legalPopupRef?.close();
        if (consentInfo.status === CONSENT_STATUS.AGREE) {
          this.isConsentAccepted = true;
          this.subscribeToSelectedParty();
          this.fetchHeliosPreferences();
          this.getAppNotifications();
          this.subscribeAppPreference();
          this.subscribeToPartiesDetails();
          this.subscribeToImplicitParties();
          this.subscribeToFeatureList();
        } else {
          if (consentInfo.isFromPopup) {
            this.legalPopupRef?.close();
            this.appStore.dispatch(new ResetAppStateAction());
            this.setApplicationLanguage();
            this.appStore.dispatch(new PartiesFailed({ failedStatus: 401, isCustSummary: false }));
          } else {
            this.showLegalPopup();
          }
        }
      })
    );
  }

  subscribeAppPreference(): void {
    this.subscriptions.add(
      this.appStore
        .select(getHeliosPreferencesData)
        .pipe(filter(response => !!response))
        .subscribe((heliosPreferences: HeliosPreference) => {
          this.globalUtilService.$preferenceData.next(heliosPreferences.appPreference);
        })
    );
  }

  subscribeToUserInfo(): void {
    this.subscriptions.add(
      this.appStore
        .select(getUserInfo)
        .pipe(filter(response => !!response))
        .subscribe(userInfo => {
          const getFirstName = () => (userInfo.given_name?.trim() ? userInfo.given_name?.trim() : '');
          const getLastName = () => (userInfo.family_name?.trim() ? userInfo.family_name?.trim() : '');
          const firstName = getFirstName();
          const lastName = getLastName();
          this.userInitials = `${firstName[0] || ''}${lastName[0] || ''}`;
          this.userName = `${firstName || ''} ${lastName || ''}`;
          userInfo.catrecid && localStorage.setItem('unique-user-id', userInfo.catrecid);
        })
    );
  }

  //method to get currently selected Party details
  subscribeToSelectedParty(): void {
    this.subscriptions.add(
      this.appStore
        .select(getSelectedParty)
        .pipe(filter(response => !!response))
        .subscribe((response: SelectedParty) => {
          this.globalUtilService.$ucidData.next(response);
          this.fileExportPollingService.cancelPolling();
          this.checkAndAddWhatFix();
          this.selectedParty = JSON.parse(JSON.stringify(response));
          this.userRole = USER_ROLES_NAME[this.selectedParty.userRoleID];
          this.ucidName = this.selectedParty.partyName ? this.selectedParty.partyName : this.selectedParty.ucid;
          const partyType = this.selectedParty.ucid === USER_TYPES.CAT ? this.selectedParty.ucid : USER_TYPES.CUSTOMER;
          this.setGlobalVariablesForWhatfix(partyType, this.userRole, this.selectedParty.catRecID);
          this.ucidChanged();
          this.updateUpgradeLink(this.selectedParty);
        })
    );
  }

  subscribeToPartiesDetails(): void {
    this.subscriptions.add(
      this.appStore
        .select(getPartiesList)
        .pipe(filter(response => !!response))
        .subscribe(response => {
          if (response.parties?.length) {
            this.findingParty = false;
            this.parties = response.parties;
            this.partiesNextCursor = this.showMore = response.nextCursor;
            //after getting first 100 parties, check if parties stored in both local storage & helios present in the list or not.
            //if not present make cust summary search calls to find (this is applicable for super admin/user).
            if (response.isFirst100 && this.appUtilService.isSuperAdminOrSuperUser(this.parties)) {
              this.checkSessionAndHeliosParty();
            }
            if (!this.findingParty) {
              this.isDataLoaderActive = false;
              this.isPartyDataLoadError = false;
              this.skipModalClose = !!response.skipModalClose;
              this.ucidListDropDown = this.prepareUCIDList(this.parties);
              //a copy of ucid dropdown list is stored in order to restore on clear search scenario.
              this.storedUcidListDropDown = JSON.parse(JSON.stringify(this.ucidListDropDown));
              this.setDefaultParty(this.selectedParty?.ucid);
              if (this.userType !== USER_TYPES.DEALER) {
                this.checkAndOpenPreferencePopup(response);
              }
            }
          }
        })
    );
  }

  subscribeToSearchParties(): void {
    this.subscriptions.add(
      this.appStore
        .select(getPartiesSearchData)
        .pipe(filter(response => !!response))
        .subscribe(response => {
          this.isDataLoaderActive = false;
          this.isPartyDataLoadError = false;
          if (response.parties?.length) {
            //parties list received from cust summary search api response is binded to ucid dropdown
            this.searchResponse = this.ucidListDropDown = this.prepareUCIDList(response.parties);
            this.partiesSearchNextCursor = this.showMore = response.nextCursor;
            this.skipModalClose = response.skipModalClose;
          } else if (response.isClearParties) {
            //on clear search, previous parties list stored before search is binded to ucid dropdown
            this.ucidListDropDown = [...this.storedUcidListDropDown];
            this.partiesSearchNextCursor = null;
            this.showMore = this.partiesNextCursor;
          }
          if (!this.preferenceModal && !this.changeAccountModal) {
            this.setDefaultParty(this.selectedParty?.ucid);
          }
        })
    );
  }

  /**
   * To navigate the inner router-outlet to dashboard when landing initially
   */
  subscribeToPartiesData(): void {
    this.subscriptions.add(
      this.appStore
        .select((state: any) => state.app.partiesList)
        .pipe(filter(response => !!response))
        .subscribe(response => {
          if (response?.parties?.length && this.router.url === '/' && this.window.location.hash === '#/') {
            this.router.navigate(['dashboard'], { state: { allAssetsRedirection: true } });
          }
        })
    );
  }

  subscribeToPartiesFailed(): void {
    this.subscriptions.add(
      this.appStore
        .select(getPartiesFailed)
        .pipe(filter(response => !!response))
        .subscribe(response => {
          if (response.isCustSummary) {
            //cust summary api failure scenario to show fetch error
            this.isDataLoaderActive = false;
            this.isPartyDataLoadError = true;
          } else if ([403, 401, 429, 404].includes(response.failedStatus)) {
            //in case of unauthorized scenario, redirecting the user to market page
            this.isTranslationsAvailable = this.isPreferenceAvailable = this.isConsentAccepted = true;
            this.stopRender.emit(true);
          } else {
            //in case of terra auth api or initial cust summary api failures, Toast message is shown
            this.displayToastMessage('app.errorMessage.process_ERR', TOASTER_CONFIG.TYPE.ERROR);
          }
        })
    );
  }

  subscribeIsPrefAvail(): void {
    this.subscriptions.add(
      this.isPrefAvailable.subscribe((pref: Preference) => {
        if (pref) {
          this.checkAndAddWhatFix();
        }
      })
    );
  }

  subscribeToImplicitParties(): void {
    this.subscriptions.add(
      this.appStore
        .select(getImplicitPartiesList)
        .pipe(filter(response => !!response))
        .subscribe(response => {
          if (response.pickedBySearch) {
            this.loadImplicitParties(response);
          } else {
            const isPrefSearchDone = () =>
              response.prefSearchDone ||
              (response.limit === 1 && !response.isPartySearchRequired) ||
              (!isSearchInProgress && !this.preferences?.ucid);
            const isSearchInProgress = this.searchForPrefParty(response);
            if (!isSearchInProgress) {
              this.loadImplicitParties(response);
            }
            if (isPrefSearchDone()) {
              this.checkAndOpenPreferencePopup();
            }
          }
        })
    );
  }

  searchForPrefParty(response: PartiesDetails): boolean {
    if (response.limit === 1 && response.isPartySearchRequired && this.preferences.ucid) {
      this.appStore.dispatch(new FindImplicitPartyDetails({ partyNumber: this.preferences.ucid, userInfo: this.userInfo }));
      return true;
    } else {
      return false;
    }
  }

  loadImplicitParties(response: PartiesDetails): void {
    this.isImplicitDataLoaderActive = false;
    if (response.parties?.length) {
      this.implicitParties = response.parties;
      this.implicitPartiesDropdown = this.prepareUCIDList(response.parties);
      if (response.searchValue) {
        this.implicitSearchParties = JSON.parse(JSON.stringify(this.implicitPartiesDropdown));
        this.ImplicitPartiesSearchNextCursor = this.implicitShowMore = response.nextCursor;
      } else {
        this.storedImplicitParties = JSON.parse(JSON.stringify(this.implicitParties));
        this.ImplicitPartiesNextCursor = this.implicitShowMore = response.nextCursor;
      }
    } else if (response.searchValue) {
      this.implicitPartiesDropdown = [];
      this.implicitPartiesCount = 0;
      this.ImplicitPartiesSearchNextCursor = null;
    }
  }

  subscribeToImplicitPartiesFailed(): void {
    this.subscriptions.add(
      this.appStore.select(getImplicitPartiesFailed).subscribe(response => {
        if (response) {
          this.isImplicitDataLoaderActive = false;
          this.isImplicitDataLoadError = true;
          //api failure scenario to show fetch error for implicit section
        }
      })
    );
  }

  subscribeToImplicitPartiesCount(): void {
    this.subscriptions.add(
      this.appStore.select(getImplicitPartiesCount).subscribe(response => {
        this.implicitPartiesCount = response?.count;
        if (response?.isTotal) {
          this.TotalImplicitPartiesCount = response.count;
        }
      })
    );
  }

  /* Getting Features list to show ucid specific menu & features only if corresponding feature present in the list*/
  subscribeToFeatureList(): void {
    //used combineLatest as we need selected party's userRoleId for featureMenuList validation as the calls are parallel
    const featuresSub = combineLatest([
      this.appStore.select(getFeatureList).pipe(filter(featureListResponse => !!featureListResponse)),
      this.appStore.select(getSelectedParty).pipe(filter(selectedPartyResponse => !!selectedPartyResponse))
    ]).subscribe(([featureListResponse, selectedPartyResponse]) => {
      this.featureMenuListToRemove = FEATURE_MENULIST;
      this.featureListResponse = featureListResponse;
      if (featureListResponse.features?.length) {
        this.enableChatBot = featureListResponse.features.some(feature => feature.name === CHAT_BOT);
        const enabledFeatures = featureListResponse.features.map(feature => feature.name);
        //removing feature based menu items when its not present in features api response
        this.featureMenuListToRemove = FEATURE_MENULIST.filter(name => !enabledFeatures.includes(name));
        this.demoModeConditions(featureListResponse.features);
      } else {
        //when error occurs during parallel call, need to call sequential call again with retry if needed
        if (!featureListResponse.canRetry) {
          this.resetAndDispatchLoadFeatureList(selectedPartyResponse?.ucid, true);
        }
      }
      //File Menu is displayed for super admins irrespective of features api response
      if (
        selectedPartyResponse &&
        selectedPartyResponse.userRoleID === USER_ROLES.SUPER_ADMIN &&
        selectedPartyResponse.ucid !== PARTY.ROOT.partyNumber &&
        this.featureMenuListToRemove.includes(FILE_MENU)
      ) {
        this.featureMenuListToRemove = this.featureMenuListToRemove.filter(name => name !== FILE_MENU);
      }
      this.populateMenuList();
    });
    this.subscriptions.add(featuresSub);
  }

  // Will Move to Helper file once Zuber's PR is merged to master
  demoModeConditions(features: Feature[]): void {
    // Showing Demo Mode using Feature Flag and for All CAT users & Dealers with 005 affaliation code
    this.showDemoMode =
      features.some((featureList: Feature) => featureList.name === DEMO_MODE_FEATURE_FLAG) && DEMO_MODE_USERS.includes(this.userType);
    if (!this.showDemoMode && isDemoModeON()) {
      removeDemoMode();
      this.window.location.reload();
    }
  }

  /**
   *method to get the enableUpgrade state
   */
  subscribeToUpgradeLink(): void {
    const upgradeLinkSubscription = this.appStore.select(getEnableUpgradeLinkSelector).subscribe(response => {
      this.enableUpgrade = response;
    });
    this.subscriptions.add(upgradeLinkSubscription);
  }

  //method to get custom and global helios preferences
  fetchHeliosPreferences(): void {
    const prefSubs = this.appStore
      .select(getHeliosPreferencesData)
      .pipe(
        filter(response => !!response),
        take(1)
      )
      .subscribe(preference => {
        this.preferences = JSON.parse(JSON.stringify(preference.appPreference));
        this.isPrefAvailable.next(this.preferences);
        this.setLanguageVariableForWhatfix(this.preferences?.language);
        this.defaultPreferenceCP = this.preferences.defaultPreferenceCP;
        // To Handle Non-supported languages - for setting the preferences
        if (!preference.appPreference.language) {
          this.preferences.defaultPreferenceCP = true;
          preference.appPreference.language = DEFAULT_FOR_NON_SUPPORTED_LANGUAGE;
        }
        this.isPreferenceAvailable = true;

        this.heliosPreference = preference.globalPreference;
        if (this.preferences.language || preference.globalPreference.language) {
          const selectedLang = this.sharedLibService.getValueFromPreferenceMatrix(
            preference.appPreference.language,
            this.heliosPreference.language,
            true
          );
          this.currentLang = selectedLang.fieldValue;
          const languageCode = BCP_LANGUAGE_CODES[selectedLang.fieldValue];
          this.translocoService.setActiveLang(languageCode);
          this.formNotificationsInSelectedLang(languageCode);
        }
      });
    this.subscriptions.add(prefSubs);
  }

  formNotificationsInSelectedLang(selectedLang: string): void {
    const translocoStub = this.translocoService.selectTranslation(selectedLang).subscribe(() => {
      this.isTranslationsAvailable = true;
      this.formNotifications();
    });
    this.translocoSubscription.add(translocoStub);
  }

  /* Preference Popup save subscription */
  subscribeToUpdatedPreference(): void {
    const prefSaveSubs = this.preferenceStore
      .select((state: any) => state.userPref.prefUpdated)
      .pipe(filter(response => !!response))
      .subscribe(response => {
        this.previousLanguage = this.translocoService.getActiveLang().toLowerCase();
        this.isLangChange = false;
        this.translocoService.setActiveLang(BCP_LANGUAGE_CODES[response.language]);
        const globalPreference = this.updateHeliosPreference(response);
        this.preferenceChange(response, globalPreference);
        this.showBody = true;
        this.displayToastAndMenuInSelectedLang(response);
        this.resetUpdatePreference = true;
      });
    this.subscriptions.add(prefSaveSubs);
  }

  displayToastAndMenuInSelectedLang(appPreference: Preference): void {
    const translocoStub = this.translocoService.selectTranslation(BCP_LANGUAGE_CODES[appPreference.language]).subscribe(() => {
      this.displayToastMessage(TOASTER_CONFIG.PREFERENCE_TEXT.SAVED, TOASTER_CONFIG.TYPE.SUCCESS);
      if (this.previousLanguage !== appPreference.language) {
        //isLangChange flag is used to avoid redirecting to dashboard on language change, when user is on some other page
        this.isLangChange = true;
        if (this.selectedParty.userRoleID) {
          this.populateMenuList();
        }
      }
      this.formNotifications();
    });
    this.translocoSubscription.add(translocoStub);
  }

  /* Preference save failure subscription */
  subscribeToPreferenceSaveFailure(): void {
    const prefSaveFailed = this.preferenceStore
      .select((state: any) => state.userPref.savePreferenceFailed)
      .subscribe(response => {
        if (response) {
          this.appStore.dispatch(new HideAppSpinner());
          this.displayToastMessage(TOASTER_CONFIG.PREFERENCE_TEXT.FAILED, TOASTER_CONFIG.TYPE.ERROR);
        }
      });
    this.subscriptions.add(prefSaveFailed);
  }

  subscribeToLogOut(): void {
    const logoutstub = this.sharedLibStore
      .select((state: any) => state.sharedLib.logout)
      .subscribe(res => {
        if (res) {
          this.onConfirmYes();
        }
      });
    this.subscriptions.add(logoutstub);
  }

  subscribeToFailedExports(): void {
    const failedExportsSub = this.fileExportPollingService.failedExports$.subscribe(failedExports => {
      this.failedNotifications = failedExports as AppNotification[];
      this.formNotifications();
    });
    this.subscriptions.add(failedExportsSub);
  }

  subscribeHelpUrl(): void {
    this.subscriptions.add(
      this.appStore
        .select((envState: any) => envState.environment)
        .subscribe(res => {
          this.helpUrl = res.helpUrl;
        })
    );
  }

  /**
   * method to load onetrust script and to update cookie toggleStatistics
   */
  subscribeToOneTrust(): void {
    oneTrustHelper.load(this.window).subscribe((oneTrust: OneTrust) => {
      this.oneTrustData = oneTrust;
      this.isFunctionalCookieEnabled = this.oneTrustData.isFunctionalCookie;
      this.checkAndAddWhatFix();
      this.checkAndEnableInsights(!this.oneTrustData.isPerformanceCookie);
      this.googleUtilService.performanceCookie$.next(this.oneTrustData.isPerformanceCookie);
      // this.updateOneTrustLanguage(this.preferences); //to be confirmed from OneTrust
      // whenever consent changing, check and update the
      this.oneTrustData?.OnConsentChanged((oneTrustEvent: ConsentEvent) => {
        const isFunctionalDisabled = !oneTrustEvent.detail.includes(COOKIES_TAG.FUNCTIONAL);
        const isPerformanceDisabled = !oneTrustEvent.detail.includes(COOKIES_TAG.PERFORMANCE);
        this.checkAndEnableInsights(isPerformanceDisabled);
        this.isFunctionalCookieEnabled = !isFunctionalDisabled;
        this.checkAndAddWhatFix();
        this.googleUtilService.performanceCookie$.next(!isPerformanceDisabled);
      });
    });
  }

  setGlobalVariablesForWhatfix(partyType: string, userRole: string, userIdentifier: string): void {
    this.window['_wfx_cat_partyType'] = { partyType };
    this.window['_wfx_cat_userRole'] = { userRole };
    this.window['_wfx_cat_userIdentifier'] = { userIdentifier };
  }

  setLanguageVariableForWhatfix(language: string): void {
    this.window['_language'] = language;
  }

  /**
   * method to subscribe web chatbot secret key
   */
  subscribeToWebchatSecretKey(): void {
    this.appService.getBotSecretKey().subscribe(result => {
      this.directlineSecret = result.botSecretKey;
    });
  }

  /*
    To check the router events while account selection change and prevent default account.
  */
  onNavigationEnd(): void {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        //when ucid changed in other page, after redirecting to dashboard, selected party is loaded.
        if (this.ucidChangedInOtherPage) {
          this.ucidChangedInOtherPage = false;
          this.loadPartyDispatchAction();
        }
        if (this.isSuperAdminUnderRoot()) {
          this.showRootPage();
        }
        const urlPath = utilsHelper.getRoutePathFromURL(event.urlAfterRedirects);
        if (this.selectedPath !== urlPath) {
          this.selectedPath = urlPath;
          // set this flag to false to prevent navigation from nav click
          this.navigateFlag = false;
        }
        this.checkAndHideSideNav(event.urlAfterRedirects);
      }
    });
  }

  addStorageListner(): void {
    const storageSub = fromEvent(this.window, 'storage').subscribe(event => {
      if ((event as any).key === LOCAL_STORAGE_KEYS.LOGOUT && (event as any).newValue === 'true') {
        this.resetUserSession();
        this.stopRender.emit();
      }
    });
    this.subscriptions.add(storageSub);
  }

  checkBrowserCompatibility(): void {
    this.browserCompatibilityFlag = false;
    const browserDetail = this.getBrowserInfo();
    const allBrowser = BROWSER_COMPATIBILITY_CONFIG;
    const selectedBrowser = allBrowser.find(
      browser =>
        browser.name.toLowerCase() === browserDetail.browserName.toLowerCase() &&
        browser.device.toLowerCase() === browserDetail.browserPlatform.toLowerCase()
    );
    if (selectedBrowser) {
      this.browserCompatibilityFlag =
        parseInt(browserDetail.browserVersion, 10) >= parseInt(selectedBrowser.version, 10) - selectedBrowser.range;
    }
    this.appStore.dispatch(new UpdateBrowserCompatibility(this.browserCompatibilityFlag));
  }

  checkAndHideSideNav(urlAfterRedirects: string): void {
    const urlArray = urlAfterRedirects.split('/');
    if (this.selectedPath === allAssetEntryRoute) {
      const checkUrl = urlAfterRedirects;
      this.hideSideNav = true;
      if (checkUrl === allAssetBaseUrl || urlArray[8] === URL_POSITION) {
        this.hideSideNav = false;
      }
    } else if (remoteListDetailsUrl === `${urlArray[1]}/${urlArray[2]}`) {
      this.hideSideNav = true;
    } else if (urlArray[1] === remoteListUrl) {
      this.hideSideNav = false;
    }
  }

  initBroadcastChannel(): void {
    if (!this.broadcastChannel) {
      this.broadcastChannel = this.bcService.createChannel(BC_CHANNELS.app);
      this.subscriptions.add(
        this.broadcastChannel.onMessage().subscribe((message: BCMessage) => {
          this.onBroadcastChannelMessage(message);
        })
      );
      this.subscriptions.add(
        this.broadcastChannel.onError().subscribe((error: BCError) => {
          this.onBroadcastChannelError(error);
        })
      );
    }
  }

  //method to avoid product family and timezone api calls if its already present in broadcase channel (e.g. the two calls will be avoided when user navigates from SD to insights)
  onBroadcastChannelMessage(message: BCMessage): void {
    switch (message.id) {
      case BC_RESERVED_MSG_IDs.connected: {
        const storeSynchMsg: BCMessage = {
          id: BC_MSG_IDs.synch_store_req
        };
        this.broadcastChannel.post(storeSynchMsg);
        break;
      }
      case BC_MSG_IDs.synch_store_req: {
        const storeDataMsg: BCMessage = {
          id: BC_MSG_IDs.synch_store_res,
          message: {
            productFamilyConfig: this.productFamilyConfig,
            timezonesList: this.timezonesList
          }
        };
        this.broadcastChannel.post(storeDataMsg);
        break;
      }
      case BC_MSG_IDs.synch_store_res:
        this.loadProductFamilyAndTimeZone(message.message);
        break;
    }
  }

  loadProductFamilyAndTimeZone(message: any): void {
    if (!this.isProductFamilyMappingAvailable && message?.productFamilyConfig) {
      this.appStore.dispatch(new ProductFamilyConfigLoadSuccessAction(message?.productFamilyConfig));
    }
    if (this.timezonesList.length === 0 && message?.timezonesList) {
      this.appStore.dispatch(new LoadTimeZoneListSuccessAction(message?.timezonesList));
    }
  }

  //method to make product family & timezone api calls in case data not already present in broadcast channel
  onBroadcastChannelError(error: BCError): void {
    switch (error.code) {
      case BC_RESERVED_ERR_CODEs.unsupported_browser:
      case BC_RESERVED_ERR_CODEs.no_peers:
      case BC_ERR_CODES.synch_store_fail:
        if (!this.isProductFamilyMappingAvailable) {
          this.appStore.dispatch(new LoadProductFamilyConfigAction());
          this.appStore.dispatch(new LoadTimeZoneListAction());
        }
        break;
    }
  }

  checkAndOpenPreferencePopup(partiesDetails?: PartiesDetails): void {
    //If ucid from helios not present in parties list, open preference popup (if block). popup will open for new users as well.
    //If user has saved preference in Helios, ucidChanged is immediately called (else block)
    if (this.defaultPreferenceCP || !this.checkIsUcidValid() || this.compareAppAndHeliosPreference()) {
      this.defaultPreferenceCP = false;
      this.showUserPreference = true;
      this.allowPreferenceClose = false;
      this.showBody = false;
      this.resetSaveUserPreference();
      this.defaultUcidDropDown = [];
      this.togglePreferenceDialog();
    } else {
      this.showUserPreference = false;
      this.allowPreferenceClose = true;
      this.showBody = true;
      const updatePartyData = () =>
        this.userType === USER_TYPES.DEALER ||
        ((partiesDetails.isFirst100 || partiesDetails.limit === 1) && !partiesDetails.pickedBySearch);
      if (updatePartyData()) {
        this.loadSelectedParty();
      }
    }
  }

  checkIsUcidValid(): boolean {
    if (this.userType !== USER_TYPES.DEALER) {
      this.isUcidValid = !!this.parties.find(party => party.partyNumber === this.preferences.ucid);
      return this.isUcidValid;
    } else {
      this.isUcidValid = !![...this.implicitPartiesDropdown, ...this.ucidListDropDown].find(
        party => party.data.partyNumber === this.preferences.ucid
      );
      return this.isUcidValid;
    }
  }

  //method to get Parties and Preference details during initial load
  getPartiesAndPreferences(): void {
    const sessionUcid = this.getPartyFromSession();
    //features list API will be called parallely along with parties, customPreference and globalPreference on page refresh
    if (this.getLocalStorageUCIDKey()) {
      this.resetAndDispatchLoadFeatureList(this.getLocalStorageUCIDKey(), false); //since no retry is needed for Features list parallel API call failure we are sending false for retry
    }
    this.appStore.dispatch(new GetPartiesAndPreferences({ sessionUcid, userInfo: { ...this.userInfo, userType: this.userType } }));
  }

  resetAndDispatchLoadFeatureList(ucid: string, canRetry: boolean): void {
    this.appStore.dispatch(new ResetFeatureListAction());
    this.appStore.dispatch(new LoadFeatureListAction(ucid, canRetry));
  }

  checkForOmsMenuOptPresence(): ConditionalMenuOptConfig {
    const paramsForOms = {
      featureListResponse: this.featureListResponse,
      selectedPartyResponse: this.selectedParty,
      userInfo: this.userInfo,
      userRoleName: this.userRole
    };
    const showOmsMenuOpt = showOmsMenuOptionAndTab(paramsForOms);
    setOmsMenuOptInSessionStorage(showOmsMenuOpt);
    const operatorsConfig = { ...CONDITIONAL_MENU_OPTIONS.Operators };
    return showOmsMenuOpt ? operatorsConfig : null;
  }

  checkForConditionalMenuOptions(): ConditionalMenuOptConfig[] {
    const conditionalMenu = [];
    Object.keys(CONDITIONAL_MENU_OPTIONS).forEach((configKey: string) => {
      const config = this[CONDITIONAL_MENU_OPTIONS[configKey].functionName]();
      if (config) {
        conditionalMenu.push(config);
      }
    });
    return conditionalMenu;
  }

  /* Method to build menu based on roleId and featureflag using local menu config */
  populateMenuList(): void {
    this.hasAccess = true;
    this.showLegal = true;
    const conditionalMenuOpt = this.checkForConditionalMenuOptions();
    this.sideNavConfig =
      this.appUtilService.formMenuList(this.selectedParty.userRoleID, this.featureMenuListToRemove, conditionalMenuOpt) ?? [];
    this.appStore.dispatch(new LoadMenuConfig(this.sideNavConfig)); //update menu config in store for route validation
    if (this.isLangChange) {
      this.navigateFlag = false;
    }
    // resetting to side menu's selected path name
    this.selectedPath = '';
    setTimeout(() => {
      this.selectedPath = utilsHelper.getRoutePathFromURL(this.router.url);
    }, 100);
  }

  /**
   * method checks if the logged in user is either standard or viewer
   * and dispatches the GetPendingUpgrade
   * @param partyData PartyData
   */

  updateUpgradeLink(partyData: SelectedParty): void {
    this.subscriptions.add(
      this.appStore
        .select(getUserInfo)
        .pipe(filter(response => !!response))
        .subscribe(userInfo => {
          if (AFFILIATION_CODES.ECUSTOMER.includes(userInfo.catafltncode) && !userInfo.catafltnorgcode) {
            this.showUpgrade = true;
            this.enableUpgrade = false;
            this.userRole = USER_ROLE_READ_ONLY;
          } else if (
            partyData.userRoleID !== USER_ROLES.VIEWER &&
            partyData.userRoleID !== USER_ROLES.STANDARD &&
            partyData.userRoleID !== USER_ROLES.DEALER_STANDARD
          ) {
            this.showUpgrade = false;
          } else {
            this.showUpgrade = true;
            this.appStore.dispatch(new GetPendingUpgrade(partyData.catRecID));
          }
        })
    );
  }

  /**Method to make feature list call based on selected ucid */
  ucidChanged(): void {
    const isPartiesPresent = this.userType === USER_TYPES.DEALER ? [...this.parties, ...this.implicitParties].length : this.parties.length;
    if (isPartiesPresent) {
      this.setDefaultParty(this.selectedParty.ucid);
      this.initBroadcastChannel();
      if (this.isSuperAdminUnderRoot()) {
        this.showRootPage();
      } else {
        this.isRootAccountPage = false;
      }
      //making Feature API call when selected party is valid
      //Features list API will be called sequentially on login and on UCID change in preference
      if (this.selectedParty.ucid && (!this.getLocalStorageUCIDKey() || this.selectedParty.ucid !== this.getLocalStorageUCIDKey())) {
        localStorageHelper.setItem(UCID_STORAGE_KEY, this.selectedParty.ucid);
        this.resetAndDispatchLoadFeatureList(this.selectedParty.ucid, true);
      }
    }
  }

  getLocalStorageUCIDKey(): string {
    return localStorageHelper.getItem(UCID_STORAGE_KEY);
  }

  //method to verify and update selected party data and then to call ucidchanged
  loadSelectedParty(): void {
    //if the selected party has all the details like userRoleID, ucidChanged is directly called.
    //else we are getting the details from parties list and loading the selected party data again.
    if (this.selectedParty.userRoleID) {
      this.ucidChanged();
    } else {
      let partyData: any = this.selectedParty;
      if (this.userType === USER_TYPES.DEALER) {
        partyData = [...this.ucidListDropDown, ...this.implicitPartiesDropdown].filter(({ data }) => this.selectedParty.ucid === data.id)[0]
          ?.data;
        if (!partyData) {
          this.selectedParty.ucid = this.preferences.ucid;
          partyData = [...this.ucidListDropDown, ...this.implicitPartiesDropdown].filter(({ data }) => this.preferences.ucid === data.id)[0]
            .data;
        }
      } else {
        partyData = this.ucidListDropDown.filter(({ data }) => this.selectedParty.ucid === data.id)[0]?.data;
        if (!partyData) {
          this.selectedParty.ucid = this.preferences.ucid;
          partyData = this.ucidListDropDown.filter(({ data }) => this.preferences.ucid === data.id)[0].data;
        }
      }
      this.selectedParty = {
        ...this.selectedParty,
        partyName: partyData.partyName,
        userRoleID: partyData.roleId,
        userRoleName: partyData.roleName
      };
      this.loadPartyDispatchAction();
    }
  }

  loadPartyDispatchAction() {
    this.appStore.dispatch(new LoadSelectedParty(this.selectedParty));
    if (this.userType === USER_TYPES.DEALER) {
      const primaryPartyData = this.appUtilService.getPrimaryPartyData(this.parties, this.selectedParty.ucid);
      if (primaryPartyData) {
        this.appStore.dispatch(new LoadPrimaryPartyInfo({ ...primaryPartyData, userType: this.userType }));
      }
    }
  }

  //method to check party stored in local storage and helios are present in the parties list or not. Make cust summary search api calls for parties not found.
  checkSessionAndHeliosParty(): void {
    const sessionUcid = this.getPartyFromSession();
    const searchSessionParty = () =>
      sessionUcid && sessionUcid !== this.preferences.ucid && !this.parties.find(data => data.partyNumber === sessionUcid);
    const searchHeliosParty = () => this.preferences.ucid && !this.parties.find(item => this.preferences.ucid === item.partyNumber);
    if (searchSessionParty() && searchHeliosParty()) {
      //both session & helios parties not found in the parties list
      this.appStore.dispatch(
        new GetSessionAndHeliosParty({
          parties: this.parties,
          sessionParty: sessionUcid,
          heliosParty: this.preferences.ucid
        })
      );
      this.findingParty = true;
    } else if (searchSessionParty() || searchHeliosParty()) {
      this.findingParty = true;
      const searchValue = searchSessionParty() ? sessionUcid : this.preferences.ucid;
      this.getPartiesDetails(1, searchValue);
    }
  }

  //method to get party number stored in local storage. before helios migration, value is stored in the form of object.
  //now its being stored as string. Reading ucid value accordingly.
  getPartyFromSession(): string {
    const sessionUcid = localStorageHelper.getItem(UCID_STORAGE_KEY);
    return typeof sessionUcid === 'string' ? sessionUcid : sessionUcid.ucid;
  }

  //method to dispatch GetPartiesDetails Action for ucid dropdown scroll, search & try again scenarios
  getPartiesDetails(limit: number, searchValue: string, reset = false, isScroll = false): void {
    if (APP_ENV === HOSTNAME_TO_ENV_CONFIG.stage || APP_ENV === HOSTNAME_TO_ENV_CONFIG.prod) {
      this.dispatchGetPartiesDetails(limit, searchValue, reset, isScroll);
    } else {
      const partiesDetails = this.appUtilService.mapPartyDetails(
        MAP_UCID[APP_ENV].parties,
        this.parties[0]?.roleId,
        this.parties[0]?.roleName
      );
      this.dispatchPartiesLoaded(partiesDetails);
    }
  }

  dispatchGetPartiesDetails(limit: number, searchValue: string, reset = false, isScroll = false): void {
    let cursor = null;
    if (isScroll) {
      cursor = searchValue ? this.partiesSearchNextCursor : this.partiesNextCursor;
    }
    //GetPartiesDetails Action is not dipatched when isScroll is true but there is no cursor value
    if (!(isScroll && !cursor)) {
      this.isDataLoaderActive = true;
      this.appStore.dispatch(
        new GetPartiesDetails({
          cursor,
          limit,
          roleId: this.parties[0]?.roleId,
          roleName: this.parties[0]?.roleName,
          searchValue,
          reset
        })
      );
    }
  }

  dispatchPartiesLoaded(parties: Party[], pickedBySearch: boolean = false): void {
    let data: PartiesDetails = { parties: parties, nextCursor: null, limit: 1 };
    data = pickedBySearch ? { ...data, pickedBySearch: true } : { ...data, skipModalClose: true };
    this.appStore.dispatch(new PartiesLoaded(data));
  }

  //method to set default option in ucid dropdown
  setDefaultParty(selectedPartyUcid): void {
    if (selectedPartyUcid) {
      this.defaultUcidDropDown = [];
      const selectedOption =
        this.userType === USER_TYPES.DEALER
          ? [...this.ucidListDropDown, ...this.implicitPartiesDropdown, ...this.implicitSearchParties].find(
              item => selectedPartyUcid === item.data?.ucid
            )
          : [...this.ucidListDropDown, ...this.searchResponse].find(item => selectedPartyUcid === item.data?.ucid);
      if (selectedOption) {
        this.defaultUcidDropDown = JSON.parse(JSON.stringify([selectedOption]));
      }
    }
  }

  //method to prepare ucid dropdown data from the parties list received from api response
  prepareUCIDList(parties: Party[]): PartyDropdown[] {
    const ucidListDropDown = [];
    const partyNumbers = [];
    const isPartyValid = (item: Party): boolean => !partyNumbers.includes(item.partyNumber) && item.partyType !== USER_TYPES.DEALER;
    parties.forEach(item => {
      let partyName = item.partyName ? `${item.partyName} (${item.partyNumber})` : `(${item.partyNumber})`;
      partyName = PARTY.ROOT.partyName === item.partyName ? item.partyName : partyName;
      const dropDowndata = {
        ...item,
        name: partyName,
        id: item.partyNumber,
        ucid: item.partyNumber,
        isPrimary: item.isPrimary,
        isDisabled: false,
        isSelected: false
      };
      if (isPartyValid(item)) {
        partyNumbers.push(item.partyNumber);
        ucidListDropDown.push({
          label: partyName,
          value: item.partyNumber,
          data: dropDowndata
        });
      }
    });
    if (this.appUtilService.isSuperAdminOrSuperUser(parties)) {
      this.isCATParty = true;
    }
    return ucidListDropDown;
  }

  /** Checks if preference options are proper */
  compareAppAndHeliosPreference(): boolean {
    return (
      this.sharedLibService.getVisibilityFromPreferenceMatrix(this.preferences.language, this.heliosPreference.language, true).visible ||
      this.sharedLibService.getVisibilityFromPreferenceMatrix(this.preferences.dateFormat, this.heliosPreference.dateFormat, true)
        .visible ||
      this.sharedLibService.getVisibilityFromPreferenceMatrix(this.preferences.unit, this.heliosPreference.systemOfMeasurement, true)
        .visible ||
      this.sharedLibService.getVisibilityFromPreferenceMatrix(this.preferences.timeFormat, this.heliosPreference.timeFormat, true)
        .visible ||
      this.sharedLibService.getVisibilityFromPreferenceMatrix(this.preferences.decimalSeparator, this.heliosPreference.numberFormat, true)
        .visible
    );
  }

  //method to handle preference change. new preferences are saved in store. and if ucid is changed, dashboard will be loaded with new ucid selected.
  preferenceChange(appPreference: Preference, globalPreference: HeliosUserPreference): void {
    const previousUcid = this.selectedParty.ucid;
    this.appStore.dispatch(new HeliosPreferencesLoaded({ appPreference, globalPreference }));
    this.preferences = appPreference;
    this.isPrefAvailable.next(this.preferences);
    this.heliosPreference = globalPreference;
    if (previousUcid !== appPreference.ucid) {
      this.ucidChangedInPreference(appPreference.ucid);
    } else if (!this.hasAccess) {
      //this block will be executed only once on initial load in the following scenario: Both session & helios parties not present in first 100,
      //and then session party found in initial search but helios party not found
      //in that case, pref. popup will open & session party will be the first option. on selection of the same, this block is used to load that party in store.
      this.updateSelectedParty(this.selectedParty.ucid);
      this.loadPartyDispatchAction();
    }
  }

  //method to update selected party data when user searches and selects ucid
  updateSelectedParty(partyNumber: string): void {
    const setSelectedParty = data => {
      const { partyName, roleId: userRoleID, roleName: userRoleName } = data;
      this.selectedParty = {
        ...this.selectedParty,
        ucid: partyNumber,
        partyName,
        userRoleID,
        userRoleName
      };
    };
    let selectedPartyData;
    let selectedPartyFromSearch;
    if (this.userType === USER_TYPES.DEALER) {
      selectedPartyData = [...this.ucidListDropDown, ...this.implicitPartiesDropdown].find(party => party.data.ucid === partyNumber);
      selectedPartyFromSearch = this.implicitSearchParties.find(response => response.data.partyNumber === partyNumber);
    } else {
      selectedPartyData = this.ucidListDropDown.find(party => party.data.ucid === partyNumber);
      selectedPartyFromSearch = this.searchResponse?.find(response => response.data.partyNumber === partyNumber);
    }
    if (selectedPartyData) {
      setSelectedParty(selectedPartyData.data);
    } else if (!selectedPartyData && selectedPartyFromSearch) {
      setSelectedParty(selectedPartyFromSearch.data);
      if (this.userType === USER_TYPES.DEALER) {
        this.appStore.dispatch(
          new ImplicitPartiesLoaded({
            pickedBySearch: true,
            parties: [{ ...selectedPartyFromSearch.data }, ...this.storedImplicitParties],
            nextCursor: this.ImplicitPartiesNextCursor
          })
        );
      } else {
        this.dispatchPartiesLoaded([{ ...selectedPartyFromSearch.data }], true);
      }
    }
  }

  /** Method to show toast message box */
  displayToastMessage(message: string, type: string): void {
    let msg = this.translocoService.translate(message);
    if (msg === TOASTER_CONFIG.ERROR_KEY) {
      msg = TOASTER_CONFIG.FALLBACK;
    }
    this.messageBar.open(msg, type, [], {
      hostType: TOASTER_CONFIG.HOST_TYPE.OVERLAY,
      duration: TOASTER_CONFIG.TOASTER_LIFE_TIME,
      horizontalPosition: TOASTER_CONFIG.H_POSITION,
      verticalPosition: TOASTER_CONFIG.V_POSITION
    });
  }

  /** Redirect to dashboard if user has changed party but not in dashboard page */
  ucidChangedInPreference(partyNumber: string): void {
    this.updateSelectedParty(partyNumber);
    if (this.router.url !== DEFAULT_ROUTE_PATH) {
      this.ucidChangedInOtherPage = true;
      this.router.navigate(['dashboard'], { state: { allAssetsRedirection: true } });
    } else {
      this.loadPartyDispatchAction();
    }
  }

  //Store the new helios preference to be available until the user refreshes the page
  updateHeliosPreference(updatedPreference: Preference): HeliosUserPreference {
    return {
      language: CP_USER_PREFERENCE_HELIOS_VALUE[updatedPreference.language],
      dateFormat: CP_USER_PREFERENCE_HELIOS_VALUE[updatedPreference.dateFormat],
      timeFormat: CP_USER_PREFERENCE_HELIOS_VALUE[updatedPreference.timeFormat],
      systemOfMeasurement: CP_USER_PREFERENCE_HELIOS_VALUE[updatedPreference.unit],
      numberFormat: CP_USER_PREFERENCE_HELIOS_VALUE[updatedPreference.decimalSeparator],
      rawData: this.heliosPreference.rawData
    };
  }

  onConfirmYes(): void {
    this.hasAccess = false;
    this.showLegal = false;
    this.appStore.dispatch(new ResetAppStateAction());
    this.isPreferenceAvailable = this.isConsentAccepted = true;
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGOUT, 'true');
    this.modal.modalRef && this.modal.close();
    this.showUserPreference = false;
    this.clearUserSession();
    sessionStorage.clear();
    this.setApplicationLanguage();
    this.redirectToLogOut();
  }

  onConfirmNo(): void {
    this.modal.close();
  }

  redirectToLogOut(): void {
    setTimeout(() => {
      this.resetUserSession();
      this.pkceAuthService.logoutRedirect();
      this.isMsalLogoutTriggered = true;
    });
  }

  resetUserSession(): void {
    if (this.preferenceModal) {
      this.showUserPreference = false;
      this.togglePreferenceDialog();
    }
    this.cpUtilService.resetLocalStorage();
  }

  clearUserSession(): void {
    utilsHelper.deleteAllCookies(OPTANON_COOKIES);
  }

  //method to handle user actions on different options of top right menu
  userPerfActions(action: string): void {
    if (this.mobileDevice) {
      this.isSideNavOpen = false;
    }
    if (action === MENU_ACTIONS.PREFERENCES) {
      this.onResetSearchDropDown();
      this.setDefaultParty(this.preferences.ucid);
      this.resetSaveUserPreference();
      this.showUserPreference = true;
      this.allowPreferenceClose = true;
      this.togglePreferenceDialog();
    } else if (action === MENU_ACTIONS.LOGOUT) {
      this.modal.openModal(this.logoutTemplate, { ...SEMI_MODAL, width: LOGOUT_MODAL_WIDTH, panelClass: ['cc-sign-out'] });
    } else if (action === MENU_ACTIONS.HELP) {
      this.doHelp();
    } else if (action === MENU_ACTIONS.UPGRADE) {
      this.showUpgradeModal = true;
    } else if (action === MENU_ACTIONS.ENABLE_COOKIES) {
      this.onClickCookies();
    }
  }

  resetSaveUserPreference(): void {
    if (this.resetUpdatePreference) {
      this.preferenceStore.dispatch(new ResetFlags());
      this.resetUpdatePreference = false;
    }
  }

  //method to check and open or close the preference popup
  togglePreferenceDialog(): void {
    const openPopup = () =>
      !this.preferenceModal &&
      this.showUserPreference &&
      this.isPreferenceAvailable &&
      this.isConsentAccepted &&
      this.isTranslationsAvailable;
    const closePopup = () => !this.showUserPreference && this.preferenceModal && !this.skipModalClose;
    if (openPopup()) {
      this.preferenceModal = this.modal.openModal(this.userPreferences, { ...SEMI_MODAL, width: PREFERENCE_MODAL_WIDTH });
    } else if (closePopup()) {
      this.preferenceModal.close();
      this.preferenceModal = null;
      this.skipModalClose = false;
    }
  }

  //method to redirect the user to help page in new tab and in preferred language
  doHelp(): void {
    this.googleUtilService.pushDataToGoogleDataLayer.next(GOOGLE_TAG_ACTIONS.helpCenter);
    const selectedLanguage = prefferedLanguages[this.preferences?.language] || defaultLanguage;
    this.window.open(`${this.helpUrl + selectedLanguage}/index.htm`, '_blank');
  }

  /**
   * On click footer 'Cookies' link
   */
  onClickCookies(): void {
    this.oneTrustData?.ToggleInfoDisplay();
  }

  // respective function calls to get ucid data from API are made based on the event from user-nav
  ucidAccountEvents(event: any): void {
    switch (event.event) {
      case ucidEvents.CHANGE_ACCOUNT:
        this.showChangeAccount(event);
        break;
      case ucidEvents.UCID_SELECTION:
        this.onAccountSelection(event.data);
        break;
      case ucidEvents.LOAD_MORE_UCID:
        this.emitSearchinfiniteScrollEvent(event.data.searchBy);
        break;
      case ucidEvents.RESET_UCID_SEARCH:
        this.onResetSearchDropDown();
        break;
      case ucidEvents.INIT_UCID_SEARCH:
        this.emitSearchAction(event.data.searchBy);
        break;
      case ucidEvents.TRY_AGAIN:
        this.onTryAgainParty(event.data.searchBy);
        break;
      default:
        break;
    }
  }

  showChangeAccount(event: any): void {
    if (event.showPopover) {
      this.showChangeAccountPopover = !this.showChangeAccountPopover;
      if (!this.showChangeAccountPopover) {
        this.onResetSearchDropDown();
      }
      this.loadImplicitPartiesAndCount(PARTY_SEARCH_DEFAULT_LIMIT, null, null, true);
    } else {
      this.showChangeAccountDialog(null);
    }
  }

  loadImplicitPartiesAndCount(limit: number, searchValue: string, cursor: string, isInitial = false): void {
    if (this.userType === USER_TYPES.DEALER && (!isInitial || (isInitial && this.implicitParties.length <= 2))) {
      this.isImplicitDataLoaderActive = true;
      this.appStore.dispatch(new LoadImplicitParties({ limit, dealerCode: this.userInfo.catafltnorgcode, cursor, searchValue }));
      this.appStore.dispatch(new LoadImplicitPartiesCount({ dealerCode: this.userInfo.catafltnorgcode, searchValue, isInitial }));
    }
  }

  /*
  To handle the emitted user account from the dropdown component
*/
  onAccountSelection(ucidData): void {
    const selectedPartyData = ucidData.ucid !== this.defaultUcidDropDown?.[0]?.data?.id ? ucidData : null;
    if (this.changeAccountModal) {
      this.pickedByImplicitSearch =
        this.userType === USER_TYPES.DEALER && !!this.implicitParties.find(response => response.partyNumber === selectedPartyData?.ucid);
      this.showChangeAccountDialog({ showDialog: false, ucid: selectedPartyData?.ucid });
    } else {
      this.showChangeAccountPopover = false;
      if (selectedPartyData) {
        this.isPartyDataLoadError = false;
        if (this.selectedParty.ucid !== selectedPartyData.ucid) {
          this.onResetSearchDropDown();
          this.ucidChangedInPreference(selectedPartyData.ucid);
        }
      }
    }
  }

  //method to handle ucid dropdown scroll event with and without search string
  emitSearchinfiniteScrollEvent(searchStr: string): void {
    if (this.appUtilService.isSuperAdminOrSuperUser(this.parties) && !this.isPartyDataLoadError) {
      this.getPartiesDetails(null, searchStr, false, true);
    } else if (this.userType === USER_TYPES.DEALER) {
      this.isImplicitDataLoaderActive = true;
      const cursor = searchStr ? this.ImplicitPartiesSearchNextCursor : this.ImplicitPartiesNextCursor;
      this.appStore.dispatch(
        new LoadImplicitParties({ limit: 100, dealerCode: this.userInfo.catafltnorgcode, cursor, searchValue: searchStr })
      );
    }
  }

  //method to dispatch ClearSearchParties Action which resets ucid dropdown data with previous parties list
  onResetSearchDropDown(): void {
    this.isDataLoaderActive = false;
    this.isPartyDataLoadError = false;
    this.appStore.dispatch(new ClearSearchParties());
    if (this.userType === USER_TYPES.DEALER) {
      this.isImplicitDataLoaderActive = false;
      this.isImplicitDataLoadError = false;
      this.implicitPartiesCount = this.TotalImplicitPartiesCount;
      this.ImplicitPartiesSearchNextCursor = null;
      this.appStore.dispatch(
        new ImplicitPartiesLoaded({
          pickedBySearch: true,
          parties: [...this.storedImplicitParties],
          nextCursor: this.ImplicitPartiesNextCursor
        })
      );
    }
  }

  //method to handle ucid dropdown search. for super admin/user GetPartiesDetails Action will be dispatched.
  //for user with roles admin, standard and viewer, client side search is done.
  emitSearchAction(searchStr: string): void {
    this.ucidListDropDown = [];
    if (this.appUtilService.isSuperAdminOrSuperUser(this.parties)) {
      this.getPartiesDetails(null, searchStr, true);
    } else {
      this.appStore.dispatch(
        new SearchPartiesLoaded({
          parties: this.appUtilService.filterParties(this.storedUcidListDropDown, searchStr),
          nextCursor: null,
          reset: true,
          skipModalClose: true
        })
      );
    }
    if (this.userType === USER_TYPES.DEALER) {
      this.implicitPartiesDropdown = [];
      this.implicitPartiesCount = 0;
      this.ImplicitPartiesSearchNextCursor = null;
      this.loadImplicitPartiesAndCount(100, searchStr, this.ImplicitPartiesSearchNextCursor);
    }
  }

  //method to handle try again of ucid dropdown search & scroll api failure
  onTryAgainParty(searchStr: string): void {
    if (this.appUtilService.isSuperAdminOrSuperUser(this.parties)) {
      this.isPartyDataLoadError = false;
      const isScroll = searchStr ? !!this.partiesSearchNextCursor : !!this.partiesNextCursor;
      this.getPartiesDetails(null, searchStr, false, isScroll);
    } else if (this.userType === USER_TYPES.DEALER) {
      this.isImplicitDataLoadError = false;
      const cursor = searchStr ? this.ImplicitPartiesSearchNextCursor : this.ImplicitPartiesNextCursor;
      this.loadImplicitPartiesAndCount(100, searchStr, cursor);
    }
  }

  /**
   * To handle preference close event emitter from UserPreference component
   */
  closeUserPreference(modal): void {
    this.isUcidValid = true;
    this.showUserPreference = false;
    this.preferenceModal = null;
    this.skipModalClose = false;
    this.onResetSearchDropDown();
    modal && modal.close();
  }

  /**
   * WhatFix to be loaded only after marketing page loaded
   * And functional cookie is enabled
   */
  checkAndAddWhatFix(): void {
    if (!this.isWhatFixLoaded && this.preferences && this.isFunctionalCookieEnabled) {
      this.gtmService.addWhatFixToDom();
      this.isWhatFixLoaded = true;
    } else if (this.isWhatFixLoaded && this.preferences && !this.isFunctionalCookieEnabled) {
      this.gtmService.removeWhatFix();
      this.isWhatFixLoaded = false;
    }
  }

  /**
   * method to update the oneTrust toggle info dialog language
   */
  updateOneTrustLanguage(preferences: Preference): void {
    if (preferences && preferences.language) {
      this.oneTrustData?.updateLanguage(preferences.language);
    }
  }

  /**
   * method to Check if user stopped performance cookies
   */
  checkAndEnableInsights(isPerformanceDisabled: boolean): void {
    if (!this.preferences) {
      return;
    } //marketing page check
    if (isPerformanceDisabled) {
      this.monitorservice.stopPerformanceCookies();
      this.isPageViewsListened = false;
    } else if (!this.isPageViewsListened) {
      this.monitorservice.listenPageViews(true);
      this.googleUtilService.updateInstrumentationKey();
      this.isPageViewsListened = true;
    }
  }

  /**
   * On click footer 'Legal' link
   */
  onClickLegal(): void {
    if (this.mobileDevice) {
      this.isSideNavOpen = false;
    }
    this.loadLegalFooterComponent();
  }

  async loadLegalFooterComponent(): Promise<void> {
    if (!this.isFooterLegalLoaded) {
      const { LegalPopupFooterComponent } = await import('@Terra/shared/legal-popup');
      this.legalPopupFooterRef?.createComponent(LegalPopupFooterComponent);
      this.isFooterLegalLoaded = true;
    }
    this.appUtilService.legalUtil.showLegalFooter$.next(this.preferences.language);
  }

  /**
   * Adding GA expiring time
   */
  manageCookie(cookieName, cookieValue, daysToExpire): void {
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    this.document.cookie = `${cookieName}=${cookieValue}; path=/;domain=.cat.com; expires=${date.toISOString()}`;
  }

  /**
   *  Function to hide/show Root page message based on url
   */
  showRootPage(): void {
    const currentPath = this.router?.url === '/' ? PAGE_PATH.DASHBOARD : this.router.url;
    const currentPage = ROOT_RESTRICTED_PAGES.filter(page => currentPath.search(page.path) !== -1);
    const isUserManagement = currentPath.includes(PAGE_PATH.USER_MANAGEMENT);
    this.isRootAccountPage = !isUserManagement;
    if (!isUserManagement) {
      this.rootPageTitle = currentPage[0]?.title || null;
    }
  }

  upgradeUserRoleModalClose(): void {
    this.showUpgradeModal = false;
  }

  isSideNavCollapsed(data: boolean): void {
    this.isSideNavOpen = data;
  }

  onNavLinkClicked(node: CcSideNavEventSource): void {
    if (node.currentNode.pathName) {
      this.navigateFlag = this.navigateFlag ? (this.router.navigate([node.currentNode.pathName]), true) : true;
      this.mobilePageTitle = node.currentNode.name;
    }
  }

  onActivate(outlet: RouterOutlet): void {
    const sub = outlet.activatedRoute.data.subscribe(data => {
      this.hideSideNav = data.hideMenu;
    });
    this.outletSubscription.add(sub);
  }

  onDeactivate(): void {
    this.outletSubscription.unsubscribe();
  }

  /**
   * Function to subscribe notifications data from API
   */
  getAppNotifications(): void {
    if (sessionStorage.getItem(NOTIFICATION_STORAGE_CONSTANTS.key) !== NOTIFICATION_STORAGE_CONSTANTS.value) {
      this.appService
        .getNotifications()
        .pipe(filter(notifications => !!notifications?.notification.length))
        .subscribe({
          next: (notifications: NotificationResponse) => {
            this.notification = this.appUtilService.filterActiveNotifications(notifications.notification);
            this.notificationData.unshift(
              ...this.appUtilService.prepareNotificationAlertData(this.preferences, this.notification, this.notificationData)
            );
            this.notificationData = JSON.parse(JSON.stringify(this.notificationData));
          },
          error: error => {
            this.removeCustomOrDowntimeNotification();
            this.notification = null;
          }
        });
    }
  }

  /**
   * Method to filter only current active notifications.
   * @param notifications list of API notifications values.
   * @returns active notification or null.
   */
  filterActiveNotifications(notifications: Notification[]): Notification {
    const now = momentHelper.getDateTimeByTZ(undefined, undefined);
    const cutomNotification = notifications.find(nfn => nfn.notificationType === NOTIFICATION_TYPE.Custom);
    if (cutomNotification) {
      const start = momentHelper.getDateTimeByTZ(cutomNotification.notificationStartTime, DATE_TIME_CONSTANTS.DEFAULT_FORMAT);
      const end = momentHelper.getDateTimeByTZ(cutomNotification.notificationEndTime, DATE_TIME_CONSTANTS.DEFAULT_FORMAT);
      if (
        dateTimeFormatterHelper.isBetweenDateTime({
          dateTime: now,
          fromDateTime: start,
          toDateTime: end,
          period: DATE_TIME_CONSTANTS.MINUTES as unitOfTime.StartOf
        })
      ) {
        return cutomNotification;
      }
    }
    const downtimeNotification = notifications.find(nfn => nfn.notificationType === NOTIFICATION_TYPE.Downtime);
    if (downtimeNotification) {
      const start = momentHelper.getDateTimeByTZ(downtimeNotification.notificationStartTime, DATE_TIME_CONSTANTS.DEFAULT_FORMAT);
      const end = momentHelper.getDateTimeByTZ(downtimeNotification.notificationEndTime, DATE_TIME_CONSTANTS.DEFAULT_FORMAT);
      const virtualStart = momentTimeFormatterHelper.getTimeWithSubtract({
        dateTime: start,
        currentFormat: undefined,
        duration: 3,
        period: DATE_TIME_CONSTANTS.DAYS as DurationInputArg2
      });
      if (
        dateTimeFormatterHelper.isBetweenDateTime({
          dateTime: now,
          fromDateTime: virtualStart,
          toDateTime: end,
          period: DATE_TIME_CONSTANTS.MINUTES as unitOfTime.StartOf
        })
      ) {
        return downtimeNotification;
      }
    }
    return null;
  }

  /**
   * Method to hide a particular notification based on id.
   * @param id notification id
   */
  onNotificationClose(event: AppNotification): void {
    this.isCustomOrDowntimeNotification(event.id);
    this.notificationData = [...this.notificationData.filter(notification => notification.id !== event.id)];
    event.type === NotificationType.error && this.fileExportPollingService.removeNotification$.next(event?.payload);
  }

  isCustomOrDowntimeNotification(id: number) {
    const isCustomDownTime = this.notificationData.find(
      notification => notification.id === id && this.appUtilService.customDownTime().includes(notification.title)
    );
    if (isCustomDownTime) {
      sessionStorage.setItem(NOTIFICATION_STORAGE_CONSTANTS.key, NOTIFICATION_STORAGE_CONSTANTS.value);
      this.notification = null;
    }
  }

  removeCustomOrDowntimeNotification(): void {
    const index = this.appUtilService.getCustomOrDowntimeNotification(this.notificationData);
    index !== -1 && this.notificationData.splice(index, 1);
  }

  onTryAgain($event: any): void {
    this.onNotificationClose($event);
    this.fileExportPollingService.tryAgain$.next($event);
  }

  /**
   *method to update the hideMobileUserInfo flag based on menu selection in mobile screen
   */
  onNavLinkExpanded(event): void {
    this.hideMobileUserInfo = event.currentNodeOpen;
  }

  /**
   *method to update the hideMobileUserInfo flag when hamburger icon is clicked
   */
  onBubbleSideNavToggle(event): void {
    if (event) {
      this.hideMobileUserInfo = false;
    }
  }

  isMobileDevice(): void {
    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe(result => (this.mobileDevice = result.matches));
  }

  formNotifications(): void {
    this.notificationData = this.appUtilService.formNotifications(
      this.preferences,
      this.notification,
      this.notificationData,
      this.failedNotifications
    );
  }

  showChangeAccountDialog(data: any): void {
    this.setDefaultParty(data?.ucid);
    if (data?.showDialog) {
      this.loadImplicitPartiesAndCount(PARTY_SEARCH_DEFAULT_LIMIT, null, null, true);
      this.changeAccountModal = this.modal.openModal(this.changeAccountPreferences, { ...SEMI_MODAL, width: CHANGE_ACCOUNT_MODAL_WIDTH });
    } else {
      this.changeAccountModal.close();
      this.changeAccountModal = null;
      this.onResetSearchDropDown();
    }
  }

  onChangeAccountPopoverClose(): void {
    this.showChangeAccountPopover = false;
    this.onResetSearchDropDown();
  }

  getBrowserInfo(): BrowserInfo {
    const browserDetails = this.deviceService.getDeviceInfo();
    return utilsHelper.formBrowserDetailsObject(browserDetails);
  }

  async showLegalPopup(): Promise<void> {
    const { LegalPopupComponent } = await import('@Terra/shared/legal-popup');
    this.legalPopupRef = this.modal.openModal(LegalPopupComponent, { ...SEMI_MODAL, width: LEGAL_POPUP_WIDTH });
    this.isConsentAccepted = false;
  }

  demoModeToggle(isChecked: boolean): void {
    this.window.localStorage.setItem(DEMO_MODE_KEY, `${isChecked}`);
    setReloadStatusForDemoMode('true');
    this.window.location.reload();
  }

  ngOnDestroy() {
    this.translocoSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.outletSubscription.unsubscribe();
  }
}
