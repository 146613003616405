<footer class="page-footer font-small blue app-footer" [ngClass]="{ 'dialog-opened': showDialog }">
  <div [ngClass]="{ 'container-fluid text-center text-md-left': !isSideMenuFooter }">
    <div [ngClass]="isSideMenuFooter ? 'side-menu-footer' : 'row'">
      <div [ngClass]="{ 'col-12 col-sm-6': !isSideMenuFooter }">
        @if (showContact) {
          <span class="contact-Us">
            <span class="contact-cls" (click)="contactClicked()">{{ 'app.footer.contactUsLabel' | transloco }}</span>
          </span>
        }

        @if (showCookies) {
          @if (!isOneTrust) {
            <span class="legal" (click)="clickCookies.emit(true)"
              ><span class="legal-cls">{{ 'WIDGETS_FOOTER_COOKIES' | transloco }}</span></span
              >
            } @else {
              <!-- No Lang translation applicable for Onetrust content -->
              <span class="footer-ot-cookie-setting">
                <a class="ot-sdk-show-settings onetrust-btn" (click)="clickCookies.emit(true)" aria-role="button">Cookie Settings</a>
              </span>
            }
          }

          @if (showLegal) {
            <span data-automation-id="fo-legal" class="legal" (click)="openDialog()"
              ><span class="legal-cls">{{ 'app.footer.legalLabel' | transloco }}</span></span
              >
            }
          </div>


          @if (showDialog) {
            <div class="footer-dialog-wrapper">
              <div class="dialog-wrapper">
                <div class="footerDialog">
                  <div style="padding: 1em 1em 0 1em">
                    <label class="header-label">
                      <span data-automation-id="fo-legal-header" class="legal-header">{{ 'app.footer.legalLabel' | transloco }}</span>
                      <span data-automation-id="fo-legal-close" (click)="closeDialog()" class="close">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L11 11" stroke="#2c2f3d" stroke-linecap="round" />
                          <path d="M11 1L1 11" stroke="#2c2f3d" stroke-linecap="round" />
                        </svg>
                      </span>
                    </label>
                  </div>
                  <div class="cc-expansion-content">
                    <cc-accordion [multi]="true">
                      <cc-expandable data-automation-id="fo-legal-termssec" [expanded]="step === 0" (opened)="openedState(0)">
                        <cc-expandable-header>
                          <span class="accordion-title" data-automation-id="fo-legal-termslbl">
                            {{ 'app.footer.termsLabel' | transloco }}
                          </span>
                        </cc-expandable-header>
                        <p
                          data-automation-id="fo-legal-termscnt"
                          id="style-2"
                          [innerHTML]="footerContents.termsofuse | transloco | sanitizeHtml"
                        ></p>
                      </cc-expandable>
                      <cc-expandable data-automation-id="fo-legal-privacysec" [expanded]="step === 1" (opened)="openedState(1)">
                        <cc-expandable-header>
                          <span class="accordion-title" data-automation-id="fo-legal-privacylbl">
                            {{ 'app.footer.privacyLabel' | transloco }}
                          </span>
                        </cc-expandable-header>
                        <p data-automation-id="fo-legal-privacycnt" [innerHTML]="footerContents.privacystatement | transloco | sanitizeHtml"></p>
                      </cc-expandable>
                      <cc-expandable data-automation-id="fo-legal-cookiesec" [expanded]="step === 2" (opened)="openedState(2)">
                        <cc-expandable-header>
                          <span class="accordion-title" data-automation-id="fo-legal-cookielbl">
                            {{ 'app.footer.cookieLabel' | transloco }}
                          </span>
                        </cc-expandable-header>
                        <p data-automation-id="fo-legal-cookiecnt" [innerHTML]="footerContents.cookiestatement | transloco | sanitizeHtml"></p>
                      </cc-expandable>
                    </cc-accordion>
                  </div>
                  <div class="action-bottom">
                    <div class="float-right delete-actions">
                      <button
                        [setTheme]="true"
                        cc-btn-secondary
                        cc-btn-large
                        data-automation-id="fo-legal-ok"
                        type="button"
                        (click)="closeDialog()"
                        >
                        {{ 'app.footer.ok' | transloco }}
                      </button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div [ngClass]="{ 'col-12 col-sm-6': !isSideMenuFooter }">
            <span data-automation-id="fo-copyright" class="copyright"
              >{{ 'app.footer.CaterpillarLabel' | transloco }} &copy; {{ copyrightYear }} {{ 'app.footer.rightsLabel' | transloco }}</span
              >
            </div>
          </div>
        </div>
      </footer>
