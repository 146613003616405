import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { USER_ROLES } from '@Terra/pitmanagement/app-store';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EditGuard implements CanActivate {
  viewer: boolean;

  constructor(private readonly store: Store<any>, private readonly router: Router) {}

  async canActivate(): Promise<boolean> {
    const isViewer = await this.store
      .select((state: any) => state.app.selectedParty)
      .pipe(map(response => response?.userRoleID !== USER_ROLES.VIEWER));

    isViewer.subscribe((response: boolean) => {
      this.viewer = response;
    });
    return this.viewer;
  }
}
