import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import {
  MIN_COUNT_CHECK,
  MIN_LOADING_THRESHOLD,
  SCRIPT_LOAD_ERROR,
  WEB_CHATBOT_SCRIPT_DOMAIN,
  WEB_CHATBOT_SCRIPT_ENDPOINT
} from './webchat.constants';
import { WebChatbot } from './webchat.models';
import { WindowRef } from './windowref';

@Injectable({
  providedIn: 'root'
})
export class WebchatService {
  constructor(private readonly winref: WindowRef, @Inject(DOCUMENT) private readonly document: Document) {}
  /**
   *method to load webchatbot script
   * @returns Observable
   */
  load(): Observable<WebChatbot> {
    return new Observable((observer: Subscriber<WebChatbot>) => {
      const source = WEB_CHATBOT_SCRIPT_DOMAIN + WEB_CHATBOT_SCRIPT_ENDPOINT;
      const webChatbotScript = this.isScriptLoaded(source);
      const loadedScript = webChatbotScript ? webChatbotScript : this.loadScript(source);
      this.setScriptLoadAndError(loadedScript, observer);
    });
  }
  /**
   * method to create a script tag
   * @param src script tag src
   * @param dataDomain data-domain attribute
   * @returns
   */
  loadScript(src: string): HTMLScriptElement {
    const script: HTMLScriptElement = this.document.createElement('script');
    const head = this.document.getElementsByTagName('head')[0];
    script.src = src;
    script.type = 'text/javascript';
    script.setAttribute('crossorigin', 'anonymous');
    head.appendChild(script);
    return script;
  }

  /**
   * method to check if the script element is already loaded into the dom
   * @param scriptSource src for script
   * @returns bool
   */
  isScriptLoaded(scriptSource: string): HTMLScriptElement | null {
    return document.querySelector(`script[src="${scriptSource}"]`);
  }

  /**
   * method to check if script is loaded and emit the data
   * @param scriptElement script tag element
   * @param observer Subscriber
   */
  setScriptLoadAndError(scriptElement: HTMLScriptElement, observer: Subscriber<WebChatbot>) {
    scriptElement.onload = e => {
      e.preventDefault();
      let count = 0;
      const otTimer = setInterval(() => {
        if (count > MIN_COUNT_CHECK) {
          this.sendObserver<WebChatbot>(observer, SCRIPT_LOAD_ERROR as unknown as WebChatbot, true, otTimer);
          return;
        }
        const webChatbot = this.winref.nativeWindow.WebChat;
        if (webChatbot && otTimer) {
          this.sendObserver<WebChatbot>(observer, webChatbot, false, otTimer);
        }
        count += 1;
      }, MIN_LOADING_THRESHOLD);
    };
  }

  /**
   * method to send the observer and complete
   * @param observer Subscriber
   * @param value string or WebChatbot
   * @param isError boolean
   */
  sendObserver<T>(observer: Subscriber<T>, value: T, isError: boolean, id: NodeJS.Timeout) {
    clearInterval(id);
    isError ? observer.error(value) : observer.next(value);
    observer.complete();
  }
}
