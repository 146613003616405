/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { decryptHandler, encryptHandler } from './demo-mode-utils/demo-mode.helper';

@Injectable()
export class DemoInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const decryptedReq = decryptHandler(req);
    return next.handle(decryptedReq).pipe(
      tap((res: any) => {
        if (res instanceof HttpResponse) {
          const newReq = req.clone();
          encryptHandler(newReq, res);
        }
      })
    );
  }
}
