import { DefaultDataServiceConfig, EntityMetadataMap } from '@ngrx/data';
const pluralNames = { SiteMaster: 'SitesMaster' };

/* istanbul ignore next */
export const entityMetadata: EntityMetadataMap = {
  PILoaderColumn: {
    selectId: selectIdForColumn
  },
  ProductivityInsight: {
    selectId: selectIdForData
  },
  SiteMaster: {},
  SiteAsset: {
    selectId: selectIdForSiteAsset
  },
  ProductiveDate: {},
  subscribedAsset: {
    selectId: selectIdForSubScribedAsset
  }
};
/* istanbul ignore next */
export function selectIdForColumn(column: any) {
  return column.property;
}
/* istanbul ignore next */
export function selectIdForData(data: any) {
  return data.cycleId;
}
/* istanbul ignore next */
export function selectIdForSiteAsset(record: any) {
  return record.serialNumber;
}
/* istanbul ignore next */
export function selectIdForSubScribedAsset(record: any) {
  return record.serialNumber;
}
/* istanbul ignore next */
export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: ''
};

/* istanbul ignore next */
export const entityConfig = {
  entityMetadata,
  pluralNames
};
