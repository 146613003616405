/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpParams, HttpRequest } from '@angular/common/http';
import { CONFIG_DECRYPT_KEY_SUFFIX, CONFIG_DECRYPT_PARAMS, DECRYPT_CONFIG_FOR_OBJ } from '../demo-mode-decryption.config';
import { CONFIG_ENCRYPT_PARAMS } from '../demo-mode-encryption-api.config';
import { CONFIG_ENCRYPT_KEY_SUFFIX } from '../demo-mode-encryption.config';
import { DemoMode, EnCryptDeCryptConfig, ObjectManipulatorPayload, PopertyParamPayload } from '../demo-mode.model';
import * as enDe from './encrypt-decrypt.helper';

export const checkIfSuffixKeyAvailable = (req: HttpRequest<any>, url: string, isEncryption: boolean): string => {
  let reqUrlConfigKey = '';
  const configSuffix = isEncryption ? CONFIG_ENCRYPT_KEY_SUFFIX : CONFIG_DECRYPT_KEY_SUFFIX;
  configSuffix?.[url]?.forEach((reqUrl: EnCryptDeCryptConfig) => {
    if (req?.body?.handlerURL?.includes(reqUrl.urlSubString) || req?.urlWithParams?.includes(reqUrl.urlSubString)) {
      reqUrlConfigKey = reqUrl.configKey;
    }
  });
  return reqUrlConfigKey;
};

export const isEncryptionDecryptionReq = (req: HttpRequest<any>, isEncryption: boolean, url: string): boolean => {
  const configSuffix = isEncryption ? CONFIG_ENCRYPT_KEY_SUFFIX : CONFIG_DECRYPT_KEY_SUFFIX;
  if (!req?.url || !Object.keys(configSuffix).includes(url)) {
    return false;
  }
  return true;
};

/**Create key which is made by combining -
 * req.url and unique part/value from req.body.handlerUrl or req.urlWithParams.
 * req.url act as PREFIX of the config key where as unique part/value from req.body.handlerUrl or req.urlWithParams
 * form SUFFIX of the config key*/
export const getDemoModeEncryptDecryptConfigKey = (req: HttpRequest<any>, isEncryption: boolean): string => {
  const url = getRequestUrlConfigKey(req);
  if (!isEncryptionDecryptionReq(req, isEncryption, url)) {
    return '';
  }
  const reqUrlConfigKey = checkIfSuffixKeyAvailable(req, url, isEncryption);
  const configParams = isEncryption ? CONFIG_ENCRYPT_PARAMS : CONFIG_DECRYPT_PARAMS;
  return Object.keys(configParams).includes(reqUrlConfigKey) ? reqUrlConfigKey : '';
};

export const getRequestUrlConfigKey = (req: HttpRequest<any>): string => {
  // splitting url with ? first and getting first part to get endpoint, then split again with / to get url array
  const urlArray = req?.url?.split('?')[0].split('/');
  let url = urlArray.pop();
  if (!url) {
    url = urlArray.pop();
  }
  return url;
};

export const encryptHandler = (req: HttpRequest<any>, event: any): void => {
  const configKey = getDemoModeEncryptDecryptConfigKey(req, true);
  if (configKey) {
    const demoMode = JSON.parse(JSON.stringify(CONFIG_ENCRYPT_PARAMS[configKey]));
    demoMode.forEach((item: any) => {
      manipulator(item, { response: event.body, req });
    });
  }
};

export const decryptHandler = (req: HttpRequest<any>): HttpRequest<any> => {
  const configKey = getDemoModeEncryptDecryptConfigKey(req, false);
  if (configKey) {
    const demoDecrypt = CONFIG_DECRYPT_PARAMS[configKey];
    const { body } = req;
    demoDecrypt.forEach((item: any) => {
      req = decryptManipulator(item, body, req);
    });
  }
  return req;
};

//recursive function to manipulate the response based on demoMode commands
export const manipulator = (
  demoMode: DemoMode,
  manipulatorPayload: { response: any; req: HttpRequest<any> },
  activity: string = 'encrypt'
): void => {
  if (demoMode.length === 0) {
    return;
  }

  const action = demoMode[0] ? demoMode[0] : undefined;

  manipulatorHandler({ action, response: manipulatorPayload.response, demoMode, activity }, manipulatorPayload.req);
};

export function manipulatorHandler(payload: ObjectManipulatorPayload, req: HttpRequest<any>): void {
  if (typeof payload.action === 'string') {
    stringManipulator(payload.action, payload.response, { demoMode: payload.demoMode, req });
  } else if (typeof payload.action === 'number') {
    manipulator(payload.demoMode.slice(1), { response: payload.response[payload.action], req });
  } else if (typeof payload.action === 'object') {
    objectManipulator(payload, req);
  }
}

export function stringManipulator(action: string, response: any, payload: { demoMode: DemoMode; req: HttpRequest<any> }): void {
  response = response?.[action];
  manipulator(payload.demoMode.slice(1), { response, req: payload.req });
}

export function numberManipulator(action: string, response: any, payload: { demoMode: DemoMode; req: HttpRequest<any> }): void {
  //action is number, then access only that specific element in the demoMode
  manipulator(payload.demoMode.slice(1), { response: response[action], req: payload.req });
}

export function objectManipulator(payload: ObjectManipulatorPayload, req: HttpRequest<any>): void {
  // action is '[]' -> indicates we have to iterate everything and call the manipulator recursively
  if ((payload.action as [])?.length === 0) {
    const array = payload.demoMode.slice(1);
    payload.response?.forEach((item: any) => {
      manipulator(array, { response: item, req });
    });
  } else {
    handleObjectManipulation(payload.action, payload.response, { activity: payload.activity, req });
    manipulator(payload.demoMode.slice(1), { response: payload.response, req });
  }
}
//[records:{property:'property to modify', prefix: 'prefix to add'}]
export const handleObjectManipulation = (
  objectAction: any,
  response: any,
  manipulationPayload: { activity: string; req: HttpRequest<any> }
): void => {
  const properties = objectAction['records'];
  properties.forEach((item: any) => {
    if (item.encryptKey) {
      encryptObjectKeys(item, response);
    } else {
      encryptObectValue(item, response, manipulationPayload);
    }
  });
};

const encryptObjectKeys = (item: any, response: any): void => {
  if (response && !Array.isArray(response)) {
    Object.keys(response).forEach((key: string) => {
      const encryptedKey = enDe.encrypt(key.toString());
      response[item['prefix'] ? `${item['prefix']}_${encryptedKey}` : encryptedKey] = response[key];
      delete response[key];
    });
  }
};

const encryptObectValue = (item: any, response: any, manipulationPayload: { activity: string; req: HttpRequest<any> }): void => {
  if (response[item['property']]) {
    const extension = ignoreExtensionManipulation(item, response, item['ignoreExtension']);
    const makeValue = ignoreMakeForAssetKey(item, response);
    const encryptedValue = getEncryptedValue(item, response, manipulationPayload);
    changePrefixByResponse(item, response, {
      customPrefixProperty: item['customPrefixProperty'],
      propertyToAddAsPrefix: item['propertyToAddAsPrefix']
    });
    item['prefixToBeAddedFromReqProperty'] && changePrefixByRequestPayload(item, manipulationPayload.req);
    response[item['property']] = checkPropertyParams({ response, encryptedValue, item, req: manipulationPayload.req });
    addExtension(extension, response, item);
    addMakeValue(makeValue, response, item);
  }
  updateAssetKey(item, response);
};

const addExtension = (extension: string, response: any, item: any): void => {
  extension && (response[item['property']] = `${response[item['property']]}.${extension}`);
};

const addMakeValue = (makeValue: string, response: any, item: any): void => {
  makeValue && (response[item['property']] = `${makeValue}|${response[item['property']]}`);
};

export function getEncryptedValue(item: any, response: any, manipulationPayload: { activity: string; req: HttpRequest<any> }): string {
  const { isValueNotToBeMasked } = getValueNotToBeMaskedStatus(item, response, manipulationPayload.req);
  const encryptedValue =
    !isValueNotToBeMasked && manipulationPayload.activity === 'encrypt'
      ? enDe.encrypt(response[item['property']].toString())
      : enDe.decrypt(response[item['property'].split(`${item['prefix']}_`)]);

  return encryptedValue;
}
/**
 * will check if the value needs to be masked or not.
 * will return true if the value to not be masked is present in response.
 */
export function getValueNotToBeMaskedStatus(
  item: any,
  response: any,
  req: HttpRequest<any>
): { isValueNotToBeMasked: boolean; paramBasedPrefix?: string } {
  const isValueNotToBeMasked = item['propertyValueToNotMask']?.includes(response[item['property']].toString());
  const paramPresent = checkPayloadParam(item, req);
  if (!isValueNotToBeMasked && paramPresent) {
    return {
      // setting value not to be masked as true if param was not found.
      isValueNotToBeMasked: paramPresent === 'false',
      paramBasedPrefix: item?.['paramBasedPrefix']?.[paramPresent]
    };
  }
  return { isValueNotToBeMasked };
}

/**
 *  To check if param is present in payload
 *  encryption is required if param is present in payload else not required
 * */
const checkPayloadParam = (item: any, req: HttpRequest<any>): string => {
  let paramPresent = '';
  if (item['checkParamProperty']) {
    // setting it false if param is not found
    paramPresent = 'false';
    for (const val of item['checkParamValue']) {
      if (req.body.payload.includes(`${item['checkParamProperty']}${val}`)) {
        // setting paramPresent as param if found
        paramPresent = val;
        break;
      }
    }
  }
  return paramPresent;
};

const getPrefix = (paramBasedPrefix: string, prefix: string): string => {
  if (paramBasedPrefix) {
    return paramBasedPrefix;
  }
  if (prefix) {
    return prefix;
  }
  return null;
};

export function checkPropertyParams(payload: PopertyParamPayload): string {
  const { response, encryptedValue, item, req } = payload;
  const { isValueNotToBeMasked, paramBasedPrefix } = getValueNotToBeMaskedStatus(item, response, req);
  const prefix = getPrefix(paramBasedPrefix, item['prefix']);
  if (isValueNotToBeMasked) {
    return response[item['property']];
  }
  if (item['isHidden']) {
    return '';
  }
  if (prefix) {
    return `${prefix}_${encryptedValue}`;
  }
  return encryptedValue;
}

export function ignoreMakeForAssetKey(item: any, response: any): string {
  let make;
  if (item['ignoreMake'] && response[item['property']]) {
    const [assetMake, serialNumber] = response[item['property']].split('|');
    make = assetMake;
    response[item['property']] = serialNumber;
  }
  return make;
}
/**
 * If the property extension should not be encrypt/decrypt.
 * then masking/unmasking without extesnion and adding extension after manipulation
 */
export function ignoreExtensionManipulation(item: any, response: any, ignoreExtension: boolean): string {
  let extension;
  if (ignoreExtension && response[item['property']]) {
    extension = response[item['property']].split('.').pop();
    response[item['property']] = response[item['property']].substr(0, response[item['property']].lastIndexOf('.'));
  }
  return extension;
}
/**
 * if prefix needs to be based on response value.
 * then adding the prefix based on response bysplitting and adding 0 index value.
 */
export function changePrefixByResponse(
  item: any,
  response: any,
  prefixProperties: { customPrefixProperty: string; propertyToAddAsPrefix: string }
): void {
  if (item['property'] === prefixProperties.customPrefixProperty) {
    item['prefix'] = response[prefixProperties.propertyToAddAsPrefix]?.split(' ')[0];
  }
}
/**
 * if prefix needs to be based on request payload.
 * then adding the prefix if value is present in request payload.
 */
export function changePrefixByRequestPayload(item: any, req: HttpRequest<any>): void {
  const { payload } = req.body;
  const payloadParams = new URLSearchParams(payload);
  const paramValue = payloadParams.get(item['prefixToBeAddedFromReqProperty']) as string | string[];
  paramValue && item['valueToBeInReq'] === paramValue && (item['prefix'] = paramValue);
}
/**
 *  if the property we want to change is asset serial number
 *  then we would probably want to manipualte the serial number inside the assetKey as well
 * */
export function updateAssetKey(item: any, response: any): void {
  if (checkAssetKey(item) && response['assetKey']) {
    const [assetMake] = response['assetKey'].split('|');
    response['assetKey'] = `${assetMake}|${response['serialNumber']}`;
  }
}

export function checkAssetKey(item: any): boolean {
  return item['property'] === 'serialNumber' && (item['prefix'] === 'asset' || item['prefix'] === 'assets');
}

export const decryptManipulator = (logic: any, body: any, req: HttpRequest<any>): HttpRequest<any> => {
  // when we have to decrypt and replace something in a string that is of type urlparams
  if (logic.method === 'POST' && logic?.type === 'param_replace') {
    return logic?.isObj ? decryptParamsForObj(logic, body, req) : decryptParamForURLReplace(body, logic, req);
  }
  // for decrypting url of get requests
  return decryptParamsForGetURL(logic, req);
};

const decryptParamsForGetURL = (logic: any, req: HttpRequest<any>): HttpRequest<any> => {
  let actualUrlwithParams = req.urlWithParams;
  const urlToReplace = logic.isEncoded ? new URLSearchParams(decodeURIComponent(actualUrlwithParams)): new URLSearchParams(actualUrlwithParams);
  let paramValue = urlToReplace.get(logic.param) as string | string[];

  if (!paramValue) {
    return req;
  }

  // if multi is true, then replace all the params
  paramValue = (paramValue as string).split(logic?.multi);
  paramValue.forEach((val: string) => {
    const newUrlWithParams = formNewUrlParams(logic, val, actualUrlwithParams);
    actualUrlwithParams = newUrlWithParams;
    req = req.clone({ url: newUrlWithParams , params: new HttpParams() });
  });

  return req;
};

const decryptParamsForObj = (logic: any, body: any, req: HttpRequest<any>): HttpRequest<any> => {
  const configKey = getDemoModeEncryptDecryptConfigKey(req, false);
  const decryptConfig = DECRYPT_CONFIG_FOR_OBJ[configKey];
  const param = decryptConfig.isJsonParseReq ? JSON.parse(body[logic.property]) : body[logic.property];
  if (Array.isArray(param)) {
    param.forEach((item: any) => {
      decryptObjectKeys(item, decryptConfig.keyNames);
    });
  } else {
    decryptObjectKeys(param, decryptConfig.keyNames);
  }
  return req.clone({ body: { ...body, [logic.property]: decryptConfig.isJsonParseReq ? JSON.stringify(param) : param } });
};

const decryptObjectKeys = (obj: any, keyNames: any): any => {
  keyNames.forEach((keyObj: { [key: string]: string }) => {
    if (checkIsString(obj[keyObj.key])) {
      obj[keyObj.key] = decryptStringBasedOnPrefix(keyObj.prefix, obj[keyObj.key]);
    } else if (checkIsArray(obj[keyObj.key])) {
      obj[keyObj.key].forEach((val: any, i: number) => {
        if (checkIsString(val) && val.includes(keyObj.prefix)) {
          obj[keyObj.key][i] = decryptStringBasedOnPrefix(keyObj.prefix, val);
        }
      });
    }
  });
  return obj;
};

const checkIsString = (value: any): boolean => value && typeof value === 'string';

const checkIsArray = (value: any): boolean => value && Array.isArray(value);

const decryptStringBasedOnPrefix = (prefix: string, value: string): string =>
  enDe.decrypt(prefix ? value.replace(`${prefix}_`, '') : value);

const decryptParamForURLReplace = (body: any, logic: any, req: HttpRequest<any>): HttpRequest<any> => {
  let actualUrlwithParams = body[logic.property];
  actualUrlwithParams = logic.internalProperties ? getActualInternalUrlWithParams(actualUrlwithParams, logic) : actualUrlwithParams;

  const urlToReplace = new URLSearchParams(actualUrlwithParams);
  const paramValue = urlToReplace.getAll(logic.param);

  if (!paramValue.length) {
    return req;
  }

  paramValue.forEach((param: string) => {
    // if multi is true, then replace all the params
    const paramArr = param.split(logic?.multi);
    paramArr.forEach((val: string) => {
      if (val.includes(logic?.prefix)) {
        const newUrlWithParams = formNewUrlParams(logic, val, actualUrlwithParams);
        actualUrlwithParams = newUrlWithParams;
        req = logic.internalProperties
          ? req.clone({
              body: {
                ...updateInternalObjectWithNewParams(logic.internalProperties[logic.internalProperties.length - 1], newUrlWithParams, body)
              }
            })
          : req.clone({ body: { ...body, [logic.property]: newUrlWithParams } });
      }
    });
  });

  return req;
};

/**
 *  when we have internal property values that needs to be decrypted
 */

export function getActualInternalUrlWithParams(actualUrlwithParams: any, logic: any): any {
  logic.internalProperties.forEach((property: string) => {
    actualUrlwithParams = actualUrlwithParams[property];
  });
  return actualUrlwithParams;
}

/**
 *  when we need to update payload of internal properties
 */

export function updateInternalObjectWithNewParams(keyName: any, newVal: any, object: any): any {
  const results = {};
  Object.keys(object).forEach((key: string) => {
    if (key === keyName) {
      results[key] = newVal;
    } else if (isValObject(object, key)) {
      results[key] = updateInternalObjectWithNewParams(keyName, newVal, object[key]);
    } else {
      results[key] = object[key];
    }
  });
  return results;
}

const isValObject = (object: any, key: any): boolean => typeof object[key] === 'object' && object[key] !== null && object[key].length !== 0;

const formNewUrlParams = (logic: any, paramValue: string, actualUrlwithParams: string): string => {
  const valueToReplace = logic.param === 'assetKey' || logic?.isAssetKey ? paramValue.split('|')[1] : paramValue;
  let newUrlWithParams = valueToReplace;
  const [, encryptedvalueToReplace] = valueToReplace.split(`${logic.prefix}_`);
  if (encryptedvalueToReplace) {
    const decryptedvalueToReplace = enDe.decrypt(encryptedvalueToReplace);
    newUrlWithParams = actualUrlwithParams.replace(valueToReplace, decryptedvalueToReplace);
  }
  return newUrlWithParams;
};
