<!-- right menu -->
@if (!isMobileView) {
  <div class="right-nav-wrapper" [appDemoModeHideDisable]="{ customClass: ['demo-mode-question-mark-gap'] }">
    @if (showDemoMode && demoModeStatus) {
      <div class="right-nav-demo-mode">
        <img
          class="user-img-demo-mode-white user-nav-img"
          alt="mobile-sort-icon"
          src="../assets/transparent_image.png"
          />
          <span class="right-nav-demo-label">{{ demoModeStrings.inDemoMode | transloco }}</span>
        </div>
      }
      <div class="right-nav-question-mark" [appDemoModeHideDisable]="{ overRideClass: [{ propertyName: 'display', value: 'none', priority: 'important' }]}">
        <ng-container *ngTemplateOutlet="questionMarkDetails"></ng-container>
      </div>
      <ng-container
    *ngTemplateOutlet="
      userDetails;
      context: {
        overLayClass: showDemoMode ? 'right-nav-cc-menu-withDemo' : 'right-nav-cc-menu'
      }
    "
    ></ng-container>
  </div>
} @else {
  <div class="right-nav-user-pref-container">
    <ng-container [ngTemplateOutlet]="changeAccountTemplateRef"> </ng-container>
    <cc-list class="right-nav-user-pref-options">
      <!-- user Name with role and upgrde link -->
      @if ((isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
        <cc-list-item class="right-nav-user-info">
          @if (isMobileViewUserInfo && isMobileView) {
            <span class="right-nav-user-initials-mobile"> {{ userInitials }} </span>
          }
          <div class="right-nav-mobile">
            <div class="right-nav-user-name" [ngClass]="{ 'right-nav-user-name-padding': !showUpgrade }">{{ userName }}</div>
            @if (showUpgrade) {
              <div class="right-nav-Viewer-link">
                <span>{{ userRole }} </span>
                <span class="right-nav-vr-line"></span>
                <span
                  class="right-nav-upgrade-btn"
              cc-tooltip=" {{
                userRole !== eCustomerUserRole
                  ? ('app.header.disableRoleTooltip' | transloco)
                  : ('app.header.disableRoleTooltipForECustomer' | transloco)
              }} "
                  tooltipWidth="{{ isMobileView ? tooltipWidth.MOBILE : tooltipWidth.DESKTOP }}"
                  [appClass]="'right-nav-tooltip'"
                  tooltipPosition="{{ isMobileView ? 'bottom-right' : 'left' }}"
                  [disabled]="enableUpgrade"
                  >
                  <button
                    cc-btn
                    cc-btn-tertiary
                    cc-btn-medium
                    class="right-nav-upgrade-link"
                    [setTheme]="true"
                    [disabled]="!enableUpgrade"
                    (click)="usermenuActions('upgrade')"
                    >
                    {{ 'UPGRADE_ROLE' | transloco }}
                  </button>
                </span>
              </div>
            }
          </div>
        </cc-list-item>
      }
      <!-- user pref options -->
      @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
        <cc-list-item
          class="right-nav-user-action right-nav-change-Account-template"
          (click)="openChangeAccount($event)"
          >
          @if (!isMobileViewUserInfo && !isMobileView) {
            <span *ngTemplateOutlet="changeAccountArrow"></span>
          }
          <span *ngTemplateOutlet="changeAccountTemplate"></span>
          <span class="right-nav-user-per-label right-nav-name-label">{{ ucidName }}</span>
          @if (!isMobileViewUserInfo && isMobileView) {
            <span class="right-nav-change-account-arrow">
              <span *ngTemplateOutlet="changeAccountArrowMobile"></span>
            </span>
          }
        </cc-list-item>
      }
      @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
        <cc-list-item
          class="right-nav-user-action right-nav-pref-help"
          (click)="usermenuActions('userPreference')"
          >
          <div>
            <span *ngTemplateOutlet="preferencesTemplate"></span>
            <span
              [isEllipseEnabled]="true"
              class="right-nav-user-per-label right-nav-ellipsis-label three-dot-ellipsis"
              cc-tooltip=" {{ 'Preferences' | transloco }}"
              >{{ 'Preferences' | transloco }}</span
              >
            </div>
          </cc-list-item>
        }
        @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
          <cc-list-item
            class="right-nav-user-action right-nav-pref-help"
            (click)="usermenuActions('help')"
            >
            <div>
              <span *ngTemplateOutlet="helpCenterTemplate"></span>
              <span
                [isEllipseEnabled]="true"
                class="right-nav-user-per-label right-nav-ellipsis-label three-dot-ellipsis"
                cc-tooltip=" {{ 'HELP_CENTER' | transloco }}"
                >{{ 'HELP_CENTER' | transloco }}</span
                >
              </div>
            </cc-list-item>
          }
          @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
            <cc-list-item
              class="right-nav-user-action"
              (click)="usermenuActions('logOut')"
              >
              <div>
                <span *ngTemplateOutlet="signOutTemplate"></span>
                <span class="right-nav-user-per-label">{{ 'SIGNOUT_BUTTON' | transloco }}</span>
              </div>
            </cc-list-item>
          }
          @if (showDemoMode && ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView))) {
            <cc-list-item
              class="right-nav-user-action demo-mode-user-action"
              >
              <div class="demo-cls">
                <img
                  class="user-img-demo-mode user-nav-img demo-mode-img"
                  alt="mobile-sort-icon"
                  src="../assets/transparent_image.png"
                  />
                  <span class="right-nav-user-per-label">{{ demoModeStrings.demo | transloco }}</span>
                  <span class="slider-icon">
                    <cc-slide-toggle
                      cc-slide-toggle-secondary
                      cc-slide-toggle-small
                      sliderEnabled="true"
                      shape="round"
                      [value]="demoModeStatus"
                      (valueChange)="toggleDemoMode($event)"
                      (click)="onStopPropogation($event)"
                    ></cc-slide-toggle>
                  </span>
                </div>
              </cc-list-item>
            }
          </cc-list>
        </div>
      }
      <!--question mark Template-->
      <ng-template #questionMarkDetails>
        <!----Question mark popup using ccpopover start---->
        <div class="right-nav-cookies-question-mark">
          @if (isHelpIconEnabled) {
            <div
              cc-popover
              [isChevron]="true"
              [template]="popOver"
              [headerbackgroundColor]="headerbackgroundColor"
              [popoverClass]="'right-nav-cc-questionMark-popover'"
              [autoposition]="true"
              title="{{ cookiesString.onetrustPopupTitle | transloco }}"
              [OverlayPosition]="'bottomCenter'"
              >
              <ng-container *ngTemplateOutlet="questionMark"> </ng-container>
            </div>
            <ng-template #popOver>
              <div
                class="right-nav-cc-questionMark-popup-content-wrapper rightnav-questionMark-popup-wrapper"
                cc-popover
                [overlayPanelClass]="'cc-popover right-nav-cc-questionMark-popover'"
                >
                <span class="right-nav-cc-popup-image-wrapper right-nav-image-wrapper">
                  <ng-container *ngTemplateOutlet="cookiesNotEnabledIcon"></ng-container>
                </span>
                <div class="right-nav-cc-popup-content-text right-nav-popup-content">
                  {{ cookiesString.onetrustCookiesNotEnable | transloco }}
                </div>
                <div class="right-nav-cc-popup-content-text-body right-nav-popup-content-body">
                  {{ cookiesString.onetrustCookiesWhatNewView | transloco }}
                </div>
                <div (click)="initOuterAction('enableCookies')" class="right-nav-cc-popup-content-text-end right-nav-popup-content-end">
                  {{ cookiesString.onetrustCookiesEnable | transloco }}
                </div>
              </div>
            </ng-template>
          } @else {
            <span class="right-nav-cc-popup-img">
              <ng-container *ngTemplateOutlet="questionMarkIcon"></ng-container>
            </span>
          }

          <ng-template #questionMark>
            <span class="right-nav-cc-popup-img">
              <ng-container *ngTemplateOutlet="questionMarkIcon"></ng-container>
            </span>
          </ng-template>
        </div>
        <!---Question mark popup using ccpopover end -->
      </ng-template>
      <!-- Templates -->
      <ng-template #userDetails let-overLayClass="overLayClass">
        <div class="right-nav-ucid-container" cc-menu [overlayPanelClass]="overLayClass" [template]="userPrefOptions">
          <div class="right-nav-hover-container">
            <!-- selected ucid Name -->
            <span class="right-nav-ucid-name">{{ ucidName }}</span>
            <!--user Initials Icon  -->
            <span class="right-nav-user-initials"> {{ userInitials }} </span>
          </div>
        </div>
      </ng-template>

      <ng-template #userPrefOptions>
        <div class="right-nav-user-pref-container">
          <ng-container [ngTemplateOutlet]="changeAccountTemplateRef"> </ng-container>
          <cc-list class="right-nav-user-pref-options">
            <!-- user Name with role and upgrde link -->
            @if ((isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
              <cc-list-item class="right-nav-user-info">
                @if (isMobileViewUserInfo && isMobileView) {
                  <span class="right-nav-user-initials-mobile"> {{ userInitials }} </span>
                }
                <div class="right-nav-mobile">
                  <div class="right-nav-user-name" [ngClass]="{ 'right-nav-user-name-padding': !showUpgrade }">{{ userName }}</div>
                  @if (showUpgrade) {
                    <div class="right-nav-Viewer-link">
                      <span>{{ userRole }} </span>
                      <span class="right-nav-vr-line"></span>
                      <span
                        class="right-nav-upgrade-btn"
              cc-tooltip=" {{
                userRole !== eCustomerUserRole
                  ? ('app.header.disableRoleTooltip' | transloco)
                  : ('app.header.disableRoleTooltipForECustomer' | transloco)
              }} "
                        tooltipWidth="{{ isMobileView ? tooltipWidth.MOBILE : tooltipWidth.DESKTOP }}"
                        [appClass]="'right-nav-tooltip'"
                        tooltipPosition="{{ isMobileView ? 'bottom-right' : 'left' }}"
                        [disabled]="enableUpgrade"
                        >
                        <button
                          cc-btn
                          cc-btn-tertiary
                          cc-btn-medium
                          class="right-nav-upgrade-link"
                          [setTheme]="true"
                          [disabled]="!enableUpgrade"
                          (click)="usermenuActions('upgrade')"
                          >
                          {{ 'UPGRADE_ROLE' | transloco }}
                        </button>
                      </span>
                    </div>
                  }
                </div>
              </cc-list-item>
            }
            <!-- user pref options -->
            @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
              <cc-list-item
                class="right-nav-user-action right-nav-change-Account-template"
                (click)="openChangeAccount($event)"
                >
                @if (!isMobileViewUserInfo && !isMobileView) {
                  <span *ngTemplateOutlet="changeAccountArrow"></span>
                }
                <span *ngTemplateOutlet="changeAccountTemplate"></span>
                <span class="right-nav-user-per-label right-nav-name-label">{{ ucidName }}</span>
                @if (!isMobileViewUserInfo && isMobileView) {
                  <span class="right-nav-change-account-arrow">
                    <span *ngTemplateOutlet="changeAccountArrowMobile"></span>
                  </span>
                }
              </cc-list-item>
            }

            @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
              <cc-list-item
                class="right-nav-user-action right-nav-pref-help"
                (click)="usermenuActions('userPreference')"
                >
                <div>
                  <span *ngTemplateOutlet="preferencesTemplate"></span>
                  <span
                    [isEllipseEnabled]="true"
                    class="right-nav-user-per-label right-nav-ellipsis-label three-dot-ellipsis"
                    cc-tooltip=" {{ 'Preferences' | transloco }}"
                    >{{ 'Preferences' | transloco }}</span
                    >
                  </div>
                </cc-list-item>
              }

              @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
                <cc-list-item
                  class="right-nav-user-action right-nav-pref-help"
                  (click)="usermenuActions('help')"
                  >
                  <div>
                    <span *ngTemplateOutlet="helpCenterTemplate"></span>
                    <span
                      [isEllipseEnabled]="true"
                      class="right-nav-user-per-label right-nav-ellipsis-label three-dot-ellipsis"
                      cc-tooltip=" {{ 'HELP_CENTER' | transloco }}"
                      >{{ 'HELP_CENTER' | transloco }}</span
                      >
                    </div>
                  </cc-list-item>
                }

                @if ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView)) {
                  <cc-list-item
                    class="right-nav-user-action"
                    (click)="usermenuActions('logOut')"
                    >
                    <div>
                      <span *ngTemplateOutlet="signOutTemplate"></span>
                      <span class="right-nav-user-per-label">{{ 'SIGNOUT_BUTTON' | transloco }}</span>
                    </div>
                  </cc-list-item>
                }

                @if (showDemoMode && ((!isMobileViewUserInfo && isMobileView) || (!isMobileViewUserInfo && !isMobileView))) {
                  <cc-list-item
                    class="right-nav-user-action demo-mode-user-action"
                    >
                    <div class="demo-cls">
                      <img
                        class="user-img-demo-mode user-nav-img demo-mode-img"
                        alt="mobile-sort-icon"
                        src="../assets/transparent_image.png"
                        />
                        <span class="right-nav-user-per-label">{{ demoModeStrings.demo | transloco }}</span>
                        <span class="slider-icon">
                          <cc-slide-toggle
                            cc-slide-toggle-secondary
                            cc-slide-toggle-small
                            sliderEnabled="true"
                            shape="round"
                            [value]="demoModeStatus"
                            (valueChange)="toggleDemoMode($event)"
                            (click)="onStopPropogation($event)"
                          ></cc-slide-toggle>
                        </span>
                      </div>
                    </cc-list-item>
                  }
                </cc-list>
              </div>
            </ng-template>

            <!-- change Account svg template -->
            <ng-template #changeAccountTemplate>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.52757 20H14.4724C14.7626 20 15 19.7989 15 19.5531V4.44693C15 4.20112 14.7626 4 14.4724 4H4.52757C4.23741 4 4 4.20112 4 4.44693V19.5531C4 19.7989 4.23741 20 4.52757 20ZM13.9449 19.1061H5.05514V4.89385H13.9449V19.1061Z"
                  fill="black"
                  />
                  <path d="M8.7168 6H6.6833V7.04H8.7168V6Z" fill="black" />
                  <path d="M12.4971 6H10.4636V7.04H12.4971V6Z" fill="black" />
                  <path d="M8.7168 8.48218H6.6833V9.525H8.7168V8.48218Z" fill="black" />
                  <path d="M12.4971 8.48218H10.4636V9.525H12.4971V8.48218Z" fill="black" />
                  <path d="M8.7168 11.2197H6.6833V12.2625H8.7168V11.2197Z" fill="black" />
                  <path d="M12.4971 11.2197H10.4636V12.2625H12.4971V11.2197Z" fill="black" />
                  <path d="M8.7168 13.9309H6.6833V14.9737H8.7168V13.9309Z" fill="black" />
                  <path d="M12.4971 13.9309H10.4636V14.9737H12.4971V13.9309Z" fill="black" />
                  <path d="M8.7168 16.7983H6.6833V17.8412H8.7168V16.7983Z" fill="black" />
                  <path d="M12.4971 16.7983H10.4636V17.8412H12.4971V16.7983Z" fill="black" />
                  <path
                    d="M14.5276 20H19.4724C19.7626 20 20 19.7989 20 19.5531V9.44693C20 9.20112 19.7626 9 19.4724 9H14.5276C14.2374 9 14 9.20112 14 9.44693V19.5531C14 19.7989 14.2374 20 14.5276 20ZM18.9449 19.1061H15.0551V9.89386H18.9449V19.1061Z"
                    fill="black"
                    />
                    <path d="M18 11.2197H15.9665V12.2625H18V11.2197Z" fill="black" />
                    <path d="M18 13.9309H15.9665V14.9737H18V13.9309Z" fill="black" />
                    <path d="M18 16.7983H15.9665V17.8412H18V16.7983Z" fill="black" />
                  </svg>
                </ng-template>

                <!-- preferences svg template -->
                <ng-template #preferencesTemplate>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.7419 13.0435H20V10.9565H17.7412C17.5958 10.3534 17.3579 9.4393 17.0435 8.92383L18.6407 7.3273L16.6727 5.3593L15.0748 6.95652C14.56 6.64209 13.6466 6.40487 13.0435 6.25948V4H10.9565V6.25948C10.3534 6.40487 9.4393 6.64209 8.92452 6.95652L7.32661 5.3593L5.35861 7.3273L6.95652 8.92452C6.64209 9.4393 6.40417 10.3534 6.25809 10.9565H4V13.0435H6.25809C6.40417 13.6466 6.64209 14.5607 6.95652 15.0755L5.35861 16.6734L7.32661 18.6407L8.92383 17.0428C9.4393 17.3586 10.3534 17.5958 10.9565 17.7419V20H13.0435V17.7419C13.6466 17.5958 14.5607 17.3579 15.0748 17.0435L16.6734 18.6407L18.6407 16.6734L17.0435 15.0755C17.3579 14.5607 17.5958 13.6466 17.7419 13.0435Z"
                      stroke="black"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      />
                      <path
                        d="M12.0001 15.1304C13.729 15.1304 15.1305 13.7289 15.1305 12C15.1305 10.2711 13.729 8.86957 12.0001 8.86957C10.2712 8.86957 8.86963 10.2711 8.86963 12C8.86963 13.7289 10.2712 15.1304 12.0001 15.1304Z"
                        stroke="black"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                      </svg>
                    </ng-template>

                    <!-- help center svg template -->
                    <ng-template #helpCenterTemplate>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4 11.9999C4 14.1217 4.84285 16.1565 6.34313 17.6568C7.84341 19.157 9.87823 19.9999 11.9999 19.9999C14.1217 19.9999 16.1565 19.157 17.6568 17.6568C19.157 16.1565 19.9999 14.1217 19.9999 11.9999C19.9999 9.87823 19.157 7.84341 17.6568 6.34313C16.1565 4.84285 14.1217 4 11.9999 4C9.87823 4 7.84341 4.84285 6.34313 6.34313C4.84285 7.84341 4 9.87823 4 11.9999V11.9999Z"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          />
                          <mask id="path-2-outside-1_646_12936" maskUnits="userSpaceOnUse" x="10.6362" y="14.6356" width="3" height="3" fill="black">
                            <rect fill="white" x="10.6362" y="14.6356" width="3" height="3" />
                            <path
                              d="M11.9999 15.6356C11.9279 15.6356 11.8576 15.6569 11.7978 15.6968C11.738 15.7368 11.6914 15.7936 11.6639 15.86C11.6364 15.9265 11.6292 15.9996 11.6432 16.0701C11.6572 16.1407 11.6919 16.2055 11.7427 16.2563C11.7936 16.3072 11.8584 16.3418 11.9289 16.3558C11.9995 16.3699 12.0726 16.3627 12.139 16.3351C12.2055 16.3076 12.2623 16.261 12.3022 16.2012C12.3422 16.1414 12.3635 16.0711 12.3635 15.9992C12.3635 15.9028 12.3252 15.8103 12.257 15.7421C12.1888 15.6739 12.0963 15.6356 11.9999 15.6356Z"
                              />
                            </mask>
                            <path
                              d="M11.9999 14.6356C11.7302 14.6356 11.4665 14.7155 11.2423 14.8654L12.3534 16.5283C12.2488 16.5982 12.1257 16.6356 11.9999 16.6356V14.6356ZM11.2423 14.8654C11.018 15.0152 10.8432 15.2282 10.74 15.4774L12.5878 16.2427C12.5396 16.359 12.4581 16.4584 12.3534 16.5283L11.2423 14.8654ZM10.74 15.4774C10.6368 15.7265 10.6098 16.0007 10.6624 16.2652L12.624 15.875C12.6486 15.9985 12.636 16.1264 12.5878 16.2427L10.74 15.4774ZM10.6624 16.2652C10.715 16.5297 10.8449 16.7727 11.0356 16.9634L12.4498 15.5492C12.5388 15.6382 12.5994 15.7516 12.624 15.875L10.6624 16.2652ZM11.0356 16.9634C11.2263 17.1541 11.4693 17.284 11.7338 17.3366L12.124 15.3751C12.2474 15.3996 12.3608 15.4602 12.4498 15.5492L11.0356 16.9634ZM11.7338 17.3366C11.9983 17.3892 12.2725 17.3622 12.5217 17.259L11.7563 15.4113C11.8726 15.3631 12.0006 15.3505 12.124 15.3751L11.7338 17.3366ZM12.5217 17.259C12.7709 17.1558 12.9838 16.981 13.1337 16.7568L11.4707 15.6456C11.5407 15.541 11.6401 15.4594 11.7563 15.4113L12.5217 17.259ZM13.1337 16.7568C13.2835 16.5325 13.3635 16.2689 13.3635 15.9992H11.3635C11.3635 15.8733 11.4008 15.7503 11.4707 15.6456L13.1337 16.7568ZM13.3635 15.9992C13.3635 15.6375 13.2198 15.2907 12.9641 15.035L11.5499 16.4492C11.4305 16.3298 11.3635 16.168 11.3635 15.9992H13.3635ZM12.9641 15.035C12.7084 14.7792 12.3615 14.6356 11.9999 14.6356V16.6356C11.8311 16.6356 11.6692 16.5685 11.5499 16.4492L12.9641 15.035Z"
                              fill="black"
                              mask="url(#path-2-outside-1_646_12936)"
                              />
                              <path
                                d="M9.81836 9.81779C9.81842 9.42445 9.92481 9.03844 10.1263 8.70061C10.3278 8.36278 10.6168 8.0857 10.9628 7.89869C11.3089 7.71168 11.6991 7.62169 12.092 7.63826C12.485 7.65482 12.8662 7.77732 13.1953 7.99279C13.5244 8.20826 13.7891 8.50868 13.9614 8.86226C14.1338 9.21585 14.2073 9.60944 14.1743 10.0014C14.1412 10.3933 14.0028 10.7691 13.7737 11.0888C13.5447 11.4086 13.2334 11.6604 12.8729 11.8178C12.6135 11.931 12.3928 12.1174 12.2378 12.3542C12.0827 12.591 12.0002 12.8678 12.0002 13.1509V13.4505"
                                stroke="black"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                              </svg>
                            </ng-template>

                            <!-- signOut svg template -->
                            <ng-template #signOutTemplate>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4 4.5C4 4.22386 4.22386 4 4.5 4H13.5C13.7761 4 14 4.22386 14 4.5V8.53846C14 8.8146 13.7761 9.03846 13.5 9.03846C13.2239 9.03846 13 8.8146 13 8.53846V5H5V19H13V15.4615C13 15.1854 13.2239 14.9615 13.5 14.9615C13.7761 14.9615 14 15.1854 14 15.4615V19.5C14 19.7761 13.7761 20 13.5 20H4.5C4.22386 20 4 19.7761 4 19.5V4.5ZM10 12C10 11.7239 10.2239 11.5 10.5 11.5H18.2929L15.6464 8.85355C15.4512 8.65829 15.4512 8.34171 15.6464 8.14645C15.8417 7.95118 16.1583 7.95118 16.3536 8.14645L19.8536 11.6464C20.0488 11.8417 20.0488 12.1583 19.8536 12.3536L16.3536 15.8536C16.1583 16.0488 15.8417 16.0488 15.6464 15.8536C15.4512 15.6583 15.4512 15.3417 15.6464 15.1464L18.2929 12.5H10.5C10.2239 12.5 10 12.2761 10 12Z"
                                  fill="black"
                                  />
                                </svg>
                              </ng-template>
                              <!-- loder Template -->
                              <ng-template #loderTemplate>
                                <div class="right-nav-count-loader">
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                  <div class="right-nav-count-loader-bodydiv"></div>
                                </div>
                              </ng-template>
                              <!-- questionMarkIcon template-->
                              <ng-template #questionMarkIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <g clip-path="url(#clip0_3187_4565)">
                                    <path
                                      d="M1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C5.32387 18.0518 7.61305 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10V10Z"
                                      stroke="white"
                                      stroke-width="1.25"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      />
                                      <path
                                        d="M10 14C9.80222 14 9.60888 14.0587 9.44443 14.1685C9.27998 14.2784 9.15181 14.4346 9.07612 14.6173C9.00043 14.8 8.98063 15.0011 9.01921 15.1951C9.0578 15.3891 9.15304 15.5673 9.29289 15.7071C9.43275 15.847 9.61093 15.9422 9.80491 15.9808C9.99889 16.0194 10.2 15.9996 10.3827 15.9239C10.5654 15.8482 10.7216 15.72 10.8315 15.5556C10.9414 15.3911 11 15.1978 11 15C11 14.7348 10.8946 14.4804 10.7071 14.2929C10.5196 14.1054 10.2652 14 10 14Z"
                                        fill="#D9D9D9"
                                        />
                                        <path
                                          d="M7.54541 7.54512C7.54548 7.1026 7.66517 6.66834 7.89183 6.28828C8.11848 5.90821 8.44366 5.5965 8.83297 5.38611C9.22227 5.17572 9.6612 5.07448 10.1033 5.09312C10.5454 5.11176 10.9743 5.24957 11.3445 5.49197C11.7147 5.73437 12.0125 6.07235 12.2064 6.47014C12.4003 6.86792 12.483 7.31072 12.4458 7.75167C12.4087 8.19262 12.253 8.61532 11.9952 8.97504C11.7375 9.33476 11.3873 9.61811 10.9818 9.79512C10.69 9.92247 10.4416 10.1322 10.2672 10.3986C10.0929 10.665 9.99996 10.9764 9.99996 11.2948L10 12"
                                          stroke="white"
                                          stroke-width="1.25"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_3187_4565">
                                            <rect width="20" height="20" fill="white" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </ng-template>
                                    <!--cookies Not Enabled Icon Template-->
                                    <ng-template #cookiesNotEnabledIcon>
                                      <svg width="172" height="164" viewBox="0 0 172 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M63.1238 64.9545C41.6578 86.3047 59.6114 112.388 70.6469 119.668C82.8995 127.778 90.4034 115.574 81.222 106.81C65.3602 91.4475 38.1774 95.5131 23.5235 120.651"
                                          stroke="#C6CCD6"
                                          stroke-miterlimit="10"
                                          stroke-dasharray="4 4"
                                          />
                                          <path d="M63.9634 131.662L23.6268 147.591L3.29628 95.6093L33.2613 83.7455L47.4942 89.5402L63.9634 131.662Z" fill="#F3F4F6" />
                                          <path d="M33.2617 83.7454L47.4946 89.5401L37.1348 93.6426L33.2617 83.7454Z" fill="#C2C9D4" />
                                          <path d="M144.606 148.383L105.818 128.951L130.565 78.9428L159.402 93.3583L164.663 107.865L144.606 148.383Z" fill="#F3F4F6" />
                                          <path d="M159.402 93.3583L164.663 107.865L154.692 102.881L159.402 93.3583Z" fill="#C2C9D4" />
                                          <path
                                            d="M48.769 143.017V36.3229C48.769 34.666 50.1122 33.3229 51.769 33.3229H111.004L120.397 44.1634L129.845 55.0672V143.017C129.845 144.674 128.502 146.017 126.845 146.017H90.8649H51.769C50.1122 146.017 48.769 144.674 48.769 143.017Z"
                                            fill="#FFF4E9"
                                            stroke="#F8C897"
                                            stroke-width="2"
                                            />
                                            <rect x="61.7744" y="50.0607" width="20" height="20" rx="3" fill="#FFF4E9" stroke="#F8C897" stroke-width="2" />
                                            <path d="M61.2637 84.0624H102.844" stroke="#F8C897" stroke-width="2" stroke-linecap="round" />
                                            <path d="M61.2637 94.0624H102.844" stroke="#F8C897" stroke-width="2" stroke-linecap="round" />
                                            <path d="M61.2637 104.062H102.844" stroke="#F8C897" stroke-width="2" stroke-linecap="round" />
                                            <path d="M61.2637 114.062H102.844" stroke="#F8C897" stroke-width="2" stroke-linecap="round" />
                                            <path d="M61.2637 124.062H89.3068" stroke="#F8C897" stroke-width="2" stroke-linecap="round" />
                                            <g clip-path="url(#clip0_30927_10919)">
                                              <path
                                                d="M108.596 160.672L108.596 160.672C106.285 161.175 104.271 159.004 104.955 156.712L104.955 156.712L117.19 115.646C117.894 113.284 120.907 112.632 122.518 114.484L122.518 114.485L150.595 146.77C152.16 148.571 151.224 151.388 148.909 151.892L108.596 160.672Z"
                                                fill="white"
                                                stroke="#F8C897"
                                                stroke-width="2"
                                                />
                                                <path
                                                  d="M123.647 147.4C123.559 146.993 123.551 146.607 123.621 146.23C123.691 145.856 123.83 145.512 124.038 145.204C124.247 144.897 124.522 144.631 124.86 144.41C125.198 144.189 125.601 144.025 126.062 143.927C126.527 143.827 126.96 143.805 127.365 143.864C127.77 143.922 128.134 144.051 128.45 144.244C128.765 144.436 129.035 144.693 129.251 145.002C129.468 145.315 129.621 145.675 129.708 146.078C129.795 146.485 129.803 146.872 129.732 147.238C129.662 147.608 129.522 147.944 129.314 148.256C129.105 148.563 128.827 148.83 128.481 149.055C128.136 149.277 127.733 149.441 127.268 149.54C126.803 149.643 126.374 149.661 125.973 149.602C125.571 149.543 125.214 149.413 124.895 149.221C124.58 149.028 124.31 148.775 124.095 148.47C123.887 148.163 123.734 147.807 123.647 147.4ZM127.794 141.014L123.286 141.995L119.306 126.664L125.093 125.405L127.794 141.014Z"
                                                  fill="#F8C897"
                                                  stroke="white"
                                                  stroke-width="1.5"
                                                  />
                                                </g>
                                                <ellipse cx="101.247" cy="20.3321" rx="1.55536" ry="1.56401" fill="#C2C9D4" />
                                                <ellipse cx="38.9396" cy="44.3136" rx="1.55536" ry="1.56401" fill="#C2C9D4" />
                                                <path
                                                  d="M165.743 61.0086L165.108 59.4324L164.474 61.0086C163.939 62.3381 162.675 63.4619 160.96 64.1244C162.675 64.7869 163.939 65.9108 164.474 67.2402L165.108 68.8164L165.743 67.2402C166.277 65.9108 167.541 64.7869 169.256 64.1244C167.541 63.4619 166.277 62.3381 165.743 61.0086Z"
                                                  fill="#C6CCD6"
                                                  />
                                                  <path
                                                    d="M69.1664 1.57619L68.5319 0L67.8974 1.57619C67.3626 2.90568 66.0989 4.02957 64.3843 4.69203C66.0989 5.35449 67.3626 6.47838 67.8974 7.80787L68.5319 9.38406L69.1664 7.80787C69.7012 6.47838 70.9649 5.35449 72.6795 4.69203C70.9649 4.02957 69.7012 2.90568 69.1664 1.57619Z"
                                                    fill="#C6CCD6"
                                                    />
                                                    <path
                                                      opacity="0.5"
                                                      d="M16.2049 65.1793L15.5705 63.6031L14.936 65.1793C14.4012 66.5088 13.1375 67.6327 11.4229 68.2952C13.1375 68.9576 14.4012 70.0815 14.936 71.411L15.5705 72.9872L16.2049 71.411C16.7398 70.0815 18.0035 68.9576 19.7181 68.2952C18.0035 67.6327 16.7398 66.5088 16.2049 65.1793Z"
                                                      fill="#C6CCD6"
                                                      />
                                                      <path
                                                        d="M19.5213 123.036L16.6274 124.701L17.129 125.593C17.2833 125.864 17.2833 126.174 17.0904 126.445C17.0132 126.6 16.8975 126.716 16.8589 126.871C16.7046 127.142 16.4345 127.297 16.1644 127.297H15.1226V130.668H16.1644C16.473 130.668 16.7431 130.823 16.8589 131.094C16.9361 131.249 17.0132 131.404 17.0904 131.52C17.2448 131.791 17.2833 132.101 17.129 132.373L16.6274 133.302L19.5213 134.968L20.0229 134.077C20.1772 133.806 20.4473 133.651 20.756 133.69C20.8332 133.69 20.9104 133.69 20.9875 133.69C21.0647 133.69 21.1419 133.69 21.219 133.69C21.5277 133.69 21.7978 133.806 21.9522 134.077L22.4538 134.968L25.3477 133.302L24.8461 132.411C24.6917 132.14 24.6917 131.83 24.8847 131.559C24.9618 131.404 25.0776 131.288 25.1162 131.133C25.2705 130.862 25.5406 130.707 25.8107 130.707H26.8525V127.336H25.8879C25.5792 127.336 25.3091 127.181 25.1933 126.91C25.1162 126.755 25.039 126.6 24.9618 126.484C24.8075 126.212 24.7689 125.902 24.9232 125.631L25.4249 124.74L22.5309 123.074L22.0293 123.965C21.875 124.237 21.6049 124.353 21.2962 124.353C21.2191 124.353 21.1419 124.353 21.0647 124.353C20.9875 124.353 20.9104 124.353 20.8332 124.353C20.5245 124.353 20.2544 124.237 20.1001 123.965L19.5213 123.036ZM21.0261 126.251C22.531 126.251 23.7657 127.491 23.7657 129.002C23.7657 130.513 22.531 131.753 21.0261 131.753C19.5213 131.753 18.2866 130.513 18.2866 129.002C18.2866 127.491 19.4827 126.251 21.0261 126.251Z"
                                                        fill="#F3F4F6"
                                                        />
                                                        <defs>
                                                          <clipPath id="clip0_30927_10919">
                                                            <rect width="52.4746" height="50.5305" fill="white" transform="matrix(0.99156 -0.129647 0.128617 0.991694 96.5352 113.889)" />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                    </ng-template>
                                                    <!-- change Account svg template -->
                                                    <ng-template #changeAccountArrow>
                                                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 9L1 5L5 1" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                                      </svg>
                                                    </ng-template>

                                                    <!-- change Account arrow svg template for mobile-->
                                                    <ng-template #changeAccountArrowMobile>
                                                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                          d="M1 9L4.94722 5.13009C4.96382 5.11377 4.9771 5.09374 4.9862 5.07132C4.9953 5.04889 5 5.02459 5 5C5 4.97541 4.9953 4.95111 4.9862 4.92869C4.9771 4.90626 4.96382 4.88623 4.94722 4.86991L1 1"
                                                          stroke="black"
                                                          stroke-width="1.25"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          />
                                                        </svg>
                                                      </ng-template>

                                                      <!-- Close icon template for change account popup mobile view -- START -->
                                                      <ng-template #closeIcon>
                                                        <svg class="mobile-close-button" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M1 15L15 1M1 1L15 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                      </ng-template>
                                                      <!-- Close icon template for change account popup mobile view -- END -->

                                                      <!-- Back icon template for change account popup mobile view -- START -->
                                                      <ng-template #backIcon>
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M32 16C32 24.8368 24.8361 32 16.0004 32C7.16385 32 0 24.8368 0 16C0 7.16318 7.16385 0 16.0004 0C24.8361 0 32 7.16318 32 16Z"
                                                            fill="#F2F2F2"
                                                            />
                                                            <path d="M22 16.0003H10" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path
                                                              d="M14.3636 20L10 16L14.3636 12"
                                                              stroke="black"
                                                              stroke-width="2"
                                                              stroke-miterlimit="10"
                                                              stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              />
                                                            </svg>
                                                          </ng-template>
                                                          <!-- Back icon template for change account popup mobile view -- END -->
                                                          <ng-template #searchIcon>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path
                                                                d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                                                                fill="black"
                                                              ></path>
                                                            </svg>
                                                          </ng-template>
