import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MonitoringService } from './insight-analytics.service';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
  constructor(private readonly injector: Injector) {
    super();
  }

  handleError(error: any): void {
    super.handleError(error);
    const monitoringService = this.injector.get(MonitoringService);
    monitoringService.logError(error);
  }
}
