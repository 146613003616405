import { SharedLibModule } from '@Terra/shared/shared-lib';
import { MsalSkipInterceptor, PkceAuthInterceptor } from '@Terra/shared/widgets/pkce-auth';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CcMessageModule } from '@cat-digital-workspace/shared-ui-core/message';
import { ErrorInterceptor } from './error.interceptor';
import { HeadersInterceptor } from './header.interceptor';
import { DEFAULT_TIMEOUT, TimeoutInterceptor, defaultTimeout } from './timeout.interceptor';

@NgModule({
  imports: [CommonModule, SharedLibModule, CcMessageModule],
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalSkipInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PkceAuthInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: defaultTimeout, multi: true }
  ]
})
export class HttpInterceptorModule {}
