export const configStrings = {
  xOrganizationId: 'x-organization-id',
  xGroupId: 'x-group-id',
  xCatAPITrackingId: 'X-Cat-API-Tracking-Id',
  contentType: 'Content-Type',
  applicationJson: 'application/json',
  xpackageName: 'x-package-name',
  acceptLanguage: 'Accept-Language',
  xHarvesterApplicationUcid: 'X-Harvester-ApplicationUcid',
  xMixedfleetintUcid: 'x-mixedfleetint-ucid',
  xApplicationId: 3,
  xApplicationIdConfig:'x-application-id'
};

export const CACHE_ENDPOINTS = ['productFamilyMapping_asus', 'getTimezonesList_asus'];
