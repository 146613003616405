import { InjectionToken } from '@angular/core';

export const DATA_DOMAIN = new InjectionToken<string>('OneTrust_DataDomain_Script_GUID');
export const DEFAULT_DATA_DOMAIN = '6b32398a-3e80-4329-b54b-c689861a9fe9';
export const SCRIPT_LOAD_ERROR = 'Error Loading OneTrust Script';
export const ONE_TRUST_SCRIPT_DOMAIN = 'https://cdn.cookielaw.org';
export const ONE_TRUST_SCRIPT_ENDPOINT = '/scripttemplates/otSDKStub.js';
export const MIN_LOADING_THRESHOLD = 500;
export const MIN_COUNT_CHECK = 50;
export const SCRIPT_TAG_CONSTANTS = {
  CHARSET: 'UTF-8',
  TYPE: 'text/javascript'
};

export enum COOKIES_TAG {
  STRICTLY_NECESSARY = 'C0001',
  PERFORMANCE = 'C0002',
  FUNCTIONAL = 'C0003',
  TARGETING = 'C0004'
}

const ONE_TRUST_UNSUPPORTED_LANGUAGES = ['ta', 'nb', 'mn'];
export const ONE_TRUST_LANG_CODES = (lang: string) => {
  const langCode = lang.split('-')[0];
  return ONE_TRUST_UNSUPPORTED_LANGUAGES.includes(langCode) ? 'en' : langCode;
};
