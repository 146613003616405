import { CACHE_ENDPOINTS } from './interceptor.config';

export const checkIfUrlIncludesCacheEndpoints = (url: string): boolean => {
  for (const item of CACHE_ENDPOINTS) {
    if (url.includes(item)) {
      return true;
    }
  }
  return false;
};
