/**This config includes the req url as a key and unique part(only) of
 * either unique part of req -->body handlerUrl or  unique part of req --> urlWthParams,
 * whichever is available and unique. we can add multiple handlerUrl/urlWithParams unique part in array value associated with the req url key;
 */
export const CONFIG_ENCRYPT_KEY_SUFFIX = {
  // req url      : ['unique part of req.body.handlerURL or rew.urlWithParams']
  getSitesWithKPIS: [
    { urlSubString: '/api/v2/jobsites', configKey: 'getSitesWithKPIS_api_v2_jobsites' },
    { urlSubString: '/api/v1/jobsites', configKey: 'getSitesWithKPIS_api_v1_jobsites' }
  ],
  postAssetList: [{ urlSubString: '/api/v2/assets', configKey: 'postAssetList_api_v2_assets' }],
  parties: [{ urlSubString: 'entitlements/v1/parties', configKey: 'parties_entitlements_v1_parties' }],
  // insights
  siteassets: [{ urlSubString: '/api/v1/organization/jobsite', configKey: 'siteassets_api_v1_organization_jobsite' }],
  postSegment: [{ urlSubString: '/api/v1/segments', configKey: 'postSegment_api_v1_segments' }],
  zones: [{ urlSubString: '/api/v1/organization/jobsite', configKey: 'zones_api_v1_organization_jobsite' }],
  zoneInfo: [{ urlSubString: '/api/v1/sites', configKey: 'zoneInfo_api_v1_sites' }],
  productivityinsights: [
    { urlSubString: '/api/v3/tableData', configKey: 'productivityinsights_api_v3_tableData' },
    { urlSubString: '/api/v1/assetsummary', configKey: 'productivityinsights_api_v1_assetsummary' },
    { urlSubString: '/api/v2/assetsummary', configKey: 'productivityinsights_api_v2_assetsummary' },
    { urlSubString: '/api/v1/filter/equipmentid', configKey: 'productivityinsights_api_v1_filter_equipmentid' },
    { urlSubString: '/api/v1/filter/serialnumber', configKey: 'productivityinsights_api_v1_filter_serialnumber' },
    { urlSubString: '/api/v1/filter/loadedby', configKey: 'productivityinsights_api_v1_filter_loadedby' },
    { urlSubString: '/api/v1/filter/loadzone', configKey: 'productivityinsights_api_v1_filter_loadzone' },
    { urlSubString: '/api/v1/filter/dumpzone', configKey: 'productivityinsights_api_v1_filter_dumpzone' },
    { urlSubString: '/api/v1/filter/zonename', configKey: 'productivityinsights_api_v1_filter_zonename' },
    { urlSubString: '/api/v1/filter/loadsitedescription', configKey: 'productivityinsights_api_v1_filter_loadsitedescription' },
    { urlSubString: '/api/v1/filter/materialid', configKey: 'productivityinsights_api_v1_filter_materialid' },
    { urlSubString: '/api/v1/filter/truckid', configKey:
    'productivityinsights_api_v1_filter_truckid' }
  ],
  postUtilizationHours: [{ urlSubString: '/api/v1/utilisation', configKey: 'postUtilizationHours_api_v1_utilisation' }],
  getSites: [
    { urlSubString: '/api/v1/assets/recommendations/config', configKey: 'getSites_api_v1_assets_recommendations_config' },
    { urlSubString: '/api/v1/sites/name', configKey: 'getSites_api_v1_sites_name' }
  ],
  getSiteCoreData_pitms: [{ urlSubString: '/api/v1/organization/jobsite', configKey: 'getSiteCoreData_pitms_api_v1_organization_jobsite' }],
  assetNotification: [{ urlSubString: '/api/v1/notifications/count/site', configKey: 'assetNotification_api_v1_notifications_count_site' }],
  postCycles_pitms: [{ urlSubString: '/api/v2/cycles', configKey: 'postCycles_pitms_api_v2_cycles' }],
  getCustSummary_helios: [
    { urlSubString: '/userManagement/v1/parties/CUST/summary', configKey: 'getCustSummary_helios_userManagement_v1_parties_CUST_summary' }
  ],
  customerParties: [{ urlSubString: '/userManagement/v1/dealers', configKey: 'customerParties_userManagement_v1_dealers' }],
  //summary dashboard
  postDesignFiles: [{ urlSubString: '/api/v1/organization/jobsite', configKey: 'postDesignFiles_api_v1_organization_jobsite' }],
  postKPI: [
    { urlSubString: '/api/v1/productivity/site', configKey: 'postKPI_api_v1_productivity_site' },
    { urlSubString: '/api/v1/utilisation/site', configKey: 'postKPI_api_v1_utilisation_site' },
    { urlSubString: '/api/v1/cost/site', configKey: 'postKPI_api_v1_cost_site' }
  ],
  postFleetLoadCount: [{ urlSubString: '/api/v1/cycles', configKey: 'postFleetLoadCount_api_v1_cycles' }],
  postSiteImageFiles_pitms: [
    { urlSubString: '/api/v1/organization/jobsite', configKey: 'postSiteImageFiles_pitms_api_v1_organization_jobsite' }
  ],
  postUtilisationMapData: [{ urlSubString: '/api/v1/utilisation', configKey: 'postUtilisationMapData_api_v1_utilisation' }],
  cost: [{ urlSubString: '/api/v1', configKey: 'cost_api_v1' }],
  postCostMapData: [{ urlSubString: '/api/v1/cost', configKey: 'postCostMapData_api_v1_cost' }],
  getLoadCountZoneInfoDetails: [{ urlSubString: '/api/v1/site', configKey: 'getLoadCountZoneInfoDetails_api_v1_site' }],
  utilizationZoneInfoDetails: [{ urlSubString: '/api/v1/site', configKey: 'utilizationZoneInfoDetails_api_v1_site' }],
  getCCDSLocation: [{ urlSubString: '/telematics/locations', configKey: 'getCCDSLocation_telematics_locations' }],
  getSiteImageMetaData_pitms_wrapper_sm: [
    { urlSubString: '/api/v1/wrapper', configKey: 'getSiteImageMetaData_pitms_wrapper_sm_api_v1_wrapper' }
  ],
  getSiteImageTileURL_pitms_wrapper_sm: [
    { urlSubString: '/api/v1/wrapper', configKey: 'getSiteImageTileURL_pitms_wrapper_sm_api_v1_wrapper' }
  ],
  sitesmaster: [
    {
      urlSubString: '/api/v1/sites',
      configKey: 'sitesmaster_api_v1_sites'
    }
  ],
  postCycleSegments: [{ urlSubString: '/api/v1/sites', configKey: 'postCycleSegments_api_v1_sites' }],
  search: [
    {
      urlSubString: '',
      configKey: 'search'
    }
  ],
  getCostZoneInfoDetails: [{ urlSubString: '/api/v1/cost', configKey: 'getCostZoneInfoDetails_api_v1_cost' }],
  postAssetListData_sm: [{ urlSubString: '/api/v1/wrapper', configKey: 'postAssetListData_sm_api_v1_wrapper' }],
  postMeasuredVolume: [{ urlSubString: '/api/v1/wrapper', configKey: 'postMeasuredVolume_api_v1_wrapper' }],
  dailyCcaSummary: [{ urlSubString: '/api/v1/wrapper', configKey: 'dailyCcaSummary_api_v1_wrapper' }]
};

export const EXCLUDED_ENCRYPTION_VALUES = ['Unknown'];
