import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent implements OnInit {
  showDialog = false;
  @Output() footerAction: EventEmitter<boolean> = new EventEmitter();
  @Output() clickLegal: EventEmitter<boolean> = new EventEmitter();
  @Output() clickCookies: EventEmitter<boolean> = new EventEmitter();

  @Input() isSideMenuFooter = false;
  @Input() isOneTrust = false;
  @Input() footerContents;
  @Input() showCookies = true;
  @Input() showLegal = true;
  @Input() showContact = true;
  @Input() contactSupportUrl = '';
  copyrightYear: number;
  panelOpenState = false;
  step = 0;
  allowEvent: any;
  constructor(@Inject(DOCUMENT) private readonly document: any, public modal: CcModal) {
    this.allowEvent = this.gotoCookie.bind(this);
  }

  ngOnInit() {
    this.copyrightYear = new Date().getFullYear();
  }

  openedState(count) {
    this.step = count;
    if (this.step === 1) {
      document.getElementById('gotoCookies').removeEventListener('click', this.allowEvent);
      document.getElementById('gotoCookies').addEventListener('click', this.allowEvent);
    }
    return this.step;
  }

  gotoCookie() {
    this.openedState(2);
  }

  closeDialog() {
    this.showDialog = false;
    this.document.body.style.overflow = 'auto';
  }

  openDialog() {
    if (this.clickLegal.observed) {
      this.clickLegal.emit(true);
      return;
    }
    this.document.body.style.overflow = 'hidden';
    this.showDialog = true;
  }

  contactClicked() {
    window.open(this.contactSupportUrl, '_blank');
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.showDialog) {
      this.closeDialog();
    }
  }
}
