import { Injectable } from '@angular/core';
import {
  EntityAction,
  EntityCollection,
  EntityCollectionReducerMethodMap,
  EntityCollectionReducerMethods,
  EntityDefinition,
  EntityDefinitionService
} from '@ngrx/data';

/* we have added the additional properties to the action.payload in CustomPersistenceResultHandler. 
Up next we need to set it to the instance of EntityCollection in the reducer. 
In order to accomplish that, we need to create an CustomEntityCollectionReducerMethods that extends 
EntityCollectionReducerMethods. In addition, we will need to overwrite the method to match your action. 
For example, if the additional property `nextURL` is only available in 
queryMany action(triggered by EntityCollectionService.getWithQuery) */

/* istanbul ignore next */
export class CustomEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(public entityName: string, public definition: EntityDefinition<T>) {
    super(entityName, definition);
  }

  protected queryManySuccess(collection: EntityCollection<T>, action: EntityAction<T[]>): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);
    const attrKeys = Object.keys((action.payload as any).attr);
    if (attrKeys.length) {
      // save the additional properties(totalCount, nextURL, etc) received from action.payload to entityCollection instance
      attrKeys.forEach(attrKey => {
        const attr = (action.payload as any).attr[attrKey];
        (ec as any)[attrKey] = attr;
      });
    }
    return ec;
  }
}

/* Register CustomEntityCollectionReducerMethods, to do that, 
we need to create a CustomEntityCollectionReducerMethodsFactory */

/* istanbul ignore next */
@Injectable()
export class CustomEntityCollectionReducerMethodsFactory {
  constructor(private readonly entityDefinitionService: EntityDefinitionService) {}
  /** Create the  {EntityCollectionReducerMethods} for the named entity type */
  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new CustomEntityCollectionReducerMethods(entityName, definition);
    return methodsClass.methods;
  }
}
