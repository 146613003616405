import { Routes } from '@angular/router';
import { EditGuard } from '../guards/edit.guard';

const PRELOAD_CONFIG = { preload: true, loadAfterSeconds: 10 };

export const appRoutes: Routes = [
  { path: '', loadChildren: () => import('@Terra/pitmanagement/marketing').then(m => m.MarketingModule), pathMatch: 'full' },

  { path: 'access_token', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'productivity', redirectTo: '/dashboard', pathMatch: 'full' },

  {
    path: 'dashboard',
    data: { preload: true },
    loadChildren: () => import('@Terra/pitmanagement/sitemanagement-v2').then(m => m.siteManagementRoutes)
  },
  {
    path: 'dashboard/add-site',
    data: { ...PRELOAD_CONFIG, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement-upsertsite').then(m => m.UpsertsiteModule)
  },
  {
    path: 'dashboard/edit-site/:status/:id',
    data: { ...PRELOAD_CONFIG, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement-upsertsite').then(m => m.UpsertsiteModule),
    canActivate: [EditGuard]
  },
  {
    path: 'dashboard/copy-site/:status/:id',
    data: { ...PRELOAD_CONFIG, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement-upsertsite').then(m => m.UpsertsiteModule)
  },
  {
    path: 'dashboard/summary/:siteGuid/:tab/:kpi/:assetType/:dateRange/:startDate/:endDate/:siteStatus/:assetKey/:shift/:viewBy',
    data: { ...PRELOAD_CONFIG, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement/summary-dashboard').then(m => m.SummaryDashboardModule)
  },
  {
    path: 'dashboard/safety/:siteGuid/:kpi/:dateRange/:startDate/:endDate/:siteStatus/:assetKey/:detectionType',
    data: { ...PRELOAD_CONFIG, preload: false, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement/summary-dashboard').then(m => m.dashboardRoutes)
  },
  {
    path: 'dashboard/insights/:tab/:siteId/:assetType/:kpi/:viewBy/:dateRange/:startDate/:endDate/:pageSize',
    data: { hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement/insights').then(m => m.InsightsModule)
  },
  {
    path: 'reportManagement',
    data: PRELOAD_CONFIG,
    loadChildren: () => import('@Terra/pitmanagement/reportmanagement').then(m => m.ReportManagementModule)
  },
  {
    path: 'dashboard/insights/:tab/:siteId/:assetType/:kpi/:viewBy/:dateRange/:startDate/:endDate/:pageSize/:serialNumber/:seekerStartTime/:siteCreationDate/:siteImageId',
    data: { ...PRELOAD_CONFIG, hideMenu: true },
    loadChildren: () => import('@Terra/pitmanagement/playback').then(m => m.PitmanagementPlaybackModule)
  },
  {
    path: 'missionManagement',
    loadChildren: () => import('@Terra/scs/mission-management').then(m => m.SupervisoryControlModule)
  },
  {
    path: 'remoteListManagement',
    data: PRELOAD_CONFIG,
    loadChildren: () => import('@Terra/pitmanagement/asset-services').then(m => m.AssetServicesRoutingModule)
  },
  {
    path: 'auditLogs',
    data: PRELOAD_CONFIG,
    loadChildren: () => import('@Terra/pitmanagement/audit-logs').then(m => m.AuditLogsModule)
  },
  {
    path: 'fileManagement',
    data: PRELOAD_CONFIG,
    loadChildren: () => import('@Terra/pitmanagement/fileManagement').then(m => m.FileManagementModule)
  },
  {
    path: 'safety',
    loadChildren: () => import('@Terra/pitmanagement/sitemanagement-v2').then(m => m.siteManagementRoutes)
  }
];
