import { HighlightSearchText } from '@Terra/shared/widgets/pipes';
import { SearchModule } from '@Terra/shared/widgets/search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CcPopoverModule } from '@cat-digital-workspace/shared-ui-core/cc-popover';
import { CcInputFieldModule } from '@cat-digital-workspace/shared-ui-core/input-field';

import { HideDisableDirective } from '@Terra/pitmanagement/shared/directive';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { CcListModule } from '@cat-digital-workspace/shared-ui-core/cc-list';
import { CcMenuModule } from '@cat-digital-workspace/shared-ui-core/contextual-menu';
import { CcDropdownModule } from '@cat-digital-workspace/shared-ui-core/dropdown';
import { CcSlideToggleModule } from '@cat-digital-workspace/shared-ui-core/slide-toggle';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { UserNavComponent } from './user-nav/user-nav.component';

@NgModule({
  imports: [
    CommonModule,
    CcListModule,
    CcMenuModule,
    CcPopoverModule,
    CcDropdownModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    SearchModule,
    ScrollingModule,
    CatButtonModule,
    CcTooltipModule,
    CcInputFieldModule,
    CcSlideToggleModule,
    HideDisableDirective
  ],
  declarations: [UserNavComponent],
  exports: [UserNavComponent],
  providers: [HighlightSearchText]
})
export class AppUserNavModule {}
