import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { isDemoModeONForInterceptor } from '@Terra/pitmanagement/shared/utils';
import { DemoInterceptor } from './demo-mode-interceptor/demo-mode.interceptor';
import { NoopInterceptor } from './noop.interceptor';

export function myDemoInterceptorFactory(): DemoInterceptor | NoopInterceptor {
  if (isDemoModeONForInterceptor()) {
    return new DemoInterceptor();
  }
  return new NoopInterceptor();
}
@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [{ provide: HTTP_INTERCEPTORS, useFactory: myDemoInterceptorFactory, multi: true }]
})
export class PitmanagementHttpInterceptorModule {}
