import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { CcPopover } from '@cat-digital-workspace/shared-ui-core/cc-popover';
import {
  CookiesString,
  DEMO_MODE_STRINGS,
  HeaderBackgroundColor,
  SHOW_CHANGE_ACCOUNT_POPOVER,
  USER_ROLE_READ_ONLY
} from './user-nav.constants';

@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNavComponent {
  @Input() showDemoMode = false;
  @Input() isMobileView = false;
  @Input() showUpgrade = false;
  @Input() enableUpgrade = false;
  @Input() userInitials = '';
  @Input() userRole = '';
  @Input() ucidName = '';
  @Input() userName = '';
  @Input() isMobileViewUserInfo = false;
  @Input() changeAccountTemplateRef!: TemplateRef<any>;
  @Output() userNavEvents: EventEmitter<string> = new EventEmitter();
  @Output() accountEvents = new EventEmitter();
  @Output() demoModeEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() set isFunctionalCookieDisabled(value: boolean) {
    if (!value) {
      this.ccpopover?.hide();
    }
    this.isHelpIconEnabled = value;
  }

  tooltipWidth = {
    DESKTOP: '237px',
    MOBILE: '237px'
  };

  isHelpIconEnabled = false;
  demoModeStatus = window.localStorage.getItem('isDemoMode') === 'true';
  demoModeStrings = DEMO_MODE_STRINGS;

  /**
   * cookiesString  creat for help popup text translate.
   */
  cookiesString = CookiesString;

  headerbackgroundColor = HeaderBackgroundColor;
  eCustomerUserRole = USER_ROLE_READ_ONLY;
  @ViewChild(CcPopover) ccpopover!: CcPopover;

  usermenuActions(action: string): void {
    this.userNavEvents.emit(action);
  }

  /**
   * Method for stopping close the pop up
   */
  openChangeAccount(event: Event): void {
    event.stopPropagation();
    this.accountEvents.emit({ event: SHOW_CHANGE_ACCOUNT_POPOVER, showPopover: true });
  }

  initOuterAction(action: string): void {
    this.ccpopover?.hide();
    this.userNavEvents.emit(action);
  }

  onStopPropogation(event: Event): void {
    event.stopPropagation();
  }

  toggleDemoMode(isChecked: boolean) {
    this.demoModeEvent.emit(isChecked);
  }
}
