import { MessageBarHorizontalPosition, MessageBarVerticalPosition } from '@cat-digital-workspace/shared-ui-core/message';
import { UsermanagementConfig } from '@Terra/shared/usermanagement-v2';

export const catCrmiSecureForce = 'https://cat-crmi.secure.force.com/CatProductivity';

export enum BC_CHANNELS {
  app = 'cp_app_root_channel'
}

export const SEMI_MODAL: any = {
  type: 'semi-modal',
  hasBackdrop: true,
  isAutoHeightModalContent: true,
  disableBackdropClick: true,
  disableMargin: true,
  width: 366
};

export enum BC_MSG_IDs {
  synch_store_req = 'synch store request',
  synch_store_res = 'synch store response'
}

export enum BC_ERR_CODES {
  synch_store_fail = 'synch store failed'
}

export const configStrings = {
  sites_v3: '/api/v3/sites',
  users_v3: '/api/v3/users',
  applications: '/api/applications/',
  applications_v2: '/api/v2/applications/',
  qaVocConsent: 'https://qa-voconsent.cat.com',
  navigatePath: '/dashboard',
  cookieString: 'cookie-consent-status',
  queryString: '=; path=/; domain=.cat.com; expires='
};

export const MENU_CONFIGURATION_BODY = {
  togglerIcon: '../assets/hamburgerIcon.svg',
  brand: {
    src: '../assets/CAT-Logo.svg',
    link: '',
    isTitle: true,
    title: 'app.header.sitemanagement'
  },
  leftMenu: {
    navs: []
  },
  rightMenu: {
    navs: []
  }
};

export const MENU_ACTIONS = {
  PREFERENCES: 'userPreference',
  HELP: 'help',
  LOGOUT: 'logOut'
};

export const UCID_STORAGE_KEY = 'ucidData';

export const USER_MANAGEMENT_CONFIG: UsermanagementConfig = {
  ui: {
    elements: {
      title: {
        styles: {
          color: '#26292e'
        },
        label: 'usermanagement.users.admintitle'
      },
      search: {},
      add: {
        label: 'usermanagement.users.addbutton'
      },
      edit: {},
      delete: {},
      form: {},
      cwsID: {
        showInGrid: true
      },
      name: {
        label: 'usermanagement.users.table.username',
        showInGrid: true
      },
      email: {
        label: 'usermanagement.users.addedit.email',
        showInGrid: true
      },
      phoneNo: {
        label: 'usermanagement.users.addedit.phone',
        showInGrid: true
      },
      group: {
        showInGrid: true,
        label: 'usermanagement.users.table.account'
      },
      role: {
        showInGrid: true,
        label: 'usermanagement.users.table.userrole'
      },
      action: {
        showInGrid: true,
        label: 'usermanagement.users.table.action'
      }
    },
    validations: {
      higherRoleEdit: false
    }
  },
  api: {
    middleware: true
  },
  isAppLevelConfig: false,
  rootGroupInfo: {
    description: 'Root',
    groups: [],
    id: 3,
    name: 'Root',
    parentID: 0,
    ucid: null
  },
  PAGE_DETAILS: {
    PageSize: 20,
    PageNumber: 1
  }
};

export const VOC_HELIOS_LANGUAGE_CODE = {
  'jp-jn': 'ja-jp',
  zh_CN: 'zh-ch',
  'zh-Hans': 'zh-ch'
};

export const GTM_DATALAYER_ID = 'GTM-NL835FR';

export const LOCAL_STORAGE_KEYS = {
  LOGOUT: 'logOut',
  DEVICEIDS: 'deviceIDs'
};

export const DEFAULT_LANGUAGE = 'en-US';
export const GROUP_ROOT = 3;
export const ROOT_LABEL = 'Root';

export const PAGE_PATH = {
  MARKETING: '/marketing',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/userManagement'
};

export const ROOT_RESTRICTED_PAGES = [
  {
    path: 'dashboard',
    title: 'productivity.title'
  },
  {
    path: 'allAssets',
    title: 'Assets'
  },
  {
    path: 'targets',
    title: 'setTargetTITLE'
  },
  {
    path: 'reportManagement',
    title: 'Report Management'
  },
  {
    path: 'remoteListManagement',
    title: 'REMOTE LIST MANAGEMENT'
  },
  {
    path: 'auditLogs',
    title: 'Audit Logs'
  }
];

export const APP_ID = 'pitManagement';
export const APPLICATION_ID = 3;
export const horizontalPosition: MessageBarHorizontalPosition = 'center';
export const verticalPosition: MessageBarVerticalPosition = 'top';

export const TOASTER_CONFIG = {
  TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  TOASTER_LIFE_TIME: 5000,
  HOST_TYPE: {
    OVERLAY: 'overlay',
    INLINE: 'inline'
  },
  H_POSITION: horizontalPosition,
  V_POSITION: verticalPosition,
  PREFERENCE_TEXT: {
    SAVED: 'PREFERENCE_SUCCESS_MSG',
    FAILED: 'PREFERENCE_SAVE_FAIL_MSG'
  },
  ERROR_KEY: 'app.errorMessage.process_ERR',
  FALLBACK: 'There is an error processing your request. Please contact customer support'
};

export const BROWSER_COMPATIBILITY_CONFIG = [
  {
    name: 'MS-Edge-Chromium',
    device: 'desktop',
    version: '95',
    range: 2
  },
  {
    name: 'Chrome',
    device: 'desktop',
    version: '95',
    range: 2
  },
  {
    name: 'Safari',
    device: 'desktop',
    version: '15',
    range: 1
  },
  {
    name: 'Firefox',
    device: 'desktop',
    version: '93',
    range: 3
  },
  {
    name: 'Safari',
    device: 'tablet',
    version: '15',
    range: 1
  },
  {
    name: 'Safari',
    device: 'mobile',
    version: '15',
    range: 1
  },
  {
    name: 'Chrome',
    device: 'mobile',
    version: '94',
    range: 2
  },
  {
    name: 'Chrome',
    device: 'tablet',
    version: '94',
    range: 2
  }
];

export const CONTACT_SUPPORT_URL = {
  local: catCrmiSecureForce,
  dev: catCrmiSecureForce,
  int: catCrmiSecureForce,
  qa: catCrmiSecureForce,
  stg: catCrmiSecureForce,
  perf: catCrmiSecureForce,
  prodBeta: catCrmiSecureForce,
  prod: catCrmiSecureForce
};

export const CP_LOGGED_IN_COOKIE = 'loggedIn';

export const ONETRUST_DATA_DOMAIN = '6b32398a-3e80-4329-b54b-c689861a9fe9';
