import { Injectable } from '@angular/core';
import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

/* Creates a new class PersistenceResultHandler that extends DefaultPersistenceResultHandler and 
overwrite the handleSuccess method. The purpose is to parse the data received from DataService, 
retrieve the additional property, and then save this to the action.payload. */

/* istanbul ignore next */
@Injectable()
export class CustomPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    // return a factory to get a data handler to
    // parse data from DataService and save to action.payload
    return function (data: any) {
      const action = actionHandler.call(this, data);

      if (action && data && !(data instanceof Array)) {
        // if the API response is not an Array, it wont be stored as entities
        // so we are getting the data array from respective attribute in API response and setting it to payload.data
        const keys = Object.keys(data);
        const attrs = {};
        const _data = [];
        keys.forEach(key => {
          if (data[key] instanceof Array && data[key].length && typeof data[key][0] !== 'string') {
            (action).payload.data = data[key];
          } else if (data[key] instanceof Array) {
            //TODO: need to do refactor code
            _data.push(data);
            (action).payload.data = _data;
          } else {
            attrs[key] = data[key];
          }
        });
        (action).payload.attr = attrs;
      } else {
        (action).payload.attr = { isArrayResponse: true };
      }
      return action;
    };
  }
}
