export const MARKETING_CONSTANTS = {
  COOKIE_SETTINGS: 'CookieSettings',
  ACCEPT: 'accept',
  DISMISS: 'dismiss',
  FORBIDDEN: 403
};
export const remoteListDetailsUrl = 'remoteListManagement/details';
export const remoteListUrl = 'remoteListManagement';

export const allAssetBaseUrl = 'allAssets/1/20/1/-/-/-/-';
export const allAssetEntryRoute = 'allAssets';
export const BotStyleConstants = {
  COLOR_WHITE: '#FFFFFF',
  COLOR_BLACK: '#000000',
  COLOR_GREY: '#777777',
  COLOR_GREY_LIGHT: '#F2F2F2',
  COLOR_GREY_DARK: '#565656',
  COLOR_NAVY_BLUE: '#C2DDF1',
  COLOR_OFF_WHITE: '#CCCCCC',
  COLOR_ALERT_BLUE: '#0078D6'
};
export const CHAT_BOT = 'Chatbot';
export const DEMO_MODE_FEATURE_FLAG = 'Demo_Site';
export const DEMO_MODE_INSIGHTS = 'insights';
